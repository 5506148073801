import React from "react";
import "../../index.css";
import "react-tippy/dist/tippy.css";
import { getBasePath } from "../../api";
import Joyride from "react-joyride";
import { ROUTES } from "./routes";
import DataTransformer from "./data_transformer";

// components
import Headbar from "../headbar/headbar";
import Sidebar from "../sidebar/sidebar";
import Botbar from "../botbar/botbar";
import Notifications from "../login_page/notifications";
import ParamStringOptions from "./param_string_options";
import ParamString from "./param_string";
import ParamObject from "./param_object";
import ParamFile from "./param_file";

// icons
import { ChevronDown, Clipboard } from "react-feather";
import BigDrop from "../sidebar/big_drop";
import { isJsonString } from "../../helpers";
import ParamInt from "./param_int";
import RightHandSidebar from "../sidebar/right_hand_sidebar";

// react-joyride user-onboarding steps
const steps = [
  {
    target: "#joy-1",
    content: "We are now on the OpenAPI Page.",
    disableBeacon: true,
    placement: "center",
  },
  {
    target: "#joy-1",
    content: "Select the API route you wish to see by clicking here.",
    disableBeacon: true,
    placement: "right",
  },
  {
    target: "#joy-2",
    content:
      "The route being queried can be seen here. You can click 'Send' to send an API request to the route.",
    disableBeacon: true,
    placement: "right",
  },
  {
    target: "#joy-3",
    content:
      "If the route requires arguments, or a body, that information will be displayed here.",
    disableBeacon: true,
    placement: "right",
  },
  {
    target: "#joy-4",
    content: "The results of any API route queries will be displayed here.",
    disableBeacon: true,
    placement: "left",
  },
];

// transform the swaggerfile data into a format usable by the OpenAPI page
let data_transformer = new DataTransformer(ROUTES);
var DATA = data_transformer.transform_data();

/**
 * Page that describes RBUI API routes and lets the user query them
 * directly.
 * Takes a JSON DATA constant and renders a set of tabs based on it:
 * one tab for each route.
 * The left side of the page consists of Form Body, JSON Body, GET
 * parameters, Bearer Tokens, and whatever else is required to query
 * the selected route. These parameter inputs are built from the
 * <Param<type> /> components.
 * The right side of the page consists of both example output, aswell
 * as actual output, should the user query the route.
 */
export default class OpenAPIPage extends React.Component {
  constructor(props) {
    super(props);

    document.title = "OT.AI Platform - OpenAPI";

    let params = window.location.href.split("?")[1];

    this.state = {
      onboard: params !== undefined && params === "onboard",
      page: DATA[0],
      popup: false,

      // fetch response data
      responseBody: null,
      responseCode: null,
      loading: false,

      bearer: "Bearer " + window.localStorage.getItem("auth"),
    };

    this.sendRequest = this.sendRequest.bind(this);
    this.renderRequestOptions = this.renderRequestOptions.bind(this);
    this.renderRequestOption = this.renderRequestOption.bind(this);
    this.popupOff = this.popupOff.bind(this);
  }

  renderRequestOptions() {
    let ret = [];

    ret.push(
      <h3
        style={{
          background: "var(--bg-color)",
          color: "var(--pri-color)",
          cursor: "unset",
        }}
      >
        Result
      </h3>
    );
    ret.concat(
      DATA.map((d) => {
        if (["/result/get", "/result/cwe_info"].includes(d.route))
          ret.push(this.renderRequestOption(d));
      })
    );

    ret.push(
      <h3
        style={{
          background: "var(--bg-color)",
          color: "var(--pri-color)",
          cursor: "unset",
        }}
      >
        Asset
      </h3>
    );
    ret.concat(
      DATA.map((d) => {
        if (
          [
            "/asset/get_all",
            "/asset/get",
            "/asset/add_tag",
            "/asset/remove_tag",
            "/asset/num_new",
            "/asset/rename",
            "/asset/description",
            "/asset/type",
            "/asset/facility/{facility_id}/get_all",
            "/asset/facility/{facility_id}/by_tag",
            "/asset/viewed",
            "/asset/audit/{assetid}",
            "/asset/delete",
          ].includes(d.route)
        )
          ret.push(this.renderRequestOption(d));
      })
    );

    ret.push(
      <h3
        style={{
          background: "var(--bg-color)",
          color: "var(--pri-color)",
          cursor: "unset",
          marginTop: 10,
        }}
      >
        Binary
      </h3>
    );
    ret.concat(
      DATA.map((d) => {
        if (["/binary/get"].includes(d.route))
          ret.push(this.renderRequestOption(d));
      })
    );

    ret.push(
      <h3
        style={{
          background: "var(--bg-color)",
          color: "var(--pri-color)",
          cursor: "unset",
          marginTop: 10,
        }}
      >
        Dropzone
      </h3>
    );
    ret.concat(
      DATA.map((d) => {
        if (
          [
            "/dropzone/list",
            "/dropzone/add",
            "/dropzone/remove",
            "/dropzone/remove_all",
            "/dropzone/start",
          ].includes(d.route)
        )
          ret.push(this.renderRequestOption(d));
      })
    );

    ret.push(
      <h3
        style={{
          background: "var(--bg-color)",
          color: "var(--pri-color)",
          cursor: "unset",
          marginTop: 10,
        }}
      >
        Facility
      </h3>
    );
    ret.concat(
      DATA.map((d) => {
        if (
          [
            "/facility/get",
            "/facility/create",
            "/facility/update",
            "/facility/delete/{facility_id}",
            "/facility/default",
          ].includes(d.route)
        )
          ret.push(this.renderRequestOption(d));
      })
    );

    ret.push(
      <h3
        style={{
          background: "var(--bg-color)",
          color: "var(--pri-color)",
          cursor: "unset",
          marginTop: 10,
        }}
      >
        Analysis Queue
      </h3>
    );
    ret.concat(
      DATA.map((d) => {
        if (["/queue/progress"].includes(d.route))
          ret.push(this.renderRequestOption(d));
      })
    );

    ret.push(
      <h3
        style={{
          background: "var(--bg-color)",
          color: "var(--pri-color)",
          cursor: "unset",
          marginTop: 10,
        }}
      >
        User
      </h3>
    );
    ret.concat(
      DATA.map((d) => {
        if (
          [
            "/user/login",
            "/user/generate_totp",
            "/user/validate_totp",
            "/user/all",
            "/user/add",
            "/user/addroles",
            "/user/removeroles",
            "/user/password",
            "/user/invite/{user_id}",
            "/user/redeem/{user_id}",
            "/user/setorg",
            "/user/profile/{user_id}",
          ].includes(d.route)
        )
          ret.push(this.renderRequestOption(d));
      })
    );

    ret.push(
      <h3
        style={{
          background: "var(--bg-color)",
          color: "var(--pri-color)",
          cursor: "unset",
          marginTop: 10,
        }}
      >
        Organization
      </h3>
    );
    ret.concat(
      DATA.map((d) => {
        if (
          [
            "/org/add",
            "/org/update",
            "/org/audit",
            "/org/alerts",
            "/org/createalert",
            "/org/readalert",
          ].includes(d.route)
        )
          ret.push(this.renderRequestOption(d));
      })
    );

    ret.push(
      <h3
        style={{
          background: "var(--bg-color)",
          color: "var(--pri-color)",
          cursor: "unset",
          marginTop: 10,
        }}
      >
        Delta
      </h3>
    );
    ret.concat(
      DATA.map((d) => {
        if (
          [
            "/delta/report/{asset_id_a}/{asset_id_b}",
            "/delta/report/{delta_report_id}/status",
            "/delta/report",
            "/delta/report/{delta_report_id}/delete",
            "/delta/report/{delta_report_id}",
          ].includes(d.route)
        )
          ret.push(this.renderRequestOption(d));
      })
    );

    return ret;
  }

  renderRequestOption(d) {
    return (
      <div
        key={d.route}
        className={
          this.state.page.route === d.route
            ? "sel-col-opt route-bg"
            : "route-bg"
        }
        style={{
          marginRight: 0,
          display: "flex",
        }}
        onClick={() =>
          this.setState({
            page: d,
            responseBody: null,
            responseCode: null,
          })
        }
      >
        <h3
          style={{
            color: "var(--pri-color-light)",
            width: 32,
          }}
        >
          {d.method}
        </h3>
        <h3>{d.route}</h3>
      </div>
    );
  }

  popupOff() {
    this.setState({ popup: false, togglingInitiate: false });
  }

  /**
   * Mount the panel resize handle listener.
   */
  componentDidMount() {
    window.addEventListener("click", this.popupOff, false);

    let handle = document.getElementById("drag-w");

    var isResizing = false;

    handle.addEventListener("mousedown", (e) => {
      isResizing = true;

      document.body.style.userSelect = "none";
    });

    document.addEventListener("mousemove", (e) => {
      if (!isResizing) return;

      document.getElementsByClassName("filter")[0].style.width = `${
        window.innerWidth - e.clientX - 41
      }px`;
    });

    document.addEventListener("mouseup", (e) => {
      isResizing = false;
      document.body.style.userSelect = "auto";
    });
  }

  /**
   * Unmount click listener.
   */
  componentWillUnmount() {
    window.removeEventListener("click", this.popupOff, false);
  }

  /**
   * Send the API request currently specified by the page state.
   * Called when a user clicks the "Send" button.
   */
  async sendRequest() {
    this.setState({ loading: true });
    let request_data = this.state.page;
    let responseBody = "";
    let res = null;

    // form data POST request
    if (request_data.parameterType === "Form Data") {
      let body = new FormData();
      for (let p of this.state.page.parameters) {
        body.append(p.name, p.value);
      }
      res = await fetch(getBasePath() + this.state.page.route, {
        method: this.state.page.method,
        headers: new Headers({
          Authorization: this.state.bearer,
        }),
        body,
      });
      responseBody = await res.text();
    }

    // JSON Body POST request
    else if (request_data.parameterType === "JSON Body") {
      let body = request_data.parameters[0].value;
      res = await fetch(getBasePath() + this.state.page.route, {
        method: this.state.page.method,
        headers: new Headers({
          Authorization: this.state.bearer,
          "Content-Type": "application/json",
        }),
        body,
      });
      responseBody = await res.text();
    }

    // GET request with get parameters
    else if (request_data.parameterType === "GET Parameters") {
      let route = this.state.page.route;
      let params = new URLSearchParams();
      for (let p of this.state.page.parameters) {
        if (p.inPath) {
          route = route.replace(`{${p.name}}`, p.value);
        } else if (p.value && p.value !== "") params.append(p.name, p.value);
      }
      res = await fetch(getBasePath() + route + "?" + params, {
        method: this.state.page.method,
        headers: new Headers({
          Authorization: this.state.bearer,
        }),
      });
      responseBody = await res.text();
    }

    // Plain POST or GET request
    else {
      res = await fetch(getBasePath() + this.state.page.route, {
        method: this.state.page.method,
        headers: new Headers({
          Authorization: this.state.bearer,
        }),
      });
      responseBody = await res.text();
    }
    this.setState({ loading: false, responseCode: res.status, responseBody });
  }

  /**
   * Render the page.
   */
  render() {
    return (
      <BigDrop>
        {this.state.onboard && (
          <button
            style={{
              position: "fixed",
              top: 40,
              right: 30,
              zIndex: 2001,
              background: "var(--contrast-color)",
              color: "white",
              borderRadius: 40,
            }}
            onClick={() => this.setState({ onboard: false })}
          >
            Cancel Tutorial
          </button>
        )}
        <Joyride
          locale={{
            back: "Back",
            close: "Close",
            last: "Next",
            next: "Next",
            open: "Open the dialog",
            skip: "Skip",
          }}
          disableScrolling
          continuous
          steps={steps}
          run={this.state.onboard}
          callback={(data) => {
            if (data.action === "reset") {
              this.setState({ onboard: false });
            }
            if (data.action === "close" && data.type === "step:after") {
              // This explicitly stops the tour (otherwise it displays a "beacon" to resume the tour)
              //this.setState({ onboard: false });
            }
          }}
          styles={{
            options: {
              arrowColor: "var(--sec-bg-color)",
              backgroundColor: "var(--sec-bg-color)",
              borderColor: "var(--bor-color)",
              primaryColor: "var(--sec-color)",
              textColor: "var(--pri-color)",
            },
          }}
        />

        <main className="deep-dive">
          <Notifications />

          <Headbar />
          <Botbar />

          <div className="main-row">
            <Sidebar page="openapi" />

            <div className="content">
              <div className="left-row dis-dec-head" style={{ height: 25 }}>
                <h3>Request</h3>
              </div>

              <div
                className="toggle-header request-header"
                style={{ height: 50 }}
                id="joy-2"
              >
                <div
                  id="joy-1"
                  onClick={(e) => {
                    e.stopPropagation();
                    this.setState({ popup: true });
                  }}
                  className="sec-button"
                  style={{ background: "var(--sec-color)" }}
                >
                  <h3 style={{ color: "white" }}>{this.state.page.method}</h3>
                  <ChevronDown style={{ stroke: "white" }} className="user" />
                </div>
                <h3 style={{ fontWeight: 800 }}>{this.state.page.route}</h3>
                <h4
                  style={{
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                >
                  {this.state.page.desc}
                </h4>

                <button
                  style={{
                    marginLeft: "auto",
                    background: "var(--sec-bg-color)",
                    border: "1px solid var(--bor-color)",
                    minWidth: 40,
                    maxWidth: 40,
                  }}
                  className="upload"
                  onClick={() => {
                    if (Notifications._this) Notifications._this.addClip();
                    let copy_link = getBasePath() + this.state.page.route;
                    if (window.location.protocol !== "https:") {
                      alert(copy_link);
                      return;
                    }
                    navigator.clipboard.writeText(copy_link);
                  }}
                >
                  <Clipboard
                    style={{
                      stroke: "var(--pri-color-light)",
                      width: 20,
                      height: 20,
                    }}
                  />
                </button>
                <button
                  onClick={this.sendRequest}
                  className="upload"
                  style={{ marginLeft: 0, minWidth: 60, maxWidth: 60 }}
                >
                  Send
                </button>

                <div
                  className="collection-options"
                  style={{
                    left: 10,
                    top: 43,
                    width: 360,
                    maxHeight: 300,
                    overflowY: "scroll",
                    display: this.state.popup ? "block" : "none",
                  }}
                >
                  {this.renderRequestOptions()}
                </div>
              </div>

              <div className="syntax openapi-syntax" id="joy-3">
                <h3
                  style={{
                    marginLeft: 20,
                    fontWeight: 700,
                    marginTop: 20,
                    marginBottom: 10,
                  }}
                >
                  Description
                </h3>
                <p
                  style={{
                    marginLeft: 20,
                    marginRight: 20,
                    textAlign: "left",
                    lineHeight: 1.4,
                  }}
                >
                  {this.state.page.descExt}
                </p>

                <h3
                  style={{
                    marginLeft: 20,
                    fontWeight: 700,
                    marginTop: 30,
                    marginBottom: 10,
                  }}
                >
                  Auth Bearer Token
                </h3>
                <div className="req-field">
                  <input
                    value={this.state.bearer}
                    placeholder="string..."
                    onInput={(e) => this.setState({ bearer: e.target.value })}
                    className="up-init"
                    style={{
                      cursor: "text",
                      width: "calc(100%)",

                      marginLeft: 20,
                    }}
                  />
                </div>

                {this.state.page.parameters.length <= 0 && (
                  <h3
                    style={{
                      marginLeft: 20,
                      fontWeight: 700,
                      marginTop: 30,
                      marginBottom: 10,
                    }}
                  >
                    This request does not require a body.
                  </h3>
                )}

                {this.state.page.parameters.length > 0 && (
                  <h3
                    style={{
                      marginLeft: 20,
                      fontWeight: 700,
                      marginTop: 30,
                      marginBottom: 10,
                    }}
                  >
                    {this.state.page.parameterType}
                  </h3>
                )}

                {this.state.page.parameters.map((p) => {
                  if (p.type === "string" && p.options === null) {
                    return (
                      <ParamString
                        key={p.name}
                        param={p}
                        func={(value) => {
                          let page = this.state.page;
                          for (let param of page.parameters) {
                            if (p.name === param.name) {
                              param.value = value;
                              break;
                            }
                          }
                          this.setState({ page });
                        }}
                      />
                    );
                  } else if (p.type === "integer") {
                    return (
                      <ParamInt
                        param={p}
                        key={p.name}
                        func={(value) => {
                          let page = this.state.page;
                          for (let param of page.parameters) {
                            if (p.name === param.name) {
                              param.value = value;
                              break;
                            }
                          }
                          this.setState({ page });
                        }}
                      />
                    );
                  } else if (p.type === "string") {
                    return (
                      <ParamStringOptions
                        param={p}
                        key={p.name}
                        func={(value) => {
                          let page = this.state.page;
                          for (let param of page.parameters) {
                            if (p.name === param.name) {
                              param.value = value;
                              break;
                            }
                          }
                          this.setState({ page });
                        }}
                      />
                    );
                  } else if (p.type === "file") {
                    return (
                      <ParamFile
                        param={p}
                        key={p.name}
                        func={(value) => {
                          let page = this.state.page;
                          for (let param of page.parameters) {
                            if (p.name === param.name) {
                              param.value = value;
                              break;
                            }
                          }
                          this.setState({ page });
                        }}
                      />
                    );
                  } else if (p.type === "object") {
                    return (
                      <ParamObject
                        param={p}
                        key={p.name}
                        func={(value) => {
                          let page = this.state.page;
                          for (let param of page.parameters) {
                            if (p.name === param.name) {
                              param.value = value;
                              break;
                            }
                          }
                          this.setState({ page });
                        }}
                      />
                    );
                  }
                  return null;
                })}
              </div>
            </div>

            <div className="report-prev" id="joy-4">
              <div className="res-filter filter">
                <div className="left-row dis-dec-head" style={{ height: 25 }}>
                  <h3>Response</h3>
                </div>

                <div className="syntax" style={{ paddingBottom: 20 }}>
                  {this.state.loading && (
                    <div className="left-row" style={{ marginTop: 20 }}>
                      <div className="lds-dual-ring lds-dual-ring-center"></div>
                    </div>
                  )}
                  {this.state.responseCode !== null &&
                    this.state.responseBody !== null && (
                      <div>
                        <h3
                          style={{
                            margin: 20,
                            fontWeight: 700,
                          }}
                        >
                          Actual Response
                        </h3>
                        <div className="left-row">
                          <h2 style={{ width: "30%", fontSize: 13 }}>Code</h2>
                          <h2 style={{ width: "70%", fontSize: 13 }}>
                            Details
                          </h2>
                        </div>
                        <hr
                          style={{
                            margin: 20,
                            marginTop: 10,
                            width: "calc(100% - 40px)",
                          }}
                        />

                        <div className="left-row res-header">
                          <h2 className="res-key">{this.state.responseCode}</h2>
                          <div className="res-field">
                            <h3>Body</h3>
                            <code
                              className="up-init"
                              style={{
                                width: "calc(100% - 40px)",
                                height: "fit-content",
                                background: "var(--sec-bg-color) !important",
                                whiteSpace: "pre-wrap",
                              }}
                            >
                              {isJsonString(this.state.responseBody)
                                ? JSON.stringify(
                                    JSON.parse(this.state.responseBody),
                                    null,
                                    2
                                  )
                                : this.state.responseBody}
                            </code>
                          </div>
                        </div>
                      </div>
                    )}
                  <h3
                    style={{
                      margin: 20,
                      fontWeight: 700,
                    }}
                  >
                    Example Response
                  </h3>
                  <div className="left-row">
                    <h2 style={{ width: "30%", fontSize: 13 }}>Code</h2>
                    <h2 style={{ width: "70%", fontSize: 13 }}>Details</h2>
                  </div>
                  <hr
                    style={{
                      margin: 20,
                      marginTop: 10,
                      width: "calc(100% - 40px)",
                    }}
                  />

                  {this.state.page.exResponses.map((r) => {
                    return (
                      <div key={r.code} className="left-row res-header">
                        <h2 className="res-key">{r.code}</h2>
                        <div className="res-field">
                          <h3>Body</h3>
                          <code
                            className="up-init"
                            style={{
                              width: "calc(100% - 40px)",
                              height: "fit-content",
                              background: "var(--sec-bg-color) !important",
                              whiteSpace: "pre-wrap",
                            }}
                          >
                            {r.value}
                          </code>
                        </div>
                      </div>
                    );
                  })}
                </div>

                <div id="drag-w"></div>
              </div>
            </div>

            <RightHandSidebar />
          </div>
        </main>
      </BigDrop>
    );
  }
}
