import { useState } from "react";
import PropTypes from "prop-types";
import "./right_hand_sidebar.css";
import { Tooltip } from "react-tippy";
import QRCode from "react-qr-code";

// components
import HelpTooltip from "../binary_analysis_page/help_tooltip";

// icons
import { BookOpen, Camera, FileText, X } from "react-feather";
import { getAllLogs, getMinifiedLogs } from "../../api";

// the maximum number of characters to display in the
// minified error QRcode
const MAX_TEXT = 500;

/**
 * The right-hand-sidebar that appears on all main-application
 * pages. Has buttons for the following:
 * - Running the tutorial.
 * - Navigating to the Knowledgebase.
 * - Downloading logs.
 * - Displaying the minified error QRcode.
 */
export default function RightHandSidebar({ tutorialClick }) {
  let [qrcode, setQrcode] = useState(null);

  return (
    <div className="right-bar" style={{ height: "100%" }}>
      <HelpTooltip clickFunction={tutorialClick} />
      <Tooltip
        // options
        html={<p>Knowledge Base</p>}
        position="left-start"
        trigger="mouseenter"
        arrow
        hideOnClick={false}
        size="small"
        style={{ height: 22, marginTop: 20 }}
      >
        <BookOpen
          onClick={(e) => {
            window.open(
              "https://support.objectsecurity.com/support/home",
              "_blank"
            );
          }}
          className="user"
        />
      </Tooltip>

      <Tooltip
        // options
        html={<p>Download Server Logs</p>}
        position="left-start"
        trigger="mouseenter"
        arrow
        hideOnClick={false}
        size="small"
        style={{ height: 22, marginTop: 20 }}
      >
        <FileText className="user" onClick={getAllLogs} />
      </Tooltip>

      <Tooltip
        // options
        html={<p>View Minified QR-Code Server Logs</p>}
        position="left-start"
        trigger="mouseenter"
        arrow
        hideOnClick={false}
        size="small"
        style={{ height: 22, marginTop: 20 }}
      >
        <Camera
          className="user"
          onClick={() =>
            getMinifiedLogs().then((qr) => {
              setQrcode(qr);
            })
          }
        />
      </Tooltip>

      {qrcode && (
        <div className="side-qr-code">
          <X className="icon" onClick={() => setQrcode(null)} />
          <QRCode value={qrcode.substring(0, MAX_TEXT)} />
        </div>
      )}
    </div>
  );
}

RightHandSidebar.propTypes = {
  /**
   * Callback function to run the tutorial.
   */
  tutorialClick: PropTypes.func.isRequired,
};
