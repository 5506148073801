import { useEffect, useState } from "react";

// components
import RightHandSidebar from "../../../sidebar/right_hand_sidebar";
import Notifications from "../../../login_page/notifications";
import BigDrop from "../../../sidebar/big_drop";
import Headbar from "../../../headbar/headbar";
import Botbar from "../../../botbar/botbar";
import Sidebar from "../../../sidebar/sidebar";
import ReportBuilderSidebar from "./report_builder_sidebar";
import ReportBuilderPreview from "./report_builder_preview";
import { FACILITY_REPORT_RESULT_TYPES, fetchFacilityItems } from "./helpers";

/**
 * Customizable report PDF component that lets users alter what
 * content is display / exported in a PDF report.
 */
export default function ReportBuilder_Facility() {
  document.title = "OT.AI Platform - Reports";

  // parse GET parameters
  const urlParams = new URLSearchParams(window.location.href.split("?")[1]);
  let facility_id = urlParams.get("id");

  let [sidebarLoading, setSidebarLoading] = useState(true);

  let [items, setItems] = useState([]);
  let [facility, setFacility] = useState(null);
  let [assets, setAssets] = useState([]);

  /**
   * Fetch the data necessary to build the report from the API.
   */
  async function init() {
    const fetchDataCallback = (type, data) => {
      switch (type) {
        case FACILITY_REPORT_RESULT_TYPES.FACILITY:
          setFacility(data);
          return;
        case FACILITY_REPORT_RESULT_TYPES.ASSETS:
          setAssets(data);
          return;
        default:
          return;
      }
    };

    await fetchFacilityItems(facility_id, fetchDataCallback);
    setSidebarLoading(false);
  }
  useEffect(init, []);

  return (
    <BigDrop>
      <main>
        {/* Header */}
        <Notifications />
        <Headbar />
        <Botbar />

        {/* Get Started */}
        <div className="main-row2">
          <Sidebar page="reports" />
          <ReportBuilderPreview
            items={items}
            setItems={setItems}
            facility_id={facility_id}
            facility={facility}
            assets={assets}
          />
          <ReportBuilderSidebar
            loading={sidebarLoading}
            items={items}
            setItems={setItems}
            assets={assets}
          />
          <RightHandSidebar />
        </div>
      </main>
    </BigDrop>
  );
}
