import { useEffect, useState } from "react";
import { CheckCircle } from "react-feather";
import UnifiedCWEs from "./unified_cwes";
import BinarySummaryInfoHelper from "./binary_summary_info_helper";
import CWEs from "../CWEs";
import { Search } from "react-feather";

export default function CWEsTab({ binary, data, preSearch, swapTab }) {
  // TODO do something with preSearch
  if (data.unified_cwes) {
    return (
      <>
        <div
          style={{
            transform: "translateX(-10px)",
            width: "calc(100% + 20px)",
            position: "relative",
          }}
        >
          <div>
            <UnifiedCWEs
              cwes={data.unified_cwes.unified_cwes}
              asset_id={binary.id}
              binary={binary.name}
              preSearch={preSearch}
              swapTab={swapTab}
            />
          </div>
        </div>
      </>
    );
  }

  // if there is no UnifiedCWE output, render CWEs the old way
  return <FlatCWEsDisplay binary={binary} data={data} preSearch={preSearch} />;
}

function FlatCWEsDisplay({ binary, data, preSearch }) {
  let [search, setSearch] = useState("");
  let [longCWE, setLongCWE] = useState(true);

  useEffect(() => {
    setSearch(preSearch);
  }, [preSearch]);

  if (!data.cwes || data.cwes.length === 0)
    return (
      <div style={{ marginTop: -10 }}>
        <h4 style={{ fontWeight: 500 }}>
          No CWEs were detected in this binary.
        </h4>
        <CheckCircle className="big-icon" />
      </div>
    );
  return (
    <>
      <h4
        style={{
          position: "absolute",
          width: "100%",
          zIndex: 20,
          padding: 10,
          left: 0,
          top: 27,
          paddingLeft: 20,
          borderTop: "1px solid var(--bor-color)",
          borderBottom: "1px solid var(--bor-color)",
          background: "var(--bg-color)",
        }}
      >
        <div className="search-row">
          <Search className="user-icon" />
          <input
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder={`Search ${data.cwes.length} CWEs...`}
          ></input>
        </div>

        <div className="cwe-len-buts">
          <button
            className={!longCWE ? "cwe-len-but-sel" : ""}
            onClick={() => setLongCWE(false)}
          >
            <hr />
            <hr />
            <hr />
            <hr />
          </button>
          <button
            className={longCWE ? "cwe-len-but-sel" : ""}
            onClick={() => setLongCWE(true)}
          >
            <hr />
            <hr />
            <hr />
            <hr />
          </button>
        </div>
      </h4>
      <div
        style={{
          marginTop: 40,
          transform: "translateX(-10px)",
          width: "calc(100% + 20px)",
          position: "relative",
        }}
      >
        <div>
          <BinarySummaryInfoHelper
            text="The Common Weakness Enumeration (CWE) is a 
              category system for hardware and software weaknesses 
              and vulnerabilities. It is sustained by a community 
              project with the goals of understanding flaws in software 
              and hardware and creating automated tools that can be used 
              to identify, fix, and prevent those flaws.
              The ObjectSecurity OT.AI Platform currently 
              detects approximately 120 unique CWEs across
              x86, ARM, MIPS, PowerPC, SPARC, MIPS, AARCH,
              and RISC-V architectures."
            link="https://cwe.mitre.org/about/index.html"
          />
          <CWEs
            cwes={data.cwes}
            cweSearch={search}
            long={longCWE}
            binary={binary}
          />
        </div>
      </div>
    </>
  );
}
