import { OTAITableColumn, OTAITable } from "../../table";
import { LINEAGE } from "./lineage";

const CWE_CHILD_COLUMNS = [
  new OTAITableColumn(
    "name",
    "Name",
    (a, b) => parseInt(b.id) - parseInt(a.id),
    "22%"
  ),
  new OTAITableColumn("description", "Description", null, "33%"),
  new OTAITableColumn(
    "num_major_findings",
    "Major CWE Findings",
    (a, b) => b.num_major_findings - a.num_major_findings,
    "22%"
  ),
  new OTAITableColumn(
    "num_instances",
    "Total CWE Instances",
    (a, b) => b.num_instances - a.num_instances,
    "22%"
  ),
];

/**
 * Displays a table of all CWE children detected.
 * When a child in this table is clicked, the user is navigated
 * to the corresponding CWE's tab using the `setCWEPath`
 * callback.
 *
 * Children have columns for CWE number, description, and # of
 * instances. The set of children should be ordered by severity
 * (this happens automatically on the backend).
 *
 * TODO Gray out child CWEs that have no instances. These are CWEs
 * that can be found in the CWE taxonomy, but were not detected in
 * the binary file.
 */
export default function CWEChildren({ cwe, setCWEPath }) {
  if (!cwe) return null;
  // append number of detected instances
  let children = [];
  for (let child of cwe.children) {
    child.num_instances = countAllInstances(child);
    child.num_major_findings = countNumMajorFindings(child);
    child.name = "CWE-" + child.id;
    children.push(child);
  }

  // append children without detected instances (greyed out)
  for (let child of LINEAGE[cwe.id].children) {
    if (!children.some((c) => c.id === child)) {
      children.push({
        id: child,
        name: "CWE-" + child,
        description: LINEAGE[child].description,
        num_instances: 0,
        num_major_findings: 0,
      });
    }
  }

  // don't display the table if there are no children
  if (children.length === 0) {
    return null;
  }

  return (
    <div className="cwe-children">
      <h3>Children</h3>
      <h2>{cwe.children.length} Detected</h2>
      <OTAITable
        data={children}
        columns={CWE_CHILD_COLUMNS}
        defaultSortFuncIdx={2}
        onRowClick={(row) => {
          setCWEPath((path) => [...path, row.id]);
        }}
        shouldGreyOut={(child) =>
          child.num_instances === 0 && child.num_major_findings === 0
        }
      />
    </div>
  );
}

function countAllInstances(cwe) {
  let instances = 0;
  instances += cwe.instances.length;
  for (let child of cwe.children) {
    instances += countAllInstances(child);
  }
  return instances;
}

function countNumMajorFindings(cwe) {
  let major_findings = 0;
  major_findings += cwe.major_findings.length;
  for (let child of cwe.children) {
    major_findings += countNumMajorFindings(child);
  }
  return major_findings;
}
