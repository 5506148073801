import { useEffect, useState, useRef } from "react";
import "./db_setting.css";
import { getAssessorDBLastUpdate, updateAssessorDB } from "../../../api";

// the types of assessors that can have their database updated
export const ASSESSORS = {
  CVES: "cves",
  MALWARE: "malware",
  CPES: "cpes",
};

export const CVE_ASSESSOR_FILE = {
  DEFAULT: "DEFAULT",
  KEV: "KEV",
};

export const MALWARE_ASSESSOR_FILE = {
  DEFAULT: "DEFAULT",
};

export const CPE_ASSESSOR_FILE = {
  DEFAULT: "DEFAULT",
};

export default function DBSetting({
  title,
  description,
  assessor,
  assessor_file,
}) {
  let [lastUpdate, setLastUpdate] = useState(null);
  let [file, setFile] = useState(null);
  let [loading, setLoading] = useState(false);
  let [res, setRes] = useState(null);
  let hiddenFileInput = useRef();

  const init = async () => {
    setLastUpdate(null);
    let lastUpdate = await getAssessorDBLastUpdate(assessor, assessor_file);
    setLastUpdate(lastUpdate);
  };
  useEffect(init, []);

  return (
    <div className="cve-db-setting">
      <h2>{title}</h2>
      <p>{description}</p>
      <div className="server-update-form">
        <div>
          {!loading && (
            <button onClick={() => hiddenFileInput.current.click()}>
              Choose File
            </button>
          )}

          <input
            type="file"
            ref={hiddenFileInput}
            onChange={(e) => {
              setFile(e.target.files[0]);
            }}
            style={{ display: "none" }}
          />
          <p>{file === null ? "No file selected." : file.name}</p>
        </div>

        {!loading && file && (
          <button
            onClick={async () => {
              setLoading(true);
              setRes(null);
              let res = await updateAssessorDB(assessor, assessor_file, file);
              setLoading(false);
              setRes(res);
              init();
            }}
            style={{ marginLeft: 10 }}
          >
            Submit
          </button>
        )}
        {loading && <div className="lds-dual-ring"></div>}
        {res && <p>{res}</p>}
      </div>
      {lastUpdate && <p>Last updated: {lastUpdate}</p>}
      <hr />
    </div>
  );
}
