export const ROUTES = {
  info: {
    description: "Swagger documentation for VAPTBOX binary analysis API",
    title: "VBUI",
    version: "1.0.0",
  },
  securityDefinitions: {
    Bearer: {
      type: "apiKey",
      description: "Value: Bearer ",
      name: "Authorization",
      in: "header",
    },
  },
  security: { Bearer: [] },
  paths: {
    "/sys/logs": {},
    "/sys/stats": {},
    "/swagger/dist/{filename}": {},
    "/swagger/{path}": {},
    "/swagger/logo": {},
    "/swagger/static": {},
    "/": {},
    "/user/login": {
      post: {
        summary: "Login with username and password.",
        description:
          'Gets an "access_token" that may be set as the Bearer Token when calling the "/saas/validate_totp" route. This route acts as the first factor of 2 factor authenticaion. "/saas/validate_totp" acts as the second factor.',
        tags: ["login"],
        security: [{ bearerAuthMFA: [] }],
        requestBody: {
          required: true,
          content: {
            "multipart/form-data": {
              schema: {
                type: "object",
                properties: {
                  username: { type: "string" },
                  password: { type: "string" },
                },
                required: ["username", "password"],
              },
            },
          },
        },
        responses: {
          200: {
            content: {
              "application/json": {
                schema: { $ref: "#/components/schemas/UserDetailsSchema" },
              },
            },
          },
        },
      },
    },
    "/user/generate_totp": {
      get: {
        summary: "Generate a TOTP.",
        description:
          "Generates a time-based one-time password for the current user. This TOTP can be converted into a QR code and scanned with Google Authenticator for 2 Factor Authentication.",
        tags: ["login"],
        security: [{ bearerAuthMFA: [] }],
        responses: {
          200: {
            content: {
              "application/json": {
                schema: { $ref: "#/components/schemas/GenTOTPSchema" },
              },
            },
          },
        },
      },
    },
    "/user/validate_totp": {
      post: {
        summary: "Login with TOTP.",
        description:
          'Validates a user\'s time-based one-time password and return to them a token that may be set as the Bearer Token when accessing all other routes of the API. This route acts as the second factor of 2 factor authentication, with "/saas/mfalogin" being the first.',
        tags: ["login"],
        security: [{ bearerAuthMFA: [] }],
        requestBody: {
          required: true,
          content: {
            "multipart/form-data": {
              schema: {
                type: "object",
                properties: { pincode: { type: "string" } },
                required: ["pincode"],
              },
            },
          },
        },
        responses: {
          200: {
            content: {
              "application/json": {
                schema: { $ref: "#/components/schemas/PinExchangeSchema" },
              },
            },
          },
        },
      },
    },
    "/user/all": {
      get: {
        summary: "Get all users.",
        description: "Gets all the users in an organization.",
        security: [{ bearerAuthMFA: [] }],
        responses: {
          200: {
            content: {
              "application/json": {
                schema: {
                  type: "array",
                  items: { $ref: "#/components/schemas/UsersAllSchema" },
                },
              },
            },
          },
        },
      },
    },
    "/user/add": {
      post: {
        summary: "Create a new user.",
        description:
          "Creates a new user given a username, password, and email. Returns user details, id, dropzone_asset, dropzone_name, etc.",
        requestBody: {
          required: true,
          content: {
            "application/json": {
              schema: {
                type: "object",
                properties: {
                  email: { type: "string" },
                  password: { type: "string" },
                  username: { type: "string" },
                  org_id: { type: "string" },
                },
                required: ["email", "password", "username", "org_id"],
              },
            },
          },
        },
        tags: ["management"],
        security: [{ bearerAuthMFA: [] }],
        responses: {
          200: {
            content: {
              "application/json": {
                schema: { $ref: "#/components/schemas/NewUserSchema" },
              },
            },
          },
        },
      },
    },
    "/user/add_superadmin": {},
    "/user/superadmin_exists": {},
    "/user/addroles": {
      post: {
        summary: "Assigns a user new roles.",
        description:
          'Assigns a user new roles in an organization. The user and org are determined by the userid and orgid fields in the form data. The list of roles being added are defined as a list in the form data. For example, the list [ "operator", "admin" ] would give the operator and admin roles to the specified user in the specified org.',
        requestBody: {
          required: true,
          content: {
            "multipart/form-data": {
              schema: {
                type: "object",
                properties: {
                  roles: { type: "string" },
                  orgid: { type: "string" },
                  userid: { type: "string" },
                },
                required: ["roles", "orgid", "userid"],
              },
            },
          },
        },
        tags: ["management"],
        security: [{ bearerAuthMFA: [] }],
        responses: {
          200: {
            content: {
              "application/json": {
                schema: { $ref: "#/components/schemas/AddRolesSchema" },
              },
            },
          },
        },
      },
    },
    "/user/removeroles": {
      post: {
        summary: "Remove roles from a user.",
        description:
          'Remove roles from a user in an organization. The user and org are determined by the userid and orgid fields in the form data. The list of roles being removed are defined as a list in the form data. For example, the list [ "operator", "admin" ] would remove the operator and admin roles from the specified user in the specified org.',
        requestBody: {
          required: true,
          content: {
            "multipart/form-data": {
              schema: {
                type: "object",
                properties: {
                  roles: { type: "string" },
                  orgid: { type: "string" },
                  userid: { type: "string" },
                },
                required: ["roles", "orgid", "userid"],
              },
            },
          },
        },
        tags: ["management"],
        security: [{ bearerAuthMFA: [] }],
        responses: {
          200: {
            content: {
              "application/json": {
                schema: { $ref: "#/components/schemas/AddRolesSchema" },
              },
            },
          },
        },
      },
    },
    "/user/password": {
      post: {
        summary: "Updates a user's password.",
        description:
          'Updates a user\'s password. The password is changed to whatever is specified in the "password" field of the JSON body.',
        requestBody: {
          required: true,
          content: {
            "application/json": {
              schema: {
                type: "object",
                properties: {
                  userId: { type: "string" },
                  password: { type: "string" },
                },
                required: ["userId", "password"],
              },
            },
          },
        },
        tags: ["management"],
        security: [{ bearerAuthMFA: [] }],
        responses: { 200: { description: "password updated" } },
      },
    },
    "/user/invite/{user_id}": {
      get: {
        summary: "Invite a user.",
        description:
          'Invites a user to the server. Returns an token specific to the "user_id" set in the API route. The token may be redeemed by the user using the "/saas/auth/userinvite/redeem/{invite}" route.',
        tags: ["management"],
        parameters: [
          {
            in: "path",
            name: "user_id",
            schema: { type: "string" },
            required: true,
          },
        ],
        security: [{ bearerAuthMFA: [] }],
        responses: {
          200: {
            content: {
              "application/json": {
                schema: { $ref: "#/components/schemas/InviteUserSchema" },
              },
            },
          },
        },
      },
    },
    "/user/redeem/{invite}": {
      get: {
        summary: "Redeem an invite.",
        description:
          'Redeems an invite generated using the "/auth/userinvite/user/{user_id}" route. Returns a token that may be used to setup a new account.',
        tags: ["management"],
        parameters: [
          {
            in: "path",
            name: "invite",
            schema: { type: "string" },
            required: true,
          },
        ],
        security: [{ bearerAuthMFA: [] }],
        responses: {
          200: {
            content: {
              "application/json": {
                schema: { $ref: "#/components/schemas/RedeemedInvite" },
              },
            },
          },
        },
      },
    },
    "/user/setorg": {
      post: {
        summary: "Swap a user to an org.",
        description:
          'Swaps a user to an org. The org a user is swapped to determines the outcome of many routes. For example, "/assessor/getassets" returns the set of assets in the org which a user is swapped to. Toggling between orgs via the header in the UI calls this route.',
        tags: ["management"],
        requestBody: {
          required: true,
          content: {
            "multipart/form-data": {
              schema: {
                type: "object",
                properties: {
                  userid: { type: "string" },
                  orgid: { type: "string" },
                },
                required: ["userid", "orgid"],
              },
            },
          },
        },
        security: [{ bearerAuthMFA: [] }],
        responses: {
          200: {
            content: {
              "application/json": {
                schema: { $ref: "#/components/schemas/SuccessMessageSchema" },
              },
            },
          },
        },
      },
    },
    "/user/eula": {
      post: {
        summary: "Accept the EULA.",
        description: "Accepts the EULA for the calling user.",
        tags: ["management"],
        security: [{ bearerAuthMFA: [] }],
        responses: {
          200: {
            content: {
              "application/json": {
                schema: { $ref: "#/components/schemas/SuccessMessageSchema" },
              },
            },
          },
        },
      },
    },
    "/user/profile/{userid}": {
      get: {
        summary: "Get user profile.",
        description: "Gets a user's profile.",
        tags: ["management"],
        parameters: [
          {
            in: "path",
            name: "userid",
            schema: { type: "string" },
            required: true,
          },
        ],
        security: [{ bearerAuthMFA: [] }],
        responses: {
          200: {
            content: {
              "application/json": {
                schema: { $ref: "#/components/schemas/NewUserSchema" },
              },
            },
          },
        },
      },
    },
    "/user/invite/reset": {},
    "/org/add": {
      post: {
        summary: "Add an organization.",
        description:
          "Adds an organization. An organization requires a name and description.",
        tags: ["management"],
        security: [{ bearerAuthMFA: [] }],
        requestBody: {
          required: true,
          content: {
            "application/json": {
              schema: {
                type: "object",
                properties: {
                  name: { type: "string" },
                  description: { type: "string" },
                },
                required: ["name", "description"],
              },
            },
          },
        },
        responses: { 200: { description: "org created" } },
      },
    },
    "/org/update": {
      post: {
        summary: "Update an organization.",
        description:
          "Updates an organization. An organization requires a name and description. Specify the org_id field to determine which org will be updated.",
        tags: ["management"],
        security: [{ bearerAuthMFA: [] }],
        requestBody: {
          required: true,
          content: {
            "application/json": {
              schema: {
                type: "object",
                properties: {
                  name: { type: "string" },
                  description: { type: "string" },
                  org_id: { type: "string" },
                },
                required: ["name", "description", "org_id"],
              },
            },
          },
        },
        responses: { 200: { description: "Done" } },
      },
    },
    "/org/audit": {
      get: {
        summary: "Get the audit log.",
        description:
          "Gets the audit log of an organization. The audit logs tracks the history of all analyzed assets in the organization.",
        tags: ["management"],
        security: [{ bearerAuthMFA: [] }],
        responses: {
          200: {
            content: {
              "application/json": {
                schema: { $ref: "#/components/schemas/AuditLogSchema" },
              },
            },
          },
        },
      },
    },
    "/org/alerts": {
      get: {
        summary:
          "Get a list of the flash alerts that have been posted to an organization by the SUPERADMIN.",
        description:
          "This route gets the list of flash alerts that belong to an organization.",
        tags: ["management"],
        security: [{ bearerAuthMFA: [] }],
        responses: {
          200: {
            content: {
              "application/json": {
                schema: {
                  type: "array",
                  items: { $ref: "#/components/schemas/AlertSchema" },
                },
              },
            },
          },
        },
      },
    },
    "/org/createalert": {
      post: {
        summary:
          "Adds a flash alert to the user's current organization. The calling user must be the SUPERADMIN.",
        description:
          "Create a new flash alert to broadcast to all users in the organization.",
        tags: ["management"],
        security: [{ bearerAuthMFA: [] }],
        requestBody: {
          required: true,
          content: {
            "multipart/form-data": {
              schema: {
                type: "object",
                properties: { alert: { type: "string" } },
                required: ["alert"],
              },
            },
          },
        },
        responses: { 200: { description: "Alert created." } },
      },
    },
    "/org/readalert": {
      post: {
        summary: "Marks a flash alert as read by the calling user.",
        description:
          "Marks a flash alert as read, making it not appear highlighted on the calling user's dashboard.",
        tags: ["management"],
        security: [{ bearerAuthMFA: [] }],
        requestBody: {
          required: true,
          content: {
            "multipart/form-data": {
              schema: {
                type: "object",
                properties: { alert_id: { type: "string" } },
                required: ["alert_id"],
              },
            },
          },
        },
        responses: { 200: { description: "Alert read." } },
      },
    },
    "/org/dashboard": {
      get: {
        summary: "Get the dashboard.",
        description:
          "Gets the data needed to generate the dashboard on the UI.",
        tags: ["results"],
        security: [{ bearerAuthMFA: [] }],
        responses: {
          200: {
            content: {
              "application/json": {
                schema: { $ref: "#/components/schemas/DashboardSchema" },
              },
            },
          },
        },
      },
    },
    "/deployment/expiration": {},
    "/deployment/is_feature_enabled": {},
    "/deployment/sys_stats": {},
    "/deployment/license": {
      post: {
        summary: "Apply a license to the ObjectSecurity OT.AI Platform.",
        description: "Updates the platform when provided with an new license.",
        responses: { 200: { description: "License has been applied." } },
      },
    },
    "/deployment/type": {},
    "/deployment/logger/config/elk": {},
    "/deployment/logger/config/elk/disable": {},
    "/deployment/db/{assessor}/{assessor_file}": {},
    "/dropzone/list": {
      get: {
        summary: "Get the binaries in the dropzone.",
        description:
          "Gets the set of binaries uploaded to the dropzone. Use the route /saas/addfile to add a file to the dropzone, and the route /saas/rmfile to remove a file from the dropzone. Use /saas/start to start analyzing the binaries in the dropzone.",
        tags: ["upload"],
        security: [{ bearerAuthMFA: [] }],
        responses: {
          200: {
            content: {
              "application/json": {
                schema: { type: "array", items: { type: "string" } },
              },
            },
          },
        },
      },
    },
    "/dropzone/add": {
      post: {
        summary: "Upload a binary to the dropzone.",
        description:
          'Uploads a binary to the dropzone which may later be analyzed using "/saas/start".',
        tags: ["upload"],
        security: [{ bearerAuthMFA: [] }],
        requestBody: {
          required: true,
          content: {
            "multipart/form-data": {
              schema: {
                type: "object",
                properties: { file: { type: "file" } },
                required: ["file"],
              },
            },
          },
        },
        responses: {
          200: {
            content: {
              "application/json": {
                schema: { $ref: "#/components/schemas/AddFileSchema" },
              },
            },
          },
          400: { description: "Please upload 1 file" },
        },
      },
    },
    "/dropzone/remove": {
      post: {
        summary: "Remove a binary from the dropzone.",
        description:
          "Removes a binary from the dropzone based upon the filename specified in the form data.",
        tags: ["upload"],
        security: [{ bearerAuthMFA: [] }],
        requestBody: {
          required: true,
          content: {
            "multipart/form-data": {
              schema: {
                type: "object",
                properties: { file: { type: "string" } },
                required: ["file"],
              },
            },
          },
        },
        responses: { 200: { description: "Ok" } },
      },
    },
    "/dropzone/remove_all": {},
    "/dropzone/start": {
      post: {
        summary: "Initiate analysis.",
        description:
          "Starts the analysis procedure on the binaries in the dropzone. You can see the progress of the analysis by using the '/assessor/getassetprogress' route.",
        tags: ["upload"],
        security: [{ bearerAuthMFA: [] }],
        requestBody: {
          required: true,
          content: {
            "multipart/form-data": {
              schema: {
                type: "object",
                properties: { facility_id: { type: "string" } },
                required: ["facility_id"],
              },
            },
          },
        },
        responses: {
          200: {
            content: {
              "application/json": {
                schema: { $ref: "#/components/schemas/SuccessSchema" },
              },
            },
          },
        },
      },
    },
    "/dropzone/s3/files/list": {},
    "/dropzone/s3/files/ingress": {},
    "/dropzone/preview/cpes": {},
    "/asset/get": {
      post: {
        summary: "Find a specific asset using asset ref_ID.",
        description:
          "Use this route to get analysis results for a given asset.",
        tags: ["results"],
        security: [{ bearerAuthMFA: [] }],
        requestBody: {
          required: true,
          content: {
            "multipart/form-data": {
              schema: {
                type: "object",
                properties: { asset: { type: "string" } },
                required: ["asset"],
              },
            },
          },
        },
        responses: {
          200: {
            content: {
              "application/json": {
                schema: { $ref: "#/components/schemas/GETASSETSchema" },
              },
            },
          },
        },
      },
    },
    "/asset/add_tag": {
      post: {
        summary: "Add a new tag to an existing asset.",
        description: "Use this route to add a tag to an existing asset.",
        tags: ["results"],
        security: [{ bearerAuthMFA: [] }],
        requestBody: {
          required: true,
          content: {
            "multipart/form-data": {
              schema: {
                type: "object",
                properties: {
                  asset: { type: "string" },
                  tag: { type: "string" },
                },
                required: ["asset", "tag"],
              },
            },
          },
        },
        responses: { 200: { description: "Tag added." } },
      },
    },
    "/asset/remove_tag": {
      post: {
        summary: "Remove an existing tag from an asset.",
        description: "Use this route to remove a tag from an existing asset.",
        tags: ["results"],
        security: [{ bearerAuthMFA: [] }],
        requestBody: {
          required: true,
          content: {
            "multipart/form-data": {
              schema: {
                type: "object",
                properties: {
                  asset: { type: "string" },
                  tag: { type: "string" },
                },
                required: ["asset", "tag"],
              },
            },
          },
        },
        responses: { 200: { description: "Tag removed." } },
      },
    },
    "/asset/get_all": {
      get: {
        summary: "Get a list of analyzed assets.",
        description:
          "Use this route to get a list of your analyses. The 'ref_id' field of each returned object can be used as the 'asset' parameter in the other routes.",
        tags: ["results"],
        security: [{ bearerAuthMFA: [] }],
        parameters: [
          {
            in: "query",
            name: "sortby",
            schema: { type: "string", enum: ["date", "score"] },
            required: false,
          },
          {
            in: "query",
            name: "offset",
            schema: { type: "integer" },
            required: false,
          },
          {
            in: "query",
            name: "limit",
            schema: { type: "integer" },
            required: false,
          },
          {
            in: "limit",
            name: "search",
            schema: { type: "string" },
            required: false,
          },
        ],
        responses: {
          200: {
            content: {
              "application/json": {
                schema: {
                  type: "array",
                  items: { $ref: "#/components/schemas/GETASSETSchema" },
                },
              },
            },
          },
        },
      },
    },
    "/asset/num_new": {
      get: {
        summary: "Get the number of new assets.",
        description:
          "Gets the number of new assets in an organization. New assets are those that haven't been viewed yet after being analyzed.",
        tags: ["results"],
        security: [{ bearerAuthMFA: [] }],
        responses: {
          200: {
            content: {
              "application/json": {
                schema: { $ref: "#/components/schemas/NumNewSchema" },
              },
            },
          },
        },
      },
    },
    "/asset/rename": {
      post: {
        summary: "Rename an asset.",
        description:
          "Renames the specified asset. Specify the ID of the asset and its new name in the form body.",
        tags: ["results"],
        security: [{ bearerAuthMFA: [] }],
        requestBody: {
          required: true,
          content: {
            "multipart/form-data": {
              schema: {
                type: "object",
                properties: {
                  asset: { type: "string" },
                  new_name: { type: "string" },
                },
                required: ["asset", "new_name"],
              },
            },
          },
        },
        responses: { 200: { description: "asset renamed" } },
      },
    },
    "/asset/description": {
      post: {
        summary: "Update an asset's description.",
        description:
          "Updates the specified asset. Specify the ID of the asset and its new description in the form body.",
        tags: ["results"],
        security: [{ bearerAuthMFA: [] }],
        requestBody: {
          required: true,
          content: {
            "multipart/form-data": {
              schema: {
                type: "object",
                properties: {
                  asset: { type: "string" },
                  new_description: { type: "string" },
                },
                required: ["asset", "new_description"],
              },
            },
          },
        },
        responses: { 200: { description: "updated asset description" } },
      },
    },
    "/asset/type": {
      post: {
        summary: "Update an asset's type.",
        description:
          "Updates an asset's type. Specify the ID of the asset and its new type in the form body.",
        tags: ["results"],
        security: [{ bearerAuthMFA: [] }],
        requestBody: {
          required: true,
          content: {
            "multipart/form-data": {
              schema: {
                type: "object",
                properties: {
                  asset: { type: "string" },
                  new_type: {
                    type: "string",
                    enum: ["HMI", "PLC", "COLLECTION"],
                  },
                },
                required: ["asset", "new_type"],
              },
            },
          },
        },
        responses: { 200: { description: "updated asset type" } },
      },
    },
    "/asset/facility/{facility_id}/get_all": {
      get: {
        summary: "Get the asset in a facility.",
        description:
          "Get the assets in a facility. Specify the facility by replacing the {facility_id} field in the API route.",
        tags: ["results"],
        security: [{ bearerAuthMFA: [] }],
        parameters: [
          {
            in: "path",
            name: "facility_id",
            schema: { type: "string" },
            required: true,
            description: "id of the parent facility",
          },
          {
            in: "query",
            name: "sortby",
            schema: { type: "string", enum: ["date", "score"] },
            required: false,
          },
          {
            in: "query",
            name: "offset",
            schema: { type: "integer" },
            required: false,
          },
          {
            in: "query",
            name: "limit",
            schema: { type: "integer" },
            required: false,
          },
          {
            in: "limit",
            name: "search",
            schema: { type: "string" },
            required: false,
          },
        ],
        responses: {
          200: {
            content: {
              "application/json": {
                schema: {
                  type: "array",
                  items: { $ref: "#/components/schemas/GETASSETSchema" },
                },
              },
            },
          },
        },
      },
    },
    "/asset/facility/{facility_id}/by_tag": {},
    "/asset/viewed": {
      post: {
        summary: "Mark an asset as viewed.",
        description:
          "Marks an asset as viewed. Specify the ID of the asset to mark as viewed in the form body.",
        tags: ["results"],
        security: [{ bearerAuthMFA: [] }],
        requestBody: {
          required: true,
          content: {
            "multipart/form-data": {
              schema: {
                type: "object",
                properties: { asset: { type: "string" } },
                required: ["asset"],
              },
            },
          },
        },
        responses: { 200: { description: "marked asset as viewed" } },
      },
    },
    "/asset/vendor_product_version": {},
    "/asset/audit/{assetid}": {
      get: {
        summary: "Get the asset audit log.",
        description:
          "Gets the audit log for a specific asset in the organization.",
        tags: ["management"],
        security: [{ bearerAuthMFA: [] }],
        parameters: [
          {
            in: "path",
            name: "assetid",
            schema: { type: "string" },
            required: true,
          },
        ],
        responses: {
          200: {
            content: {
              "application/json": {
                schema: { $ref: "#/components/schemas/AuditLogSchema" },
              },
            },
          },
        },
      },
    },
    "/asset/delete": {},
    "/asset/{assetid}/result/{assessment}": {},
    "/queue/progress": {
      get: {
        summary: "Get the progress ongoing analyses.",
        description:
          "Get's the current analysis progress of all ongoing analyses in the user's organization.",
        tags: ["results"],
        security: [{ bearerAuthMFA: [] }],
        responses: {
          200: {
            content: {
              "application/json": {
                schema: { $ref: "#/components/schemas/AssetProgressSchema" },
              },
            },
          },
        },
      },
    },
    "/queue/assessment/reprioritize": {},
    "/queue/binary/reprioritize": {},
    "/queue/asset/reprioritize": {},
    "/queue/assessment/stop": {},
    "/queue/asset/stop": {},
    "/queue/binary/stop": {},
    "/facility/get": {
      get: {
        summary: "Get all facilities.",
        description: "Get all the facilities in the organization.",
        tags: ["results"],
        security: [{ bearerAuthMFA: [] }],
        responses: {
          200: {
            content: {
              "application/json": {
                schema: {
                  type: "array",
                  items: { $ref: "#/components/schemas/FacilitySchema" },
                },
              },
            },
          },
        },
      },
    },
    "/facility/create": {
      post: {
        summary: "Create a new facility.",
        description:
          'Create a new facility. A new facility requires the specification of its name, description, and its latitude/longitude. The latLng field is comma separated, ie a valid value is "-118.88232871576709,35.5323729994895".',
        tags: ["results"],
        security: [{ bearerAuthMFA: [] }],
        requestBody: {
          required: true,
          content: {
            "multipart/form-data": {
              schema: {
                type: "object",
                properties: {
                  name: { type: "string" },
                  description: { type: "string" },
                  latLng: { type: "string" },
                },
                required: ["name", "description", "latLng"],
              },
            },
          },
        },
        responses: { 200: { description: "created facility" } },
      },
    },
    "/facility/update": {
      post: {
        summary: "Update an exisiting facility.",
        description:
          'Updates an exisiting facility. Specify the facility to update by setting the "id" field in the form body. The updated facility requires the specification of its name, description, and latitude/longitude. The latLng field is comma separated, ie a valid value is "-118.88232871576709,35.5323729994895".',
        tags: ["results"],
        security: [{ bearerAuthMFA: [] }],
        requestBody: {
          required: true,
          content: {
            "multipart/form-data": {
              schema: {
                type: "object",
                properties: {
                  id: { type: "string" },
                  name: { type: "string" },
                  description: { type: "string" },
                  latLng: { type: "string" },
                },
                required: ["id", "name", "description", "latLng"],
              },
            },
          },
        },
        responses: { 200: { description: "updated facility" } },
      },
    },
    "/facility/default": {
      get: {
        summary: "Get the default facility.",
        description:
          "Each organization has a default facility that cannot be deleted. By default, all assets in an organization are analyzed in this default facility.",
        tags: ["results"],
        security: [{ bearerAuthMFA: [] }],
        responses: { 200: { description: "facility_id" } },
      },
    },
    "/facility/delete/{facility_id}": {
      get: {
        summary: "Delete a facility.",
        description:
          "Delete the facility specified by the {facility_id} in the API route.",
        tags: ["results"],
        security: [{ bearerAuthMFA: [] }],
        parameters: [
          {
            in: "path",
            name: "facility_id",
            schema: { type: "string" },
            required: true,
            description: "id of the facility to delete",
          },
        ],
        responses: { 200: { description: "facility deleted" } },
      },
    },
    "/binary/get": {
      post: {
        summary: "Get list of binaries for a given asset.",
        description:
          "Use this route to get the set of analyzed binaries in a given analysis. The 'name' field of each returned object can be used as a parameter to the 'getresults' route to get the analysis result for the binary corresponding to that name.",
        tags: ["results"],
        security: [{ bearerAuthMFA: [] }],
        requestBody: {
          required: true,
          content: {
            "multipart/form-data": {
              schema: {
                type: "object",
                properties: { asset: { type: "string" } },
                required: ["asset"],
              },
            },
          },
        },
        responses: {
          200: {
            content: {
              "application/json": {
                schema: { $ref: "#/components/schemas/GetBinariesSchema" },
              },
            },
          },
        },
      },
    },
    "/binary/facility/{facility_id}/by_name": {},
    "/binary/facility/{facility_id}/by_cwe": {},
    "/result/get": {
      post: {
        summary: "Get binary assessment results.",
        description:
          "Use this route to get a set of analysis results for a given binary. The type of results returned is specified by the 'assessment' parameter.",
        tags: ["results"],
        security: [{ bearerAuthMFA: [] }],
        requestBody: {
          required: true,
          content: {
            "multipart/form-data": {
              schema: {
                type: "object",
                properties: {
                  asset: { type: "string" },
                  bin: { type: "string" },
                  assessment: {
                    type: "string",
                    enum: [
                      "cwes",
                      "cves",
                      "restrictness",
                      "metadata",
                      "pe",
                      "malware",
                      "vulscore",
                      "decompiled",
                      "disassembly",
                      "crypto",
                      "dangerous_fns",
                    ],
                  },
                },
                required: ["asset", "bin", "assessment"],
              },
            },
          },
        },
        responses: {
          200: {
            description:
              "Results differ depending on which assessment you are getting results for.",
          },
        },
      },
    },
    "/result/deepdivesize": {
      post: {
        summary: "Get line amount in a binary.",
        description:
          "Get the number of lines of a disassembled/decompiled binary.",
        tags: ["results"],
        security: [{ bearerAuthMFA: [] }],
        requestBody: {
          required: true,
          content: {
            "multipart/form-data": {
              schema: {
                type: "object",
                properties: {
                  asset: { type: "string" },
                  bin: { type: "string" },
                  assessment: {
                    type: "string",
                    enum: ["decompile", "disassemble"],
                  },
                },
                required: ["asset", "bin", "assessment"],
              },
            },
          },
        },
        responses: { 200: { description: "The number of lines in the file." } },
      },
    },
    "/result/deepdivelines": {
      post: {
        summary: "Get lines in a binary.",
        description:
          "Use this route to capture a section of the disassembled/decompiled binary file. The result is a group of lines in the binary.",
        tags: ["results"],
        security: [{ bearerAuthMFA: [] }],
        requestBody: {
          required: true,
          content: {
            "multipart/form-data": {
              schema: {
                type: "object",
                properties: {
                  asset: { type: "string" },
                  bin: { type: "string" },
                  assessment: {
                    type: "string",
                    enum: ["decompile", "disassemble"],
                  },
                  offset: { type: "integer" },
                  limit: { type: "integer" },
                },
                required: ["asset", "bin", "assessment", "offset", "limit"],
              },
            },
          },
        },
        responses: { 200: { description: "The subset of lines in the file." } },
      },
    },
    "/result/deepdivefunc": {},
    "/result/deepdivebridge": {},
    "/result/cwe_info": {
      post: {
        summary: "Get information on a set of CWEs",
        description:
          'Get additional information each cwe in a list. Specify which CWEs to get information about by including their number ID in the "cwes" form data list. For example, the list ["710","1119","1109","119"] will fetch information on CWEs 710, 1119, 1109, and 119.',
        tags: ["results"],
        security: [{ bearerAuthMFA: [] }],
        requestBody: {
          required: true,
          content: {
            "multipart/form-data": {
              schema: {
                type: "object",
                properties: { cwes: { type: "string" } },
                required: ["cwes"],
              },
            },
          },
        },
        responses: {
          200: {
            description: "Results differ depending on the list of input CWEs.",
          },
        },
      },
    },
    "/result/get_binary_results_by_hash": {},
    "/admin/logs/service/{svc}": {},
    "/admin/logs/min": {},
    "/admin/logs/all": {},
    "/delta/report/{asset_id_a}/{asset_id_b}": {
      post: {
        summary: "Generate a Binary Delta Report.",
        description:
          "Generates a Binary Delta Report. The JSON body of this request may be populated with optional information relating to the report, such as a title and description.",
        tags: ["management"],
        security: [{ bearerAuthMFA: [] }],
        parameters: [
          {
            in: "path",
            name: "asset_id_a",
            schema: { type: "string" },
            required: true,
            description: "Id of the first asset in the report.",
          },
          {
            in: "path",
            name: "asset_id_b",
            schema: { type: "string" },
            required: true,
            description: "Id of the second asset in the report.",
          },
        ],
        responses: { 200: { description: "delta_report_id" } },
      },
    },
    "/delta/report/{delta_report_id}/status": {
      get: {
        summary: "Get the generation status of a Binary Delta Report.",
        description:
          "Get the generation status of a Binary Delta Report. Takes the delta_report_id returned from the /delta/report/{asset_id_a}/{asset_id_b} route.",
        tags: ["management"],
        security: [{ bearerAuthMFA: [] }],
        parameters: [
          {
            in: "path",
            name: "delta_report_id",
            schema: { type: "string" },
            required: true,
            description: "Id of Binary Delta Report.",
          },
        ],
        responses: { 200: { description: "delta_report_status" } },
      },
    },
    "/delta/report": {
      get: {
        summary: "Gets all Binary Delta Reports.",
        description:
          "Gets all Binary Delta Reports. This route does not download each report PDF, but insteads gets the metadata of all reports available for download.",
        tags: ["management"],
        security: [{ bearerAuthMFA: [] }],
        responses: {
          200: {
            content: {
              "application/json": {
                schema: { $ref: "#/components/schemas/DeltaReportSchema" },
              },
            },
          },
        },
      },
    },
    "/delta/report/{delta_report_id}/delete": {
      post: {
        summary: "Delete a Binary Delta Report.",
        description: "Deletes a Binary Delta Report.",
        tags: ["management"],
        security: [{ bearerAuthMFA: [] }],
        parameters: [
          {
            in: "path",
            name: "delta_report_id",
            schema: { type: "string" },
            required: true,
            description: "Id of Binary Delta Report to delete.",
          },
        ],
        responses: { 200: { description: "Successfully deleted report." } },
      },
    },
    "/delta/report/{delta_report_id}": {
      get: {
        summary: "Downloads a Binary Delta Report PDF.",
        description: "Downloads a Binary Delta Report PDF.",
        tags: ["management"],
        security: [{ bearerAuthMFA: [] }],
        parameters: [
          {
            in: "path",
            name: "delta_report_id",
            schema: { type: "string" },
            required: true,
            description: "Id of Binary Delta Report.",
          },
        ],
        responses: { 200: { description: "PDF File" } },
      },
    },
  },
  openapi: "3.0.2",
  components: {
    schemas: {
      BaseAssetSchema: {
        type: "object",
        properties: {
          org_id: { type: "string" },
          uuid: { type: "string" },
          asset_id: { type: "string" },
        },
      },
      BaseBinarySchema: {
        type: "object",
        properties: {
          binary_id: { type: "string" },
          org_id: { type: "string" },
          uuid: { type: "string" },
          asset_id: { type: "string" },
        },
      },
      LoginSchema: {
        type: "object",
        properties: {
          password: { type: "string" },
          username: { type: "string" },
        },
      },
      AccountSchema: {
        type: "object",
        properties: { org_id: { type: "string" }, uuid: { type: "string" } },
      },
      RootAccountSchema: {
        type: "object",
        properties: { org_id: { type: "string" } },
      },
      OperatorSchema: {
        type: "object",
        properties: { username: { type: "string" }, uuid: { type: "string" } },
      },
      UserDetailsSchema: {
        type: "object",
        properties: {
          org_id: { type: "string" },
          access_token: { type: "string" },
          mfastep: { type: "integer" },
          username: { type: "string" },
          id: { type: "string" },
          email: { type: "string" },
          eula: { type: "boolean" },
        },
      },
      PasswordSchema: {
        type: "object",
        properties: { password: { type: "string" } },
      },
      PaginationSchema: {
        type: "object",
        properties: { page: { type: "integer" } },
      },
      AuthenticationTokenSchema: {
        type: "object",
        properties: { token: { type: "integer" } },
      },
      PlainAssetSchema: {
        type: "object",
        properties: { asset_id: { type: "string" } },
      },
      AssetQuerySchema: {
        type: "object",
        properties: {
          org_id: { type: "string" },
          asset_id: { type: "string" },
        },
      },
      CWESchema: { type: "object", properties: { cwe: { type: "string" } } },
      AssessmentSchema: {
        type: "object",
        properties: {
          cwss_score: { type: "string" },
          cwes: {
            type: "array",
            items: { $ref: "#/components/schemas/CWESchema" },
          },
        },
      },
      BinarySchema: {
        type: "object",
        properties: {
          cwss_score: { type: "string" },
          cwes: {
            type: "array",
            items: { $ref: "#/components/schemas/CWESchema" },
          },
          assessments: { type: "object" },
        },
      },
      AssetSchema: {
        type: "object",
        properties: {
          metadata: { type: "object" },
          asset_id: { type: "string" },
        },
      },
      AssessmentsSchema: {
        type: "object",
        properties: {
          assets: {
            type: "array",
            items: { $ref: "#/components/schemas/AssessmentSchema" },
          },
        },
      },
      DevicesSchema: {
        type: "object",
        properties: {
          assets: {
            type: "array",
            items: { $ref: "#/components/schemas/AssetSchema" },
          },
        },
      },
      BinariesSchema: {
        type: "object",
        properties: {
          binaries: {
            type: "array",
            items: { $ref: "#/components/schemas/BinarySchema" },
          },
        },
      },
      OnboardDeviceSchema: {
        type: "object",
        properties: {
          labeling: {
            type: "object",
            additionalProperties: { type: "string" },
          },
          packaging: {
            type: "object",
            additionalProperties: { type: "string" },
          },
          exterior_ports: { type: "object" },
        },
      },
      OperatorMetaAnalysisSchema: {
        type: "object",
        properties: {
          ready: { type: "boolean" },
          data: {
            type: "object",
            additionalProperties: {
              type: "object",
              additionalProperties: { type: "string" },
            },
          },
        },
      },
      B64Schema: { type: "object", properties: { base64: { type: "string" } } },
      PortSchema: {
        type: "object",
        properties: { type: { type: "string" }, terminal: { type: "boolean" } },
      },
      InitPortsSchema: {
        type: "object",
        properties: {
          device: { type: "object" },
          org_id: { type: "string" },
          ports: {
            type: "array",
            items: { $ref: "#/components/schemas/PortSchema" },
          },
          asset_id: { type: "string" },
        },
      },
      UpdatePortsSchema: {
        type: "object",
        properties: {
          ports: {
            type: "array",
            items: { $ref: "#/components/schemas/PortSchema" },
          },
          asset_id: { type: "string" },
        },
      },
      SequenceDeviceSchema: {
        type: "object",
        properties: {
          product: { type: "string" },
          manufacturer: { type: "string" },
          serial_number: { type: "string" },
        },
      },
      SequenceClientSchema: {
        type: "object",
        properties: {
          abilities: { type: "array", items: { type: "string" } },
          imei: { type: "string" },
        },
      },
      SequencedAssetsSchema: {
        type: "object",
        properties: {
          assets: {
            type: "array",
            items: { $ref: "#/components/schemas/PlainAssetSchema" },
          },
        },
      },
      IMEISchema: {
        type: "object",
        properties: { imei: { type: "string" }, asset_id: { type: "string" } },
      },
      ConnectionSchema: {
        type: "object",
        properties: {
          parity: { type: "string" },
          baud: { type: "string" },
          port: { type: "string" },
        },
      },
      AssetConnectionSchema: {
        type: "object",
        properties: {
          connection: { $ref: "#/components/schemas/ConnectionSchema" },
          asset_id: { type: "string" },
        },
      },
      MultimediaSchema: {
        type: "object",
        properties: {
          text: { type: "string" },
          audio: { type: "string" },
          image: { type: "string" },
          video: { type: "string" },
        },
      },
      StepSequenceSchema: {
        type: "object",
        properties: {
          step: { type: "string" },
          connection: { $ref: "#/components/schemas/ConnectionSchema" },
          dialog: { type: "string" },
          multimedia: { $ref: "#/components/schemas/MultimediaSchema" },
        },
      },
      AssessmentsInputSchema: {
        type: "object",
        properties: {
          scripts_url: { type: "string" },
          assessment: { type: "string" },
          filename: { type: "string" },
          write_url: { type: "string" },
          mount_dir: { type: "string" },
        },
      },
      AssessmentResultsSchema: {
        type: "object",
        properties: {
          loc: { type: "string" },
          id: { type: "string" },
          cwe: { type: "string" },
          desc: { type: "string" },
        },
      },
      AssessmentsOutputSchema: {
        type: "object",
        properties: {
          results: {
            type: "array",
            items: { $ref: "#/components/schemas/AssessmentResultsSchema" },
          },
        },
      },
      HeartbeatSchema: {
        type: "object",
        properties: { status: { type: "string" } },
      },
      NotImplementedSchema: {
        type: "object",
        properties: { implemented: { type: "boolean" } },
      },
      HwiRegisterRequest: {
        type: "object",
        properties: {
          capabilities: { type: "array", items: { type: "string" } },
          imei: { type: "string" },
        },
      },
      HwiRegisterResponse: {
        type: "object",
        properties: { hwic_id: { type: "string" } },
      },
      HwiPortUpdateRequest: {
        type: "object",
        properties: {
          parity: { type: "string" },
          stopbit: { type: "string" },
          port: { type: "string" },
          os: { type: "string" },
          product: { type: "string" },
          baud: { type: "string" },
        },
      },
      HwiPortUpdateResponse: {
        type: "object",
        properties: { proc_id: { type: "string" } },
      },
      HwiStatusRequest: {
        type: "object",
        properties: {
          log: { type: "string" },
          token: { type: "string" },
          state: { type: "string" },
          asset: { type: "string" },
        },
      },
      HwiStatusResponse: {
        type: "object",
        properties: {
          data: { type: "string" },
          cur_state: { type: "string" },
          token: { type: "string" },
          next_op: { type: "string" },
        },
      },
      HwiErrorRequest: {
        type: "object",
        properties: {
          error_msg: { type: "string" },
          token: { type: "string" },
          error_code: { type: "string" },
          asset: { type: "string" },
        },
      },
      HwiErrorResponse: {
        type: "object",
        properties: {
          cur_state: { type: "string" },
          token: { type: "string" },
          next_op: { type: "string" },
        },
      },
      HwiUploadCompleteRequest: {
        type: "object",
        properties: {
          org_id: { type: "string" },
          asset_id: { type: "string" },
        },
      },
      HwiUploadCompleteResponse: {
        type: "object",
        properties: { status: { type: "string" } },
      },
      SaaSLoginSchema: {
        type: "object",
        properties: {
          password: { type: "string" },
          username: { type: "string" },
        },
      },
      RemoveFileSchema: {
        type: "object",
        properties: { username: { type: "string" }, file: { type: "string" } },
      },
      StartSchema: {
        type: "object",
        properties: { status: { type: "string" }, queue: { type: "string" } },
      },
      AddUserSchema: {
        type: "object",
        properties: {
          password: { type: "string" },
          username: { type: "string" },
          org_id: { type: "string" },
          email: { type: "string" },
        },
      },
      NewUserSchema: {
        type: "object",
        properties: {
          id: { type: "string" },
          username: { type: "string" },
          details: { $ref: "#/components/schemas/UserDetailsSchema" },
          email: { type: "string" },
        },
      },
      UserRolesSchema: {
        type: "object",
        properties: { org_id: { type: "array", items: { type: "string" } } },
      },
      UsersAllSchema: {
        type: "object",
        properties: {
          id: { type: "string" },
          username: { type: "string" },
          roles: { $ref: "#/components/schemas/UserRolesSchema" },
          email: { type: "string" },
        },
      },
      PinExchangeSchema: {
        type: "object",
        properties: {
          access_token: { type: "string" },
          mfastep: { type: "integer" },
          details: { $ref: "#/components/schemas/UserDetailsSchema" },
          username: { type: "string" },
          email: { type: "string" },
          id: { type: "string" },
          pinsRemaining: { type: "integer" },
          roles: { $ref: "#/components/schemas/UserRolesSchema" },
        },
      },
      PincodeSchema: {
        type: "object",
        properties: { pincodes: { type: "object" } },
      },
      AddFileSchema: {
        type: "object",
        properties: { status: { type: "string" }, files: { type: "object" } },
      },
      QuestionnaireSchema: {
        type: "object",
        properties: {
          SC: { type: "string" },
          C: { type: "string" },
          AP: { type: "string" },
          AL: { type: "string" },
          BI: { type: "string" },
        },
      },
      SuccessSchema: {
        type: "object",
        properties: { status: { type: "string" } },
      },
      ASSETSessionSchema: {
        type: "object",
        properties: {
          started: { type: "string" },
          percentage: { type: "integer" },
          state: { type: "string" },
          ended: { type: "string" },
          name: { type: "string" },
          ref_id: { type: "string" },
        },
      },
      GETASSETSchema: {
        type: "object",
        properties: {
          percentage: { type: "integer" },
          started: { type: "string" },
          type: { type: "string" },
          state: { type: "string" },
          viewed: { type: "boolean" },
          cwss_score: { type: "string" },
          facility_id: { type: "string" },
          ended: { type: "string" },
          name: { type: "string" },
          ref_id: { type: "string" },
        },
      },
      CWSSSchema: {
        type: "object",
        properties: {
          score: { type: "string" },
          aggregate: { type: "string" },
          base: { type: "string" },
          attack: { type: "string" },
          environment: { type: "string" },
        },
      },
      BinListSchema: {
        type: "object",
        properties: {
          percentage: { type: "string" },
          state: { type: "string" },
          cwss: { $ref: "#/components/schemas/CWSSSchema" },
          name: { type: "string" },
          color: { type: "string" },
        },
      },
      BinaryListSchema: {
        type: "object",
        properties: {
          assessable: {
            type: "array",
            items: { $ref: "#/components/schemas/BinListSchema" },
          },
          queued: { type: "object" },
          unassessable: {
            type: "array",
            items: { $ref: "#/components/schemas/BinListSchema" },
          },
        },
      },
      DropzoneSchema: {
        type: "object",
        properties: { arr: { type: "object" } },
      },
      CWEResultSchema: {
        type: "object",
        properties: {
          loc: { type: "string" },
          cwe: { type: "string" },
          _id: { type: "string" },
          msg: { type: "string" },
        },
      },
      CWEResultsSchema: {
        type: "object",
        properties: {
          cwe_num: {
            type: "array",
            items: { $ref: "#/components/schemas/CWEResultSchema" },
          },
        },
      },
      CWEListSchema: {
        type: "object",
        properties: { arr: { $ref: "#/components/schemas/CWEResultsSchema" } },
      },
      CVEResultSchema: {
        type: "object",
        properties: {
          score: { type: "string" },
          vendor: { type: "string" },
          version: { type: "string" },
          cve_number: { type: "string" },
          product: { type: "string" },
          comments: { type: "string" },
          paths: { type: "string" },
          cvss_version: { type: "string" },
          severity: { type: "string" },
          remarks: { type: "string" },
        },
      },
      CVEResultsSchema: {
        type: "object",
        properties: {
          cve: {
            type: "array",
            items: { $ref: "#/components/schemas/CVEResultSchema" },
          },
        },
      },
      CVEListSchema: {
        type: "object",
        properties: {
          lib_name: {
            type: "array",
            items: { $ref: "#/components/schemas/CVEResultSchema" },
          },
        },
      },
      DashboardAssetSchema: {
        type: "object",
        properties: {
          name: { type: "string" },
          id: { type: "string" },
          facility_id: { type: "string" },
          vulnerability: { type: "number" },
        },
      },
      FacilitySchema: {
        type: "object",
        properties: {
          latLng: { type: "string" },
          assets_nYellow: { type: "integer" },
          assets_nRed: { type: "integer" },
          description: { type: "string" },
          vulnerability_score: { type: "number" },
          new: { type: "boolean" },
          id: { type: "string" },
          assets_nGreen: { type: "integer" },
          name: { type: "string" },
          ref_id: { type: "string" },
        },
      },
      TopRiskSchema: {
        type: "object",
        properties: {
          score: { type: "number" },
          asset_name: { type: "string" },
          asset: { type: "string" },
          type: { type: "string" },
          date: { type: "integer" },
          facility_id: { type: "string" },
          binary: { type: "string" },
          name: { type: "string" },
          ref_id: { type: "string" },
          desc: { type: "string" },
        },
      },
      DashboardSchema: {
        type: "object",
        properties: {
          defaultFacility: { type: "string" },
          assets_nGreen: { type: "integer" },
          assets_nYellow: { type: "integer" },
          assets_nRed: { type: "integer" },
          assets_total: { type: "integer" },
          ref_id: { type: "string" },
          assets_vulnerable: { type: "integer" },
          binaries_total: { type: "integer" },
          vulnerabilities_published: { type: "integer" },
          binaries_vulnerable: { type: "integer" },
          high_alerts: {
            type: "array",
            items: { $ref: "#/components/schemas/TopRiskSchema" },
          },
          vulnerabilities_unpublished: { type: "integer" },
          facilities: {
            type: "array",
            items: { $ref: "#/components/schemas/FacilitySchema" },
          },
          facilities_total: { type: "integer" },
          users_in_org: { type: "integer" },
          facilities_vulnerable: { type: "integer" },
          top_risks: {
            type: "array",
            items: { $ref: "#/components/schemas/TopRiskSchema" },
          },
          assets: {
            type: "array",
            items: { $ref: "#/components/schemas/DashboardAssetSchema" },
          },
          users_total: { type: "integer" },
          name: { type: "string" },
        },
      },
      TopRisksSchema: {
        type: "object",
        properties: {
          publishedVulnerabilities: { type: "integer" },
          unpublishedVulnerabilities: { type: "integer" },
          topRisks: {
            type: "array",
            items: { $ref: "#/components/schemas/TopRiskSchema" },
          },
        },
      },
      AuditDataSchema: {
        type: "object",
        properties: { name: { type: "string" }, asset_id: { type: "string" } },
      },
      AuditSchema: {
        type: "object",
        properties: {
          timestamp: { type: "integer" },
          type: { type: "string" },
          asset_id: { type: "string" },
          name: { type: "string" },
          ref_id: { type: "string" },
          ent_type: { type: "string" },
          data: { $ref: "#/components/schemas/AuditDataSchema" },
        },
      },
      AuditLogSchema: {
        type: "object",
        properties: {
          audit: {
            type: "array",
            items: { $ref: "#/components/schemas/AuditSchema" },
          },
        },
      },
      ExpirationSchema: {
        type: "object",
        properties: {
          maxAssets: { type: "integer" },
          assets: { type: "integer" },
          timeout: { type: "integer" },
        },
      },
      BinaryProgressSingleSchema: {
        type: "object",
        properties: { binary_name: { type: "integer" } },
      },
      AssetProgressSingleSchema: {
        type: "object",
        properties: {
          name: { type: "string" },
          binaries: { $ref: "#/components/schemas/BinaryProgressSingleSchema" },
          completed: { type: "integer" },
        },
      },
      AssetProgressSchema: {
        type: "object",
        properties: {
          asset_id: { $ref: "#/components/schemas/AssetProgressSingleSchema" },
        },
      },
      GetBinariesSingleSchema: {
        type: "object",
        properties: {
          percentage: { type: "integer" },
          state: { type: "string" },
          cwss: { $ref: "#/components/schemas/CWSSSchema" },
          name: { type: "string" },
          color: { type: "string" },
        },
      },
      GetBinariesSchema: {
        type: "object",
        properties: {
          assessable: {
            type: "array",
            items: { $ref: "#/components/schemas/GetBinariesSingleSchema" },
          },
          unassessable: {
            type: "array",
            items: { $ref: "#/components/schemas/GetBinariesSingleSchema" },
          },
        },
      },
      GetResultsSchema: {
        type: "object",
        properties: { depends_on_the_type_of_results: { type: "string" } },
      },
      GenTOTPSchema: { type: "object", properties: { qr: { type: "string" } } },
      AddRolesSchema: {
        type: "object",
        properties: { roles: { type: "array", items: { type: "string" } } },
      },
      InviteUserSchema: {
        type: "object",
        properties: { invite: { type: "string" } },
      },
      SuccessMessageSchema: {
        type: "object",
        properties: { message: { type: "string" } },
      },
      RedeemedInvite: {
        type: "object",
        properties: {
          email: { type: "string" },
          username: { type: "string" },
          id: { type: "string" },
          access_token: { type: "string" },
        },
      },
      NumNewSchema: {
        type: "object",
        properties: { numNew: { type: "integer" } },
      },
      AlertSchema: {
        type: "object",
        properties: {
          datetime: { type: "integer" },
          readby: { type: "array", items: { type: "string" } },
          type: { type: "string" },
          title: { type: "string" },
          ref_id: { type: "string" },
          text: { type: "string" },
        },
      },
      DeltaReportSchema: {
        type: "object",
        properties: {
          asset_name_b: { type: "string" },
          org_id: { type: "string" },
          status: { type: "string" },
          date: { type: "string" },
          id: { type: "string" },
          asset_id_a: { type: "string" },
          asset_name_a: { type: "string" },
          asset_id_b: { type: "string" },
        },
      },
    },
    securitySchemes: {
      bearerAuth: {
        type: "http",
        scheme: "bearer",
        bearerFormat: "JWT",
        description: "Authentication token before MFA (from mfalogin)",
      },
      bearerAuthMFA: {
        type: "http",
        scheme: "bearer",
        bearerFormat: "JWT",
        description: "Authentication token after MFA (from mfapinexchange)",
      },
    },
  },
};
