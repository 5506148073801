import "./copyright.css";

/**
 * Copyright information that is displayed in the bottom-left
 * corner of the Botbar, and at the bottom-center of any
 * login-related page components.
 * This component can be clicked to view an extended alert
 * with additional copyright information.
 */
export default function Copyright() {
  let year = new Date().getFullYear();
  if (year > 2021) {
    year = `2021 - ${year}`;
  }

  return (
    <div className="copyright-row">
      <a
        className="copyright"
        onClick={() =>
          alert(
            `\n© Copyright ${year} \nObjectSecurity, ObjectSecurity Logo Design, OT.AI, OT.AI Logo Design and ThreatAI are trademarks of ObjectSecurity LLC. \nAll other trademarks, logos, and brand names are the property of their respective owners. \nAll Rights Reserved.\n\nOBJECTSECURITY PROPRIETARY\n \nDO NOT REDISTRIBUTE\nThis software may not be modified, transferred, packaged, redistributed, or disclosed without prior written permission from ObjectSecurity LLC.\n \nAll information contained herein is, and remains the property of ObjectSecurity LLC and its suppliers, if any.  The intellectual and technical concepts contained herein are proprietary to ObjectSecurity LLC and its suppliers and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless prior written permission is obtained from ObjectSecurity LLC.\n \nAny reproduction of technical data, computer software, or portions thereof marked with this legend must also reproduce the markings.`
          )
        }
      >
        &copy; Copyright {year} | ObjectSecurity
      </a>
      <a href="https://support.objectsecurity.com/support/solutions/articles/72000587084-objectsecurity-privacy-policy">
        Privacy Policy
      </a>
    </div>
  );
}
