import "./elk_logger_input.css";
import { useState, useEffect } from "react";
import { Checkbox } from "@mui/material";
import {
  getELKLoggerConfig,
  configELKLogger,
  disableELKLogger,
} from "../../api";

// icons
import { ChevronDown } from "react-feather";

// enum of possible ELKLogger protocols
const PROTOCOL = {
  HTTP: "HTTP",
  TCP: "TCP",
  UDP: "UDP",
};

export default function ELKLoggerInput() {
  let [drop, setDrop] = useState(false);

  // are we awaiting a response from the server?
  let [loading, setLoading] = useState(true);

  // fields required to define an ELKLogger
  let [protocol, setProtocol] = useState(PROTOCOL.HTTP);
  let [host, setHost] = useState("");
  let [port, setPort] = useState("");
  let [ssl, setSSL] = useState(false);

  // server response text
  let [res, setRes] = useState(null);

  // is the ELKLogger currently enabled or not
  let [enabled, setEnabled] = useState(false);

  /**
   * Get the ELKLogger target URL.
   */
  const init = async () => {
    let config = await getELKLoggerConfig();

    // config === null iff there is no ELKLogger configured
    if (config) {
      // set ELKLogger config parameters
      setProtocol(config.protocol);
      setHost(config.host);
      setPort(config.port);
      setSSL(config.ssl_enabled);
      setRes("The ELK stack is configured.");
      setEnabled(true);
    }
    setLoading(false);
  };
  useEffect(init, []);

  /**
   * Update ELKLogger with the target URL.
   */
  const updateElkConfig = async () => {
    setRes(null);
    let res = await configELKLogger(protocol, host, port, ssl);
    setRes(res);
    if (res === "The ELK stack has been successfully configured.") {
      setEnabled(true);
      return;
    }
    setEnabled(false);
  };

  /**
   * Disable ELK logging.
   */
  const disableELK = async () => {
    let res = await disableELKLogger();
    setRes(res);
    setHost("");
    setPort("");
    setSSL(false);
    setEnabled(false);
  };

  return (
    <div className="elk-logger-input">
      <h2>Configure ELK Stack</h2>
      <p>
        The ObjectSecurity OT.AI Platform may optionally be configured to log
        all binary assessments results to an ELK (Elasticsearch, Logstash,
        Kibana) stack of your choosing.
      </p>
      <div>
        {!loading && (
          <>
            <div className="row">
              <div
                className="protocol-select"
                onClick={() => setDrop((drop) => !drop)}
              >
                {protocol} <ChevronDown className="icon" />
                {drop && (
                  <div className="protocol-select-drop">
                    <div onClick={() => setProtocol(PROTOCOL.HTTP)}>HTTP</div>
                    <div onClick={() => setProtocol(PROTOCOL.TCP)}>TCP</div>
                    <div onClick={() => setProtocol(PROTOCOL.UDP)}>UDP</div>
                  </div>
                )}
              </div>
              <input
                className=""
                value={host}
                onChange={(e) => setHost(e.target.value)}
                placeholder="Host (example.stack.io)"
              />
              <input
                className=""
                value={port}
                onChange={(e) => setPort(e.target.value)}
                placeholder="Port (31311)"
              />
              <Checkbox
                onChange={(e) => {
                  setSSL(e.target.checked);
                }}
                checked={ssl}
              />
              <span className="ssl-enabled-txt">SSL Enabled</span>
            </div>
            <hr />
            <div className="row">
              <button onClick={updateElkConfig}>Submit</button>
              {enabled && (
                <button className="disable-elk" onClick={disableELK}>
                  Disable ELK
                </button>
              )}
            </div>
          </>
        )}

        {loading && <div className="lds-dual-ring"></div>}
      </div>

      {res && <p>{res}</p>}
    </div>
  );
}
