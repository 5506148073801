import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { Binary } from "../../../constants";
import "./score_cause.css";
import ScoreReasoner from "./score_reasoner";

/**
 * Displays the cause of why a binary was given a certain score.
 */
export default function ScoreCause({
  score,
  asset_id,
  binary,
  cwss,
  clickFunction,
  fromReportPDF,
  done,
}) {
  let [thinking, setThinking] = useState(true);
  let [reason, setReason] = useState(null);
  let [clickable, setClickable] = useState(false);

  // grab data from API to stop "thinking"
  useEffect(async () => {
    let scoreReasoner = new ScoreReasoner(score, asset_id, binary, cwss);
    setReason(await scoreReasoner.reason());
    if (!fromReportPDF) setClickable(scoreReasoner.isClickable());
    setThinking(false);
    if (done) done();
  }, [binary]);

  if (score === "GREEN") return null;

  return (
    <div
      className={
        clickable ? "score-cause score-cause-clickable" : "score-cause"
      }
      onClick={() => {
        if (!clickable) return;
        clickFunction(clickable);
      }}
    >
      <div className="left-row">
        <div style={{ width: "100%" }}>
          <h3 className="why">
            Why has this binary been scored {score.toLowerCase()}?
          </h3>
          {thinking && (
            <div className="lds-dual-ring lds-dual-ring-center"></div>
          )}
          {!thinking && reason}
        </div>
      </div>
    </div>
  );
}

ScoreCause.propTypes = {
  /**
   * Color score of the binary.
   */
  score: PropTypes.oneOf(["RED", "YELLOW", "GREEN"]).isRequired,

  /**
   * The ID of the parent asset.
   */
  asset_id: PropTypes.string.isRequired,

  /**
   * The scored binary.
   */
  binary: PropTypes.instanceOf(Binary).isRequired,

  /**
   * The CWSS score of the binary.
   */
  cwss: PropTypes.number.isRequired,
  /**
   * Callback function that is called when the component
   * is clicked.
   * Typically navigates to another tab of the Binary
   * Summary panel.
   */
  clickFunction: PropTypes.func,

  /**
   * Is this component being displayed on the customizable
   * report?
   */
  fromReportPDF: PropTypes.bool,
};
