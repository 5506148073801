import React from "react";
import "../../index.css";
import "./botbar.css";
import { getExpiration, getSysStats } from "../../api";
import { VERSION, bytesToSize } from "../../helpers";

/**
 * Footer that appears on every page of the application.
 * Shows version number, flash alerts, copyright, and optional expiration.
 */
export default class Botbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // expiration data
      countdown: null,
      binariesRemaining: null,
      assetsRemaining: null,
      memory: null,
      cpu: null,
    };
    this.init = this.init.bind(this);
    this.updateTimeout = this.updateTimeout.bind(this);
  }

  componentDidMount() {
    this.init();
  }

  componentWillUnmount() {
    clearInterval(this.updateTimeout);
  }

  async init() {
    try {
      let sys_stats = await getSysStats();
      this.setState({
        cpu:
          "CPU Load: " +
          Math.round(
            sys_stats.system.cpu.pct_used.reduce((a, b) => a + b) /
              sys_stats.system.cpu.pct_used.length
          ) +
          "%",
        memory:
          "Memory: " +
          bytesToSize(
            sys_stats.system.memory.total - sys_stats.system.memory.available
          ) +
          " / " +
          bytesToSize(sys_stats.system.memory.total),
      });
    } catch (e) {
      console.log(e);
    }

    let expiration = await getExpiration();
    if (expiration === "No expiration.") return;
    if (
      expiration.hasOwnProperty("maxBinaries") &&
      expiration.hasOwnProperty("binaries")
    )
      this.setState({
        binariesRemaining: `${expiration.binaries} / ${expiration.maxBinaries} Binaries`,
      });
    if (
      expiration.hasOwnProperty("maxAssets") &&
      expiration.hasOwnProperty("assets")
    )
      this.setState({
        assetsRemaining: `${expiration.assets} / ${expiration.maxAssets} Assets`,
      });

    if (new Date(expiration.timeout * 1000) < new Date()) {
      this.setState({ countdown: "Expired! - No Time Remaining" });
      return;
    }

    this.countDownDate = new Date(expiration.timeout * 1000).getTime();
    this.updateTimeout();
    setInterval(this.updateTimeout, 1000);
  }

  updateTimeout() {
    if (isNaN(this.countDownDate)) {
      this.setState({
        countdown: "Your deployment in unlicensed!",
      });
      return;
    }

    var now = new Date().getTime();
    var timeleft = this.countDownDate - now;
    var days = Math.floor(timeleft / (1000 * 60 * 60 * 24));
    var hours = Math.floor(
      (timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    var minutes = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((timeleft % (1000 * 60)) / 1000);
    this.setState({
      countdown: `${days}:${hours}:${minutes}:${seconds} Time Remaining`,
    });
  }

  /**
   * Render the component.
   */
  render() {
    let year = new Date().getFullYear();
    if (year > 2021) {
      year = `2021 - ${year}`;
    }

    return (
      <footer>
        <h3
          className="alpha"
          style={{
            marginLeft: 20,
            fontSize: 13,
            minWidth: 70,
            maxWidth: 70,
            marginRight: 0,
          }}
        >
          {VERSION}
        </h3>

        <h3
          className="alpha copyright-alpha"
          style={{
            fontSize: 13,
            marginLeft: 10,
            marginRight: 10,
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            cursor: "pointer",
          }}
          onClick={() =>
            alert(
              `\n© Copyright ${year} \nObjectSecurity, ObjectSecurity Logo Design, OT.AI, OT.AI Logo Design and ThreatAI are trademarks of ObjectSecurity LLC. \nAll other trademarks, logos, and brand names are the property of their respective owners. \nAll Rights Reserved.\n\nOBJECTSECURITY PROPRIETARY\n \nDO NOT REDISTRIBUTE\nThis software may not be modified, transferred, packaged, redistributed, or disclosed without prior written permission from ObjectSecurity LLC.\n \nAll information contained herein is, and remains the property of ObjectSecurity LLC and its suppliers, if any.  The intellectual and technical concepts contained herein are proprietary to ObjectSecurity LLC and its suppliers and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless prior written permission is obtained from ObjectSecurity LLC.\n \nAny reproduction of technical data, computer software, or portions thereof marked with this legend must also reproduce the markings.`
            )
          }
        >
          © Copyright {year} | ObjectSecurity
        </h3>

        <a
          style={{
            fontSize: 13,
            marginLeft: 10,
            marginRight: "auto",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            cursor: "pointer",
            color: "var(--pri-color-light)",
            fontWeight: 400,
            textDecoration: "none",
          }}
          target="_blank"
          href="https://support.objectsecurity.com/support/solutions/articles/72000587084-objectsecurity-privacy-policy"
        >
          Privacy Policy
        </a>

        {this.state.cpu && (
          <h3
            className="alpha"
            style={{
              fontSize: 13,

              minWidth: "fit-content",
              maxWidth: "fit-content",
              marginRight: 20,
            }}
          >
            {this.state.cpu}
          </h3>
        )}
        {this.state.memory && (
          <h3
            className="alpha"
            style={{
              fontSize: 13,
              minWidth: "fit-content",
              maxWidth: "fit-content",
              marginRight: 20,
              marginLeft: 0,
            }}
          >
            {this.state.memory}
          </h3>
        )}
        {this.state.binariesRemaining && (
          <h3
            className="alpha"
            style={{
              fontSize: 13,
              marginLeft: 0,
              minWidth: "fit-content",
              maxWidth: "fit-content",
              marginRight: 20,
            }}
          >
            {this.state.binariesRemaining}
          </h3>
        )}
        {this.state.assetsRemaining && (
          <h3
            className="alpha"
            style={{
              fontSize: 13,
              marginLeft: 0,
              minWidth: "fit-content",
              maxWidth: "fit-content",
              marginRight: 20,
            }}
          >
            {this.state.assetsRemaining}
          </h3>
        )}

        {this.state.countdown && (
          <h3
            className="alpha"
            style={{
              marginLeft: 0,
              fontSize: 13,

              minWidth: 180,
              maxWidth: 180,
              marginRight: 15,
            }}
          >
            {this.state.countdown}
          </h3>
        )}
      </footer>
    );
  }
}
