import { getBasePath } from ".";

/**
 * Get all the server logs.
 */
export async function getAllLogs() {
  window.open(getBasePath() + "/admin/logs/all", "_blank");
}

/**
 * Show the user minified server logs. (Presented as a scannable QR code).
 * This function is called from a button click on the right-sidebar.
 */
export async function getMinifiedLogs() {
  let res = await fetch(getBasePath() + "/admin/logs/min", {
    method: "GET",
  });
  let data = res.text();
  return data;
}
