export * from "./user";
export * from "./org";
export * from "./deployment";
export * from "./dropzone";
export * from "./asset";
export * from "./queue";
export * from "./facility";
export * from "./binary";
export * from "./result";
export * from "./admin";
export * from "./delta";

export function getBasePath() {
  return window.location.protocol + "//" + window.location.host;
}
