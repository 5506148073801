import React from "react";
import "../../index.css";
import "./login0_page.css";
import { login0 } from "../../api";
import { TextField } from "@mui/material";
import { checkExpiration } from "./helpers";

// components
import LoginHeadbar from "./login_headbar/login_headbar";
import Copyright from "./copyright/copyright";
import ReleaseNotes from "./release_notes_modal";

/**
 * Login page of the application. Allows users to specify username and
 * password login.
 * Release notes, flash alerts, and EULA can also be optionally displayed.
 * This page will navigate the user to the 2FA page if 2FA is required.
 */
export default class Login0Page extends React.Component {
  constructor(props) {
    super(props);

    document.title = "OT.AI Platform - Login";

    this.state = {
      loading: false,
      failed: false,
      releaseNotes: false,

      username: "",
      password: "",
    };

    this.firstLogin = this.firstLogin.bind(this);
    this.enterClick = this.enterClick.bind(this);
  }

  enterClick(e) {
    if (e.key === "Enter") {
      this.firstLogin();
    }
  }

  /**
   * Render the page.
   */
  render() {
    return (
      <div className="login-page">
        <LoginHeadbar />
        <h1>{this.state.releaseNotes ? "Release Notes" : "Sign in"}</h1>
        {!this.state.releaseNotes && (
          <div className="login-center">
            <TextField
              value={this.state.username}
              onKeyDown={this.enterClick}
              onChange={(e) => this.setState({ username: e.target.value })}
              label="Username"
              fullWidth
              className="username-input"
            />
            <TextField
              value={this.state.password}
              onKeyDown={this.enterClick}
              onChange={(e) => this.setState({ password: e.target.value })}
              label="Password"
              type="password"
              fullWidth
            />
            <p>
              Please login with your OT.AI Platform credentials.
              <br />
              <br />
              If you have MFA enabled, you will be prompted to setup you account
              information if you haven't done so already.
            </p>

            <div className="row">
              {this.state.loading && <div className="lds-dual-ring"></div>}
              {!this.state.loading && <p>{this.state.fail}</p>}

              <button onClick={this.firstLogin}>Sign in</button>
            </div>
          </div>
        )}
        {this.state.releaseNotes && (
          <div className="login-center">
            <ReleaseNotes />
            <div className="row">
              <button onClick={() => this.setState({ releaseNotes: false })}>
                Ok
              </button>
            </div>
          </div>
        )}
        {!this.state.releaseNotes && (
          <div className="login-bot-row">
            <a
              onClick={() => {
                var link = document.createElement("a");
                link.href = "/ObjectSecurity_OT.AI_Platform_EULA_7_26_2022.pdf";
                link.download =
                  "ObjectSecurity_OT.AI_Platform_EULA_7_26_2022.pdf";
                link.dispatchEvent(new MouseEvent("click"));
              }}
            >
              EULA
            </a>
            <a onClick={() => this.setState({ releaseNotes: true })}>
              Release Notes
            </a>
            <a
              onClick={() => {
                window.location.assign("#/reset_password");
              }}
            >
              Reset Password
            </a>
          </div>
        )}
        <Copyright />
      </div>
    );
  }

  /**
   * Login the user.
   * Clears the cache, then submits the specified username and password
   * to the backend.
   */
  async firstLogin() {
    // reset local storage
    window.localStorage.removeItem("analyzingUploads");
    window.localStorage.removeItem("generatedReports");
    window.localStorage.removeItem("collections");
    window.localStorage.removeItem("pastUploads");
    window.localStorage.removeItem("dropzoneId");
    window.localStorage.removeItem("firstLoad");
    window.localStorage.removeItem("firstToken");
    window.localStorage.removeItem("auth");
    window.localStorage.removeItem("username");
    window.localStorage.removeItem("email");
    window.localStorage.removeItem("userid");
    window.localStorage.removeItem("dropUploads");

    this.setState({ loading: true });

    try {
      let res = await login0(this.state.username, this.state.password);
      this.setState({ loading: false });
      window.localStorage.setItem("auth", res.access_token);

      // check if MFA/Google authenticator is required
      if (res.hasOwnProperty("details")) {
        if (!res.eula) {
          window.location.assign("#/eula");
          return;
        }
        window.location.assign("#/dashboard");
        await checkExpiration();
      } else {
        window.location.assign("#/login1");
      }
    } catch (e) {
      this.setState({ loading: false, fail: "Login failed." });
      return;
    }
  }
}
