const NVD_URL = "https://services.nvd.nist.gov/rest/json/cves/2.0";
const URL = `https://api.allorigins.win/get?url=`;

export async function getNVD() {
  try {
    let totalResults = await fetch(createURL("resultsPerPage=1"));
    totalResults = await totalResults.json();
    totalResults = JSON.parse(totalResults.contents).totalResults;
    let startIndex = totalResults - 5;

    let results = await fetch(
      createURL(`resultsPerPage=5&startIndex=${startIndex}`)
    );
    results = await results.json();
    results = JSON.parse(results.contents);
    let vulnerabilities = results.vulnerabilities.map((vuln) => {
      vuln = vuln.cve;
      return {
        title: vuln.id,
        date: vuln.published,
        link: `https://nvd.nist.gov/vuln/detail/${vuln.id}`,
        description: vuln.descriptions[0].value,
      };
    });
    return vulnerabilities.reverse();
  } catch {
    return [];
  }
}

function createURL(params) {
  const uri = encodeURIComponent(`${NVD_URL}?${params}`);
  return `${URL}${uri}`;
}
