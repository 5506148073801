import { useRef } from "react";
import PropTypes from "prop-types";
import "../report_builder_preview.css";
import { Tooltip } from "react-tippy";
import { useReactToPrint } from "react-to-print";
import { Binary } from "../../../../constants";

// components
import BinaryReport from "./binary_report";

// icons
import { X, FileText, Printer } from "react-feather";

/**
 * A preview of the custom report PDF.
 * This displays the set of all user-added report items.
 * It can be exported to PDF format via a button click.
 * Custom report items can be dragged and dropped on the report,
 * changing their order.
 */
export default function ReportBuilderPreview({
  items,
  asset_id,
  binary,
  metadata,
  manalyze,
  binaryEncryption,
  setItems,
  cves,
  cwes,
  threatAI,
}) {
  document.title = "OT.AI Platform - Reports";
  const urlParams = new URLSearchParams(window.location.href.split("?")[1]);
  let fromBinaryAnalysis = urlParams.get("fromBinaryAnalysis") !== null;
  const printRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    pageStyle: `
    @media print {
      body {
        overflow: visible !important;

        --bg-color: #ffffff;
        --sec-bg-color: #fbfbfb;
        --pri-color: #0f1419;
        --pri-color-light: #7a8791;
        --bor-color: #cfd9de;
      }
    }
  `,

    print: async (printIframe) => {
      // Do whatever you want here, including asynchronous work
      printIframe.contentWindow.print();
    },
  });

  if (!binary) return null;
  return (
    <div className="report-builder-preview">
      <div
        className="report-builder-preview-internal"
        id="fake-report-document-scroller"
      >
        <div className="fake-report-document" ref={printRef} id="the-report">
          <BinaryReport
            items={items}
            asset_id={asset_id}
            binary={binary}
            metadata={metadata}
            manalyze={manalyze}
            binaryEncryption={binaryEncryption}
            setItems={setItems}
            cves={cves}
            cwes={cwes}
            threatAI={threatAI}
          />
        </div>
      </div>

      <Tooltip
        title={
          fromBinaryAnalysis
            ? "Back to Binary Analysis Page"
            : "Back to Reports Page"
        }
        position="left"
        trigger="mouseenter"
        arrow
        size="small"
        className="report-builder-button report-builder-button-close"
      >
        <button
          onClick={() => {
            if (fromBinaryAnalysis) window.location.assign("#/binary");
            else window.location.assign("#/report");
          }}
        >
          <X className="icon" />
        </button>
      </Tooltip>

      {fromBinaryAnalysis && (
        <Tooltip
          title="Back to Reports Page"
          position="left"
          trigger="mouseenter"
          arrow
          size="small"
          className="report-builder-button report-builder-button-docs"
        >
          <button
            onClick={() => {
              window.location.assign("#/report");
            }}
          >
            <FileText className="icon" />
          </button>
        </Tooltip>
      )}

      <Tooltip
        title="Print PDF"
        position="left"
        trigger="mouseenter"
        arrow
        size="small"
        className="report-builder-button report-builder-button-download"
        style={{
          top: fromBinaryAnalysis ? 100 : 60,
        }}
      >
        <button className="pdf-download-button" onClick={handlePrint}>
          <Printer className="icon" />
        </button>
      </Tooltip>
    </div>
  );
}

ReportBuilderPreview.propTypes = {
  /**
   * The list of items current on the custom report.
   */
  items: PropTypes.arrayOf(PropTypes.object).isRequired,

  /**
   * The ID of the parent asset.
   */
  asset_id: PropTypes.string.isRequired,

  /**
   * The parent binary.
   */
  binary: PropTypes.instanceOf(Binary).isRequired,

  /**
   * The parent binary's metadata.
   * One of the untoggleable report items.
   */
  metadata: PropTypes.object.isRequired,

  /**
   * The parent binary's manalyze results.
   * One of the untoggleable report items.
   */
  manalyze: PropTypes.object.isRequired,

  /**
   * The parent binary's encryption results.
   * One of the untoggleable report items.
   */
  binaryEncryption: PropTypes.object.isRequired,

  /**
   * Callback function that sets the items on the report.
   * Is called when the user is drag-and-dropping said
   * items.
   */
  setItems: PropTypes.func.isRequired,

  /**
   * The set of all CVEs in the parent binary.
   * Used to construct the set of toggleable items.
   */
  cves: PropTypes.array.isRequired,

  /**
   * The set of all CWEs in the parent binary.
   * sed to contstruct the set of toggleable items.
   */
  cwes: PropTypes.array.isRequired,

  /**
   * The set of all ThreatAI tests in the parent binary.
   * sed to construct the set of toggleable items.
   */
  threatAI: PropTypes.array.isRequired,
};
