import React from "react";
import PropTypes from "prop-types";
import "../../index.css";
import "./release_notes.css";
import { VERSION } from "../../helpers";

const NEW = [
  "Added the Binary Delta Feature, accessible from a new tab in the user interface.",
  "The Binary Delta Feature allows you to compare binary assessment results for different versions of the same binary executable or firmware image (e.g., between updates, patches, upgrades, mergers, debloats, remediation, etc.).",
  "You may select two asset for which to generate a Binary Delta Report. The Binary Delta Report is viewable in the user interface and may be exported to PDF.",
];
const UPDATED = [
  "Improved the descriptions of various CWEs. When detected, these CWEs are reported with more context than before.",
  "Added the ability to recover a user's password via email from the sign in screen.",
  "Fixed bugs and performance issues relating to user authentication.",
];
const REMOVED = ["None."];

/**
 * Release notes modal that appears on the login page.
 * Displays what has been added, updated, and removed from this
 * release version.
 */
export default class ReleaseNotesModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: "NEW", // | UPDATED | REMOVED
    };
  }

  render() {
    return (
      <div className="release-notes-div">
        <h2 className="version-number">{VERSION}</h2>
        <div className="row">
          <h4
            onClick={() => this.setState({ page: "NEW" })}
            className={this.state.page === "NEW" ? "selected-rel" : "rele"}
          >
            New
          </h4>
          <h4
            onClick={() => this.setState({ page: "UPDATED" })}
            className={this.state.page === "UPDATED" ? "selected-rel" : "rele"}
          >
            Updated
          </h4>
          <h4
            onClick={() => this.setState({ page: "REMOVED" })}
            className={this.state.page === "REMOVED" ? "selected-rel" : "rele"}
          >
            Removed
          </h4>
        </div>
        {this.state.page === "NEW" && (
          <ul className="">
            {NEW.map((n) => (
              <li>{n}</li>
            ))}
          </ul>
        )}
        {this.state.page === "UPDATED" && (
          <ul>
            {UPDATED.map((u) => (
              <li>{u}</li>
            ))}
          </ul>
        )}
        {this.state.page === "REMOVED" && (
          <ul>
            {REMOVED.map((r) => (
              <li>{r}</li>
            ))}
          </ul>
        )}
      </div>
    );
  }
}

ReleaseNotesModal.propTypes = {
  /**
   * Callback that occurs when the user attempts to close the release
   * notes modal.
   */
  pressFunction: PropTypes.func.isRequired,
};
