import { useEffect, useState } from "react";
import { fetchBinaryItems, BINARY_REPORT_RESULT_TYPES } from "./helpers";

// components
import RightHandSidebar from "../../../sidebar/right_hand_sidebar";
import Notifications from "../../../login_page/notifications";
import BigDrop from "../../../sidebar/big_drop";
import Headbar from "../../../headbar/headbar";
import Botbar from "../../../botbar/botbar";
import Sidebar from "../../../sidebar/sidebar";
import ReportBuilderSidebar from "./report_builder_sidebar";
import ReportBuilderPreview from "./report_builder_preview";

/**
 * Customizable report PDF component that lets users alter what
 * content is display / exported in a PDF report.
 */
export default function ReportBuilder_Binary() {
  // parse GET parameters
  const urlParams = new URLSearchParams(window.location.href.split("?")[1]);
  let asset_id = urlParams.get("asset_id");
  let binary_name = urlParams.get("binary");

  // a loading spinner should display while we are awaiting all assessment results
  let [sidebarLoading, setSidebarLoading] = useState(true);

  let [items, setItems] = useState([]);
  let [binary, setBinary] = useState(null);
  let [metadata, setMetadata] = useState(null);
  let [manalyze, setManalyze] = useState(null);
  let [binaryEncryption, setBinaryEncryption] = useState(null);
  let [cwes, setCWEs] = useState([]);
  let [cves, setCVEs] = useState([]);
  let [threatAI, setThreatAI] = useState([]);
  let [dangerousFns, setDangerousFns] = useState({});
  let [crypto, setCrypto] = useState(null);
  let [runtimeEncryption, setRuntimeEncryption] = useState(null);
  let [weakPointers, setWeakPointers] = useState([]);

  /**
   * Callback function that set the state of this component once
   * result data has been returned from the backend.
   */
  const fetchDataCallback = (type, data) => {
    switch (type) {
      case BINARY_REPORT_RESULT_TYPES.BINARY:
        setBinary(data);
        return;
      case BINARY_REPORT_RESULT_TYPES.METADATA:
        setMetadata(data);
        return;
      case BINARY_REPORT_RESULT_TYPES.MANALYZE:
        setManalyze(data);
        return;
      case BINARY_REPORT_RESULT_TYPES.BINARY_ENCRYPTION:
        setBinaryEncryption(data);
        return;
      case BINARY_REPORT_RESULT_TYPES.CWES:
        setCWEs(data);
        return;
      case BINARY_REPORT_RESULT_TYPES.CVES:
        setCVEs(data);
        return;
      case BINARY_REPORT_RESULT_TYPES.THREATAI:
        setThreatAI(data);
        return;
      case BINARY_REPORT_RESULT_TYPES.DANGEROUS_FUNCTIONS:
        setDangerousFns(data);
        return;
      case BINARY_REPORT_RESULT_TYPES.CRYPTO:
        setCrypto(data);
        return;
      case BINARY_REPORT_RESULT_TYPES.RUNTIME_ENCRYPTION:
        setRuntimeEncryption(data);
        return;
      case BINARY_REPORT_RESULT_TYPES.WEAK_POINTERS:
        setWeakPointers(data);
        return;
      default:
        return;
    }
  };

  /**
   * Fetch the data necessary to build the report from the API.
   */
  async function init() {
    await fetchBinaryItems(asset_id, binary_name, fetchDataCallback);
    setSidebarLoading(false);
  }
  useEffect(init, []);

  return (
    <BigDrop>
      <main>
        {/* Header */}
        <Notifications />
        <Headbar />
        <Botbar />

        {/* Get Started */}
        <div className="main-row2">
          <Sidebar page="reports" />
          <ReportBuilderPreview
            items={items}
            asset_id={asset_id}
            binary={binary}
            metadata={metadata}
            manalyze={manalyze}
            binaryEncryption={binaryEncryption}
            setItems={setItems}
            cwes={cwes}
            cves={cves}
            threatAI={threatAI}
          />
          <ReportBuilderSidebar
            loading={sidebarLoading}
            items={items}
            setItems={setItems}
            cwes={cwes}
            cves={cves}
            threatAI={threatAI}
            dangerousFns={dangerousFns}
            crypto={crypto}
            binaryEncryption={binaryEncryption}
            runtimeEncryption={runtimeEncryption}
            weakPointers={weakPointers}
          />
          <RightHandSidebar />
        </div>
      </main>
    </BigDrop>
  );
}
