import React from "react";
import "../../index.css";
import { sendTOTP } from "../../api";
import { checkExpiration } from "./helpers";
import { TextField } from "@mui/material";

// components
import Copyright from "./copyright/copyright";
import LoginHeadbar from "./login_headbar/login_headbar";

/**
 * Second factor portion of login page.
 * Lets users enter their Google Authenticator code.
 */
export default class Login1Page extends React.Component {
  constructor(props) {
    super(props);

    document.title = "OT.AI Platform - Login";

    this.state = {
      pincode: "",
      loading: false,
      failed: false,
    };

    this.enterClick = this.enterClick.bind(this);
    this.secondLogin = this.secondLogin.bind(this);
  }

  enterClick(e) {
    if (e.key === "Enter") {
      this.secondLogin();
    }
  }

  /**
   * Submit the pincode to the backend for 2nd factor authentication.
   */
  async secondLogin() {
    this.setState({ loading: true });
    try {
      let res = await sendTOTP(
        this.state.pincode,
        window.localStorage.getItem("firstToken")
      );
      this.setState({ loading: false });
      if (!res.eula) {
        window.location.assign("#/eula");
        return;
      }
      window.location.assign("#/dashboard");
      await checkExpiration();
    } catch (e) {
      this.setState({ loading: false, fail: "Invalid pincode." });
    }
  }

  /**
   * Render the page.
   */
  render() {
    return (
      <div className="login-page">
        <LoginHeadbar logout />
        <h1>Pincode Entry</h1>

        <div className="login-center">
          <TextField
            value={this.state.pincode}
            onKeyDown={this.enterClick}
            onChange={(e) => this.setState({ pincode: e.target.value })}
            label="6-Digit Pincode"
            fullWidth
          />

          <p>
            Please enter your Google Authenticator pincode.
            <br />
            <br />
            This pincode can be found by open the Google Authenticator
            application on your mobile device.
          </p>

          <div className="row">
            {this.state.loading && <div className="lds-dual-ring"></div>}
            {!this.state.loading && <p>{this.state.fail}</p>}

            <button onClick={this.secondLogin}>Sign in</button>
          </div>
        </div>

        <Copyright />
      </div>
    );
  }
}
