/*
    This file contains code defining the super-class of all tabs which 
    appear on the Binary Summary Panel.
    
    Depending on the 'tab' prop, different tabs will be rendered.
    This component also handles logic for when the binary analysis 
    is un-finished: warning the user that the tab content cannot be
    painted yet.

    This component uses composition as a substitute for polymorphism.
*/

import { useState, useEffect } from "react";
import BaseTab from "./base_tab";
import {
  getVulscore,
  getMetadata,
  getEntropy,
  getManalyze,
  getBinaryEncryption,
  getCVEs,
  getCWEs,
  getCWEInfo,
  getBAP,
  getLineage,
  getDangerousFunctions,
  getCrypto,
  getRuntimeEncryption,
  getWeakPointers,
  getClamAV,
  getUnifiedCWEs,
} from "../../../api";

// components
import CVEsTab from "./cves_tab";
import CWEsTab from "./cwes_tab";
import ThreatAITab from "./threatai_tab";
import DangerousFunctionsTab from "./dangerous_functions_tab";
import CryptoTab from "./crypto_tab";
import WeakPointers from "./weak_pointers/weak_pointers";
import OWASP_2021 from "../mappings/owasp_2021";
import ISA_3_3 from "../mappings/isa_3_3";
import ISA_4_2 from "../mappings/isa_4_2";
import NIST from "../mappings/nist";

export default function BinarySummaryTab({
  setPage,
  binary,
  tab,
  swapTab,
  preSearch,
}) {
  // is the tab loading or not
  let [loading, setLoading] = useState(true);

  // if the tab is paintable or not
  let [paintable, setPaintable] = useState(false);

  // data used to render the tab
  let [tabData, setTabData] = useState({});

  // there is a function for each tab that fetches assessment results, and then
  // determines if the panel is paintable (returns a boolean value)
  const paintBaseTab = async () => {
    let tabData = {};
    let vulscore = await getVulscore(binary.id, binary.name);

    if (vulscore) {
      tabData.cvss = vulscore.base;
      tabData.exploitability = vulscore.exploitability;
      tabData.impact = vulscore.impact;
    }

    let metadata = await getMetadata(binary.id, binary.name);

    let entropy = await getEntropy(binary.id, binary.name);

    if (entropy) metadata.entropy = entropy;
    tabData.metadata = metadata;

    let manalyze = await getManalyze(binary.id, binary.name);

    if (manalyze && Object.keys(manalyze).length === 0) manalyze = null;
    tabData.manalyze = manalyze;

    let binaryEncryption = await getBinaryEncryption(binary.id, binary.name);
    tabData.binaryEncryption = binaryEncryption;

    let clamav = await getClamAV(binary.id, binary.name);
    tabData.clamav = clamav;

    setTabData((old) => {
      return { ...old, ...tabData };
    });
    return true;
  };
  const paintCVEsTab = async () => {
    let tabData = {};
    let cves = await getCVEs(binary.id, binary.name);
    tabData.cves = cves;

    if (!binary.aggregate && !tabData.cves) return false;

    setTabData((old) => {
      return { ...old, ...tabData };
    });
    return true;
  };
  const paintCWEsTab = async () => {
    let tabData = {};
    let unified_cwes = await getUnifiedCWEs(binary.id, binary.name);
    if (unified_cwes) {
      tabData.unified_cwes = unified_cwes;
      setTabData((old) => {
        return { ...old, ...tabData };
      });
      return true;
    }
    return await paintCWEsDependentTab();
  };
  const paintCWEsDependentTab = async () => {
    let tabData = {};
    let cwes = await getCWEs(binary.id, binary.name);

    // get the detailed info of each CWE
    let cweIds = [];
    for (let c of cwes) {
      let id = c.cwe.split("-")[1];
      cweIds.push(id);
    }
    let cweInfo = await getCWEInfo(cweIds);
    for (let c of cwes) {
      let id = c.cwe.split("-")[1];
      c.info = cweInfo[id] ? cweInfo[id] : "";
    }
    tabData.cwes = cwes;

    if (!binary.aggregate && (!tabData.cwes || tabData.cwes.length === 0))
      return false;

    let lineage = await getLineage(binary.id, binary.name);
    tabData.lineage = lineage;
    setTabData((old) => {
      return { ...old, ...tabData };
    });
    return true;
  };
  const paintThreatAITab = async () => {
    let tabData = {};
    let bap = await getBAP(binary.id, binary.name);
    tabData.bap = bap;
    let lineage = await getLineage(binary.id, binary.name);
    tabData.lineage = lineage;
    setTabData((old) => {
      return { ...old, ...tabData };
    });

    if (!binary.aggregate && (!tabData.bap || tabData.bap.length === 0))
      return false;

    return true;
  };
  const paintDangerousFunctionsTab = async () => {
    let tabData = {};

    let dangerous_functions_pre = await getDangerousFunctions(
      binary.id,
      binary.name
    );
    let dangerous_functions = {};
    for (let df in dangerous_functions_pre) {
      if (dangerous_functions_pre[df].length > 0)
        dangerous_functions[df] = dangerous_functions_pre[df];
    }
    tabData.dangerous_functions = dangerous_functions;

    if (
      !binary.aggregate &&
      (!tabData.dangerous_functions ||
        Object.keys(tabData.dangerous_functions).length === 0)
    )
      return false;

    setTabData((old) => {
      return { ...old, ...tabData };
    });
    return true;
  };
  const paintCryptoTab = async () => {
    let tabData = {};

    let crypto = await getCrypto(binary.id, binary.name);
    tabData.crypto = crypto;

    let binaryEncryption = await getBinaryEncryption(binary.id, binary.name);
    tabData.binaryEncryption = binaryEncryption;

    let runtimeEncryption = await getRuntimeEncryption(binary.id, binary.name);
    tabData.runtimeEncryption = runtimeEncryption;

    if (
      !binary.aggregate &&
      !tabData.crypto &&
      !tabData.binaryEncryption &&
      !tabData.runtimeEncryption
    )
      return false;

    setTabData((old) => {
      return { ...old, ...tabData };
    });
    return true;
  };
  const paintWeakPointersTab = async () => {
    let tabData = {};

    tabData.weakPointers = await getWeakPointers(binary.id, binary.name);

    if (
      !binary.aggregate &&
      (!tabData.weakPointers || Object.keys(tabData.weakPointers).length === 0)
    )
      return false;
    setTabData((old) => {
      return { ...old, ...tabData };
    });
    return true;
  };

  /**
   * Render the correct tab depending on the tab prop.
   */
  const renderTab = () => {
    switch (tab) {
      case "Base":
        return <BaseTab binary={binary} data={tabData} setPage={setPage} />;
      case "CVEs":
        return <CVEsTab binary={binary} data={tabData} />;
      case "CWEs":
        return (
          <CWEsTab
            binary={binary}
            data={tabData}
            preSearch={preSearch}
            swapTab={swapTab}
          />
        );
      case "ThreatAI":
        return <ThreatAITab binary={binary} data={tabData} swapTab={swapTab} />;
      case "Dangerous Functions":
        return (
          <DangerousFunctionsTab
            binary={binary}
            data={tabData}
            swapTab={(cwe_id) => {
              swapTab("CWEs", cwe_id);
            }}
          />
        );
      case "Crypto":
        return <CryptoTab binary={binary} data={tabData} />;
      case "Weak Pointers":
        return (
          <WeakPointers binary={binary} data={tabData} swapTab={swapTab} />
        );
      case "OWASP 10:2021":
        return (
          <OWASP_2021
            asset_id={binary.id}
            binary={binary.name}
            cwes={tabData.cwes ? tabData.cwes : []}
          />
        );
      case "ISA/IEC 62443-3-3":
        return (
          <ISA_3_3
            asset_id={binary.id}
            binary={binary.name}
            cwes={tabData.cwes ? tabData.cwes : []}
            lineage={tabData.lineage}
          />
        );
      case "ISA/IEC 62443-4-2":
        return (
          <ISA_4_2
            asset_id={binary.id}
            binary={binary.name}
            cwes={tabData.cwes ? tabData.cwes : []}
            lineage={tabData.lineage}
          />
        );
      case "NIST 800-53":
        return <NIST cwes={tabData.cwes ? tabData.cwes : []} />;
      case "NIST 800-82":
        return <NIST cwes={tabData.cwes ? tabData.cwes : []} is800_82={true} />;
      default:
        return null;
    }
  };

  /**
   * Depending on the tab, grab required assessment results and attempt
   * to paint the tab content. If the content cannot be painted, and the
   * assessment is still in-progress, then warn the user.
   */
  useEffect(() => {
    let active = true;

    setLoading(true);
    setPaintable(false);
    setTabData({});

    const fetchData = async () => {
      let new_paintable = true;
      switch (tab) {
        case "Base":
          new_paintable = await paintBaseTab();
          break;
        case "CVEs":
          new_paintable = await paintCVEsTab();
          break;
        case "CWEs":
          new_paintable = await paintCWEsTab();
          break;
        case "OWASP 10:2021":
        case "ISA/IEC 62443-3-3":
        case "ISA/IEC 62443-4-2":
        case "NIST 800-53":
        case "NIST 800-82":
          new_paintable = await paintCWEsDependentTab();
          break;
        case "ThreatAI":
          new_paintable = await paintThreatAITab();
          break;
        case "Dangerous Functions":
          new_paintable = await paintDangerousFunctionsTab();
          break;
        case "Crypto":
          new_paintable = await paintCryptoTab();
          break;
        case "Weak Pointers":
          new_paintable = await paintWeakPointersTab();
          break;
        default:
          break;
      }
      if (active) {
        setPaintable(new_paintable);
        setLoading(false);
      }
    };
    fetchData();

    return () => {
      active = false;
    };
  }, [tab, binary.name, binary.id]);

  // display loading screen if the API requests haven't returned yet
  if (loading)
    return (
      <div
        className="filter-internal"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <div className="lds-dual-ring lds-dual-ring-center"></div>
      </div>
    );

  if (!paintable)
    return (
      <div className="unpaintable">
        <div className="lds-dual-ring lds-dual-ring-center"></div>
        <h3>
          This binary is still under assessment. This tab will produce results
          later on. Other tabs may already have partial results.
        </h3>
      </div>
    );

  return <div className="filter-internal">{renderTab()}</div>;
}
