import PropTypes from "prop-types";
import "./login_headbar.css";
import "react-tippy/dist/tippy.css";
import { Tooltip } from "react-tippy";
import { logout as logout_fn } from "../../../api";

// icons
import { LogOut } from "react-feather";

/**
 * Headbar that appears at the top of all Login related
 * page components.
 * Optionally enables the user to logout and return to the
 * Login0 Page.
 */
export default function LoginHeadbar({ logout }) {
  return (
    <>
      <div className="headbar">
        <img
          src="/otai_logo_500x500.png"
          alt="OTAI Logo"
          className="project-logo"
        />

        {logout && (
          <Tooltip
            title="Log Out"
            position="left"
            trigger="mouseenter"
          >
            <div className="icon-wrap" onClick={logout_fn}>
              <LogOut className="icon" />
            </div>
          </Tooltip>
        )}
      </div>
      <hr className="headbar-hr" />
    </>
  );
}

LoginHeadbar.propTypes = {
  /**
   * Logout button enabled/disabled.
   */
  logout: PropTypes.bool,
};
