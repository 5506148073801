import React from "react";
import PropTypes from "prop-types";
import "./file_drop.css";
import { Modal } from "react-bootstrap";
import {
  getUserProfile,
  renameAsset,
  updateAssetType,
  listBinaries,
  getDefaultFacility,
  getAllFacilities,
  updateAssetVendorProductVersion,
} from "../../../api";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
  ZoomableGroup,
} from "react-simple-maps";

// icons
import { File, GitPullRequest, ChevronDown, ChevronUp } from "react-feather";

// components
import FacilityDropdown from "../../binary_analysis_page/facility_dropdown";
import AssetTypeSelector from "../asset_type_selector";
import FileDropRight from "./file_drop_right";
import VendorProductVersionInputs from "./vendor_product_version_inputs";

const DEFAULT_FORM = {
  type: "",
  assetName: "",
  product: "",
  vendor: "",
  version: "",
};

/**
 * Modal that appears when binaries are being uploaded for analysis.
 * The user is prompted to create a parent asset for their uploaded binaries.
 * In addition to the child binaries, the following information is required:
 * 1. Name.
 * 2. Asset Type (HMI, PLC, Generic Asset).
 * 3. Parent Facility.
 * Asset ID is randomly generated by the Back-End.
 */
export default class FileDropModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // the files which currently exist in the dropzone
      dropUploads: [],

      // asset form filled out by the user on the left side of this component
      form: DEFAULT_FORM,

      // parent facility selection
      selectedFacility: null,
      facilities: [],
      facilityDropdown: false,

      // if the analysis is starting, the user should
      // not be able to interact with the modal
      startingAnalysis: false,
    };

    this.refresh = this.refresh.bind(this);
    this.popupOff = this.popupOff.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.setForm = this.setForm.bind(this);
  }

  /**
   * Update the component if the user toggles it on/off.
   */
  componentDidUpdate(prevProps, prevState) {
    if (this.props.modal !== prevProps.modal) {
      // dropping files from another page
      if (
        this.props.acceptedFiles &&
        this.props.acceptedFiles !== prevProps.acceptedFiles
      ) {
        this.resetForm();
        this.init_facilities();
      }
      // from "New Analysis" click
      else {
        this.resetForm();
        this.refresh();
        this.init_facilities();
      }
    }
  }

  resetForm() {
    this.setState({
      form: DEFAULT_FORM,
      startingAnalysis: false,
    });
  }

  setForm(values) {
    if (values.vendor === "") {
      values.product = "";
    }
    if (values.product === "") {
      values.version = "";
    }
    this.setState({
      form: {
        ...this.state.form,
        ...values,
      },
    });
  }

  popupOff() {
    this.setState({ facilityDropdown: false });
  }

  componentDidMount() {
    window.addEventListener("mousedown", this.popupOff, false);
    this.init_facilities();
  }

  componentWillUnmount() {
    window.removeEventListener("mousedown", this.popupOff, false);
  }

  async init_facilities() {
    this.setState({
      selectedFacility: null,
      facilities: [],
    });

    // get facility information
    let facilities = await getAllFacilities();
    let defaultFacility = await getDefaultFacility();
    this.setState({
      selectedFacility: facilities.filter((f) => {
        return f.id === defaultFacility;
      })[0],
      facilities,
    });
  }

  async refresh() {
    let dropUploads = await listBinaries();
    this.setState({
      dropUploads,
    });
  }

  /**
   * Render the component.
   */
  render() {
    return (
      <Modal
        show={this.props.modal}
        animation={false}
        className="modal-bg"
        enforceFocus={false}
      >
        <Modal.Body className="modal2 file-drop-modal">
          {this.state.startingAnalysis && (
            <div className="starting-bin-analysis">
              <div className="lds-dual-ring"></div>
              <h3>Starting Binary Analysis</h3>
            </div>
          )}

          <div className="new-analysis-base-info" style={{ height: 595 }}>
            <div className="new-analysis-base-row">
              <GitPullRequest className="new-analysis-icon" />
              <h2>New Asset Analysis</h2>
            </div>

            {!this.state.selectedFacility && (
              <div>
                {/*
                  <hr className="new-analysis-line"></hr>
                   */}
                <h3 className="new-analysis-question">
                  Where is the asset located?
                </h3>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <div className="lds-dual-ring"></div>
                </div>
              </div>
            )}

            {this.state.selectedFacility && (
              <div>
                <h3 className="new-analysis-question">
                  Where is the asset located?
                </h3>
                <div
                  id="facility-selector"
                  onClick={() =>
                    this.setState({
                      panel: "FACILITY",
                      facilityDropdown: true,
                    })
                  }
                  className="facility-name"
                  style={{
                    padding: 0,
                    paddingRight: 5,
                    marginTop: 10,
                    width: "fit-content",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    position: "relative",
                    border: this.state.facilityDropdown
                      ? "1px solid var(--bor-color)"
                      : "1px solid transparent",

                    background: this.state.facilityDropdown
                      ? "var(--sec-bg-color)"
                      : "",
                    borderBottomLeftRadius: this.state.facilityDropdown ? 0 : 5,
                    borderBottomRightRadius: this.state.facilityDropdown
                      ? 0
                      : 5,
                    cursor: this.state.facilityDropdown ? "auto" : "pointer",
                  }}
                >
                  {this.state.facilityDropdown && (
                    <div
                      style={{
                        width: "100%",
                        background: "var(--sec-bg-color)",
                        height: 2,
                        position: "absolute",
                        bottom: -2,
                        left: 0,
                        zIndex: 31,
                      }}
                    ></div>
                  )}
                  <div
                    className="facility-row-map"
                    style={{
                      borderColor: "var(--sec-color)",
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        top: 1,
                        left: 1,
                        width: "calc(100% - 2px)",
                        height: "calc(100% - 2px)",
                        overflow: "hidden",
                        borderTopRightRadius: 5,
                        borderBottomRightRadius: 5,
                      }}
                    >
                      <ComposableMap projection="geoMercator">
                        <ZoomableGroup
                          zoom={5}
                          center={this.state.selectedFacility.latLng}
                        >
                          <Geographies geography={"./map_data.json"}>
                            {({ geographies, projection }) =>
                              geographies.map((geo) => {
                                return (
                                  <Geography
                                    style={{
                                      default: { outline: "none" },
                                      hover: { outline: "none" },
                                      pressed: { outline: "none" },
                                    }}
                                    key={geo.rsmKey}
                                    geography={geo}
                                    fill="var(--bg-color)"
                                    stroke="var(--bor-color)"
                                  />
                                );
                              })
                            }
                          </Geographies>
                          <Marker
                            coordinates={this.state.selectedFacility.latLng}
                          >
                            <circle
                              style={{ cursor: "pointer" }}
                              r={10}
                              fill={
                                this.state.selectedFacility
                                  .vulnerability_score > 0.5
                                  ? "var(--contrast-color)"
                                  : this.state.selectedFacility
                                      .vulnerability_score > 0.25
                                  ? "var(--yellow-color)"
                                  : "var(--green-color)"
                              }
                              filter={
                                this.state.selectedFacility
                                  .vulnerability_score > 0.5
                                  ? "drop-shadow( 0px 0px 5px var(--contrast-color))"
                                  : this.state.selectedFacility
                                      .vulnerability_score > 0.25
                                  ? "drop-shadow( 0px 0px 5px var(--yellow-color))"
                                  : "drop-shadow( 0px 0px 5px var(--green-color))"
                              }
                            />
                          </Marker>
                        </ZoomableGroup>
                      </ComposableMap>
                    </div>
                  </div>
                  <h3
                    style={{
                      fontSize: 15,
                      color: "var(--sec-color)",
                      fontWeight: 500,
                    }}
                  >
                    {this.state.selectedFacility.name}
                  </h3>
                  {!this.state.facilityDropdown && (
                    <ChevronDown
                      className="user"
                      style={{
                        height: 16,
                        width: 16,
                        marginLeft: 5,
                        marginRight: 0,
                      }}
                    />
                  )}
                  {this.state.facilityDropdown && (
                    <ChevronUp
                      className="user"
                      style={{
                        height: 16,
                        width: 16,
                        marginLeft: 5,
                        marginRight: 0,
                      }}
                    />
                  )}

                  {this.state.facilityDropdown && (
                    <FacilityDropdown
                      fromfileDrop
                      facilities={this.state.facilities}
                      selectFacility={(selectedFacility) =>
                        this.setState({
                          selectedFacility,
                          facilityDropdown: false,
                        })
                      }
                    />
                  )}
                </div>
              </div>
            )}

            <AssetTypeSelector
              selectedType={this.state.form.type}
              selectType={(type) => this.setForm({ type })}
            />

            {this.state.form.type !== "" && (
              <div>
                <hr className="new-analysis-line"></hr>
                <h3 className="new-analysis-question">
                  What would you like to name the asset?
                </h3>
                <input
                  className="new-analysis-input"
                  value={this.state.form.assetName}
                  onChange={(e) => this.setForm({ assetName: e.target.value })}
                  type="text"
                  placeholder="Asset Name..."
                />
              </div>
            )}

            <VendorProductVersionInputs
              form={this.state.form}
              setForm={this.setForm}
            />

            <button
              className={
                this.state.dropUploads.length !== 0 &&
                this.state.form.type !== "" &&
                this.state.form.assetName !== ""
                  ? "new-analysis-next-button"
                  : "new-analysis-next-button-off"
              }
              onClick={async () => {
                if (
                  !(
                    this.state.dropUploads.length !== 0 &&
                    this.state.form.type !== "" &&
                    this.state.form.assetName !== ""
                  )
                )
                  return;

                this.setState({ startingAnalysis: true });

                // update asset name and type, if we are creating a new asset
                let new_name = this.state.form.assetName;
                let new_type = this.state.form.type;
                let profile = await getUserProfile(
                  window.localStorage.getItem("userid")
                );
                await renameAsset(profile.details.dropzone_asset, new_name);
                await updateAssetType(profile.details.dropzone_asset, new_type);
                await updateAssetVendorProductVersion(
                  profile.details.dropzone_asset,
                  this.state.form.vendor,
                  this.state.form.product,
                  this.state.form.version
                );
                this.props.nextFunction(this.state.selectedFacility.id);
              }}
            >
              {this.state.dropUploads.length !== 0 &&
              this.state.form.type !== "" &&
              this.state.form.assetName !== ""
                ? "Start Analysis"
                : "Please fill out form and upload binaries."}
            </button>
          </div>

          <FileDropRight
            form={this.state.form}
            backFunction={this.props.backFunction}
            refresh={this.refresh}
            acceptedFiles={this.props.acceptedFiles}
            dropUploads={this.state.dropUploads}
          />
        </Modal.Body>
      </Modal>
    );
  }
}

FileDropModal.propTypes = {
  /**
   * Is the modal on or off.
   */
  modal: PropTypes.bool.isRequired,

  /**
   * Callback to start the analysis on the uploaded files.
   */
  nextFunction: PropTypes.func.isRequired,

  /**
   * Callback to close the modal.
   */
  backFunction: PropTypes.func.isRequired,

  /**
   * List of incoming files.
   * Used when uploading statically from the <BigDrop /> component.
   */
  acceptedFiles: PropTypes.arrayOf(PropTypes.instanceOf(File)),
};
