/*
    This component defines a single row (binary) in the Dashboard
    binary table.

    This component may be clicked upon to view the binary in detail
    on the Binary Analysis Page.
*/

import { useState, useEffect } from "react";
import { getCVEs, getCWEs } from "../../../api";
import { calcLight } from "../../../helpers";

export default function BinaryTableRow({ binary }) {
  // data to display in the table that must be fetched from the API
  let [nCVEs, setnCVEs] = useState(null);
  let [nCWEs, setnCWEs] = useState(null);

  /**
   * Load binary data from API.
   * (CVEs and CWEs).
   */
  const init = async () => {
    let cves = await getCVEs(binary.asset_id, binary.name);
    setnCVEs(cves ? cves.length : 0);

    let cwes = await getCWEs(binary.asset_id, binary.name);
    setnCWEs(cwes.length);
  };
  useEffect(() => init(), []);

  return (
    <div
      className="binary-table-row"
      onClick={() => {
        window.location.assign(
          `#/binary?asset=${binary.asset_id}&facility_id=${binary.facility_id}&asset_name=${binary.asset_name}`
        );
      }}
    >
      <div className="binary-table-cell">
        <div className={calcLight(binary.cwss.aggregate)}></div>
      </div>
      <div className="binary-table-cell">
        <h2>{binary.name}</h2>
      </div>
      <div className="binary-table-cell">
        {nCVEs !== null && <h3>{nCVEs}</h3>}
      </div>
      <div className="binary-table-cell">
        {nCWEs !== null && <h3>{nCWEs}</h3>}
      </div>
      <div className="binary-table-cell">
        <h3>{binary.date}</h3>
      </div>
    </div>
  );
}
