import "../binary_summary_components.css";
import BinarySummaryInfoHelper from "../binary_summary_info_helper";

// components
import WeakPointer from "./weak_pointer";

// icons
import { CheckCircle } from "react-feather";

/**
 * The weak pointers assessment tab.
 * Displays weak pointers found in a binary and the
 * paths to get there.
 */
export default function WeakPointers({ binary, data, swapTab }) {
  return (
    <div className="weak-pointers-tab">
      {/* No weak pointers detected. */}
      {(!data.weakPointers || Object.keys(data.weakPointers).length === 0) && (
        <div style={{ marginTop: -10 }}>
          <h4 style={{ fontWeight: 500 }}>
            No weak pointers were detected in this binary.
          </h4>
          <CheckCircle className="big-icon" />
        </div>
      )}

      {/* Display weak pointers. */}
      {data.weakPointers && Object.keys(data.weakPointers).length !== 0 && (
        <>
          <BinarySummaryInfoHelper
            text="Weak Pointers is a novel binary code analysis science that
                  automates identification of known code vulnerabilities and
                  weaknesses, like buffer overflow. To speed remediation of code
                  weaknesses, the ObjectSecurity OT.AI Platform provides the path of
                  the weakness (also known as a Weak Pointer) guiding DevSecOps and
                  Application Security specialists to zone in directly to the
                  problem lines of code that need to be remediated."
            link="https://support.objectsecurity.com/support/solutions/articles/72000606477-how-to-secure-a-weak-pointer"
          />

          {Object.keys(data.weakPointers).map((wp) => (
            <WeakPointer
              asset_id={binary.id}
              binary={binary.name}
              weakPointer={wp}
              paths={data.weakPointers[wp].paths}
              confidence={data.weakPointers[wp].confidence}
              swapTab={swapTab}
            />
          ))}
        </>
      )}
    </div>
  );
}
