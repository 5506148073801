import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./mappings.css";
import { getDeepDiveLines } from "../../../api";
import SyntaxHighlighter from "react-syntax-highlighter";
import { dark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { ChevronLeft, ChevronRight } from "react-feather";
import { Binary } from "../../../constants";
import { isHex } from "../../../helpers";

// The number of lines to display at one time.
const NUM_LINES = 10;

/**
 * Displays a set of locations where a vulnerability was
 * discovered.
 * The user can toggle between these locations using arrow
 * buttons.
 * This component is rendered from the Binary Summary Panel,
 * and from the Customizable Report.
 */
export default function DiscoveryLocation({
  asset_id,
  binary,
  locations,
  fromReportPDF,
  setPageCallback,
  done,
}) {
  let [page, setPage] = useState(0);
  let [lines, setLines] = useState([]);

  /**
   * The DiscoveryLocation can be either in the decompiled output,
   * or the disassembled output.
   */
  const getVersion = () => {
    let loc = locations[page].loc;
    return isHex(loc) ? "disassemble" : "decompile";
  };

  /**
   * Gets the line to center the discovery location preview on.
   * This function can either return a Hex string (in the case of the
   * disassembly view), or an integer (in the case of the decompiled
   * view).
   */
  const getCenterLine = () => {
    let loc = locations[page].loc;
    if (isHex(loc)) {
      let split_loc = loc.split("x");
      return `0x${split_loc[1].padStart(8, "0")}`.toLowerCase();
    }
    return parseInt(locations[page].loc) - 6;
  };

  /**
   * Based on the current page value, gets a set of
   * deep dive lines from the backend.
   */
  const refresh = async () => {
    let lines = await getDeepDiveLines(
      asset_id,
      binary,
      getVersion(),
      getCenterLine(),
      NUM_LINES
    );
    lines = cleanLines(lines);
    setLines(lines);
    if (setPageCallback) setPageCallback(page);
    if (done) done();
  };
  useEffect(refresh, [page]);

  /**
   * Revert to page 0 if the set of prop locations
   * changes.
   */
  useEffect(() => {
    setPage(0);
    if (setPageCallback) setPageCallback(0);
  }, [locations]);

  if (locations.length == 0) return null;
  let center_line_num = getCenterLine();
  let is_clickable =
    !fromReportPDF && lines.length > 0 && getVersion() === "decompile";
  return (
    <>
      <h3>Discovery Location</h3>
      <div
        className={
          is_clickable
            ? "discovery-location discovery-location-clickable"
            : "discovery-location"
        }
        onClick={() => {
          if (!is_clickable) return;
          window.location.assign(
            `#/deepdive?name=${binary}&id=${asset_id}&line=${
              center_line_num - 1
            }`
          );
        }}
      >
        <SyntaxHighlighter
          wrapLines={true}
          wrapLongLines={true}
          showLineNumbers={getVersion() === "decompile"}
          startingLineNumber={center_line_num + 1}
          language={getVersion() === "disassemble" ? "x86asm" : "c"}
          style={dark}
        >
          {lines.length === 0
            ? "\t No associated discovery location."
            : lines
                .map((line) => {
                  return line;
                })
                .join("")}
        </SyntaxHighlighter>
        {lines.length > 0 && <p className="deep-dive-line"></p>}
      </div>

      <div className="row">
        <h3>&nbsp; &nbsp; &nbsp; &nbsp;{getVersion()} view</h3>
        <h3 className="page-count">
          {page + 1} of {locations.length}
        </h3>
        <ChevronLeft
          onClick={() => {
            if (page === 0) return;
            setPage((page) => page - 1);
          }}
          className="icon"
        />
        <ChevronRight
          onClick={() => {
            if (page === locations.length - 1) return;
            setPage((page) => page + 1);
          }}
          className="icon"
        />
      </div>
    </>
  );
}

/**
 * Cleans a set of lines of any unwanted information.
 */
function cleanLines(lines) {
  let cleaned_lines = [];
  for (let line of lines) {
    line = line.replace("[r2ghidra]", "");
    line = line.replace("r2ghidra", "");
    cleaned_lines.push(line);
  }
  return cleaned_lines;
}

DiscoveryLocation.propTypes = {
  /**
   * The ID of the asset the discovered locations
   * are found in.
   */
  asset_id: PropTypes.string.isRequired,

  /**
   * The binary the discovered locations are found in.
   */
  binary: PropTypes.instanceOf(Binary).isRequired,

  /**
   * The set  of deep-dive locations that the user can
   * toggle the discovery location viewer between.
   */
  locations: PropTypes.array.isRequired,

  /**
   * If the component is rendered from the
   * report customizer.
   */
  fromReportPDF: PropTypes.bool,
};
