/*
    This file contains constants used to map discovered vulnerabilities
    to various rules, regulations, and requirements.

    Sample Mappings:

    - 250: Execution with Unnecessary Privileges
  1.3 Account management
  1.1 Human user identification and authentication
  1.2 Software process and device identification and authentication
  2.1 Authorization enforcement

  - 400: Uncontrolled Resource Consumption
  7.2 Resource management

  - 20: Improper Input Validation
  3.5 Input validation

  - 787: Out-of-bounds Write
  3.2 Malicious code protection

  - 94: Improper Control of Generation of Code ('Code Injection')
  3.1 Communication integrity
  3.5 Input validation
  3.2 Malicious code protection

  - 190: Integer Overflow or Wraparound
  3.2 Malicious code protection
  7.2 Resource management

  - 1233: Security-Sensitive Hardware Controls with Missing Lock Bit Protection
  2.5 Session lock
  2.7 Concurrent session control

  - 367: Time-of-check Time-of-use (TOCTOU) Race Condition
  2.5 Session lock
  2.7 Concurrent session control
  7.2 Resource management

  - 330: Use of Insufficiently Random Values
  3.6 Deterministic output

  - 120: Buffer Copy without Checking Size of Input ('Classic Buffer Overflow')
  3.5 Input validation
  3.2 Malicious code protection

  - 119: Improper Restriction of Operations within the Bounds of a Memory Buffer
  3.2 Malicious code protection

  - 134: Use of Externally-Controlled Format String
  3.5 Input validation

  - 126: Buffer Over-read
  3.2 Malicious code protection

  - 681: Incorrect Conversion between Numeric Types
  3.2 Malicious code protection

  - 807: Reliance on Untrusted Inputs in a Security Decision 
  3.5 Input validation

  - 710: Improper Adherence to Coding Standards
  3.2 Malicious code protection

  - 616: Incomplete Identification of Uploaded File Variables (PHP)
  2.1 Authorization enforcement
  3.4 Software and information integrity
  3.5 Input validation

  - 453: Insecure Default Variable Initialization
  3.2 Malicious code protection
  3.4 Software and information integrity

  - 1119: Excessive Use of Unconditional Branching
  7.7 Least functionality

  - 561: Dead Code
  7.7 Least functionality

  - 563: Assignment to Variable without Use
  7.7 Least functionality

  - 1109: Use of Same Variable for Multiple Purposes
  7.7 Least functionality

  - 805: Buffer Access with Incorrect Length Value
  3.2 Malicious code protection

  - 758: Reliance on Undefined, Unspecified, or Implementation-Defined Behavior
  3.6 Deterministic output

  - 195: Signed to Unsigned Conversion Error
  3.2 Malicious code protection

  - 78: Improper Neutralization of Special Elements used in an OS Command ('OS Command Injection')
  2.1 Authorization enforcement
  3.2 Malicious code protection
  5.2 Zone boundary protection
  5.4 Application partitioning

  - 362: Concurrent Execution using Shared Resource with Improper Synchronization ('Race Condition')
  2.5 Session lock
  2.7 Concurrent session control
  7.2 Resource management

  - 732: Incorrect Permission Assignment for Critical Resource
  2.1 Authorization enforcement
  7.2 Resource management

  - 121: Stack-based Buffer Overflow
  3.2 Malicious code protection

  - 772: Missing Release of Resource after Effective Lifetime
  7.2 Resource management

  - 691: Insufficient Control Flow Management
  3.6 Deterministic output

  - 1164: Irrelevant Code
  7.7 Least functionality


  CRYPTO:

  - 347: Improper Verification of Cryptographic Signature
  - 522: Insufficiently Protected Credentials
  - 321: Use of Hard-coded Cryptographic Key
  - 798: Use of Hard-coded Credentials
  - 327: Use of a Broken or Risky Cryptographic Algorithm

  all map to  -->

  1.9 Strength of public key authentication
  3.1 Communication integrity
  3.3 Security functionality verification
  4.3 Use of cryptography

*/

const OWASP_2021_MAPPING = {
  15: {
    cwe_name: "External Control of System or Configuration Setting",
    static_support: true,
    dynamic_support: false,
    veracode_severity: "4 - High",
  },
  16: {
    cwe_name: "Configuration",
    static_support: false,
    dynamic_support: true,
    veracode_severity: "0 - Informational",
  },
  20: {
    cwe_name: "Improper Input Validation",
    static_support: true,
    dynamic_support: false,
    veracode_severity: "0 - Informational",
  },
  22: {
    cwe_name:
      "Improper Limitation of a Pathname to a Restricted Directory (Path Traversal)",
    static_support: true,
    dynamic_support: true,
    veracode_severity: "3 - Medium",
  },
  35: {
    cwe_name: "Path Traversal: '.../...//'",
    static_support: true,
    dynamic_support: false,
    veracode_severity: "2 - Low",
  },
  73: {
    cwe_name: "External Control of File Name or Path",
    static_support: true,
    dynamic_support: false,
    veracode_severity: "3 - Medium",
  },
  74: {
    cwe_name:
      "Improper Neutralization of Special Elements in Output Used by a Downstream Component (Injection)",
    static_support: false,
    dynamic_support: true,
    veracode_severity: "4 - High",
  },
  77: {
    cwe_name:
      "Improper Neutralization of Special Elements used in a Command (Command Injection)",
    static_support: true,
    dynamic_support: false,
    veracode_severity: "5 - Very High",
  },
  78: {
    cwe_name:
      "Improper Neutralization of Special Elements used in an OS Command (OS Command Injection)",
    static_support: true,
    dynamic_support: true,
    veracode_severity: "5 - Very High",
  },
  79: {
    cwe_name:
      "Improper Neutralization of Input During Web Page Generation (Cross-site Scripting)",
    static_support: true,
    dynamic_support: true,
    veracode_severity: "3 - Medium",
  },
  80: {
    cwe_name:
      "Improper Neutralization of Script-Related HTML Tags in a Web Page (Basic XSS)",
    static_support: true,
    dynamic_support: true,
    veracode_severity: "3 - Medium",
  },
  83: {
    cwe_name: "Improper Neutralization of Script in Attributes in a Web Page",
    static_support: true,
    dynamic_support: true,
    veracode_severity: "3 - Medium",
  },
  86: {
    cwe_name:
      "Improper Neutralization of Invalid Characters in Identifiers in Web Pages",
    static_support: true,
    dynamic_support: false,
    veracode_severity: "3 - Medium",
  },
  88: {
    cwe_name:
      "Improper Neutralization of Argument Delimiters in a Command ('Argument Injection')",
    static_support: true,
    dynamic_support: false,
    veracode_severity: "3 - Medium",
  },
  89: {
    cwe_name:
      "Improper Neutralization of Special Elements used in an SQL Command (SQL Injection)",
    static_support: true,
    dynamic_support: true,
    veracode_severity: "4 - High",
  },
  90: {
    cwe_name:
      "Improper Neutralization of Special Elements used in an LDAP Query (LDAP Injection)",
    static_support: true,
    dynamic_support: false,
    veracode_severity: "3 - Medium",
  },
  91: {
    cwe_name: "XML Injection (aka Blind XPath Injection)",
    static_support: true,
    dynamic_support: true,
    veracode_severity: "3 - Medium",
  },
  93: {
    cwe_name: "Improper Neutralization of CRLF Sequences (CRLF Injection)",
    static_support: true,
    dynamic_support: false,
    veracode_severity: "3 - Medium",
  },
  94: {
    cwe_name: "Improper Control of Generation of Code (Code Injection)",
    static_support: true,
    dynamic_support: false,
    veracode_severity: "3 - Medium",
  },
  95: {
    cwe_name:
      "Improper Neutralization of Directives in Dynamically Evaluated Code (Eval Injection)",
    static_support: true,
    dynamic_support: true,
    veracode_severity: "5 - Very High",
  },
  98: {
    cwe_name:
      "Improper Control of Filename for Include/Require Statement in PHP Program (PHP Remote File Inclusion)",
    static_support: true,
    dynamic_support: true,
    veracode_severity: "4 - High",
  },
  99: {
    cwe_name: "Improper Control of Resource Identifiers (Resource Injection)",
    static_support: true,
    dynamic_support: false,
    veracode_severity: "3 - Medium",
  },
  112: {
    cwe_name: "Missing XML Validation",
    static_support: true,
    dynamic_support: false,
    veracode_severity: "3 - Medium",
  },
  113: {
    cwe_name:
      "Improper Neutralization of CRLF Sequences in HTTP Headers (HTTP Response Splitting)",
    static_support: true,
    dynamic_support: true,
    veracode_severity: "3 - Medium",
  },
  114: {
    cwe_name: "Process Control",
    static_support: true,
    dynamic_support: false,
    veracode_severity: "5 - Very High",
  },
  117: {
    cwe_name: "Improper Output Neutralization for Logs",
    static_support: true,
    dynamic_support: false,
    veracode_severity: "3 - Medium",
  },
  129: {
    cwe_name: "Improper Validation of Array Index",
    static_support: true,
    dynamic_support: false,
    veracode_severity: "3 - Medium",
  },
  134: {
    cwe_name: "Use of Externally-Controlled Format String",
    static_support: true,
    dynamic_support: false,
    veracode_severity: "5 - Very High",
  },
  159: {
    cwe_name: "Improper Handling of Invalid Use of Special Elements",
    static_support: true,
    dynamic_support: false,
    veracode_severity: "0 - Informational",
  },
  183: {
    cwe_name: "Permissive List of Allowed Inputs",
    static_support: true,
    dynamic_support: false,
    veracode_severity: "3 - Medium",
  },
  200: {
    cwe_name: "Exposure of Sensitive Information to an Unauthorized Actor",
    static_support: true,
    dynamic_support: true,
    veracode_severity: "2 - Low",
  },
  201: {
    cwe_name: "Insertion of Sensitive Information Into Sent Data",
    static_support: true,
    dynamic_support: false,
    veracode_severity: "2 - Low",
  },
  209: {
    cwe_name: "Generation of Error Message Containing Sensitive Information",
    static_support: true,
    dynamic_support: true,
    veracode_severity: "2 - Low",
  },
  215: {
    cwe_name: "Insertion of Sensitive Information Into Debugging Code",
    static_support: true,
    dynamic_support: true,
    veracode_severity: "2 - Low",
  },
  223: {
    cwe_name: "Omission of Security-relevant Information",
    static_support: true,
    dynamic_support: false,
    veracode_severity: "2 - Low",
  },
  256: {
    cwe_name: "Plaintext Storage of a Password",
    static_support: true,
    dynamic_support: false,
    veracode_severity: "3 - Medium",
  },
  259: {
    cwe_name: "Use of Hard-coded Password",
    static_support: true,
    dynamic_support: true,
    veracode_severity: "3 - Medium",
  },
  261: {
    cwe_name: "Weak Encoding for Password",
    static_support: true,
    dynamic_support: false,
    veracode_severity: "3 - Medium",
  },
  272: {
    cwe_name: "Least Privilege Violation",
    static_support: true,
    dynamic_support: false,
    veracode_severity: "3 - Medium",
  },
  282: {
    cwe_name: "Improper Ownership Management",
    static_support: true,
    dynamic_support: false,
    veracode_severity: "3 - Medium",
  },
  285: {
    cwe_name: "Improper Authorization",
    static_support: true,
    dynamic_support: true,
    veracode_severity: "3 - Medium",
  },
  287: {
    cwe_name: "Improper Authentication",
    static_support: true,
    dynamic_support: true,
    veracode_severity: "4 - High",
  },
  295: {
    cwe_name: "Improper Certificate Validation",
    static_support: true,
    dynamic_support: false,
    veracode_severity: "3 - Medium",
  },
  296: {
    cwe_name: "Improper Following of a Certificate's Chain of Trust",
    static_support: false,
    dynamic_support: true,
    veracode_severity: "3 - Medium",
  },
  297: {
    cwe_name: "Improper Validation of Certificate with Host Mismatch",
    static_support: true,
    dynamic_support: true,
    veracode_severity: "3 - Medium",
  },
  298: {
    cwe_name: "Improper Validation of Certificate Expiration",
    static_support: false,
    dynamic_support: true,
    veracode_severity: "3 - Medium",
  },
  299: {
    cwe_name: "Improper Check for Certificate Revocation",
    static_support: false,
    dynamic_support: true,
    veracode_severity: "3 - Medium",
  },
  311: {
    cwe_name: "Missing Encryption of Sensitive Data",
    static_support: true,
    dynamic_support: false,
    veracode_severity: "3 - Medium",
  },
  312: {
    cwe_name: "Cleartext Storage of Sensitive Information",
    static_support: true,
    dynamic_support: false,
    veracode_severity: "3 - Medium",
  },
  313: {
    cwe_name: "Cleartext Storage in a File or on Disk",
    static_support: true,
    dynamic_support: false,
    veracode_severity: "3 - Medium",
  },
  316: {
    cwe_name: "Cleartext Storage of Sensitive Information in Memory",
    static_support: true,
    dynamic_support: false,
    veracode_severity: "3 - Medium",
  },
  319: {
    cwe_name: "Cleartext Transmission of Sensitive Information",
    static_support: true,
    dynamic_support: false,
    veracode_severity: "3 - Medium",
  },
  321: {
    cwe_name: "Use of Hard-coded Cryptographic Key",
    static_support: true,
    dynamic_support: true,
    veracode_severity: "3 - Medium",
  },
  326: {
    cwe_name: "Inadequate Encryption Strength",
    static_support: true,
    dynamic_support: true,
    veracode_severity: "3 - Medium",
  },
  327: {
    cwe_name: "Use of a Broken or Risky Cryptographic Algorithm",
    static_support: true,
    dynamic_support: true,
    veracode_severity: "3 - Medium",
  },
  328: {
    cwe_name: "Use of Weak Hash",
    static_support: true,
    dynamic_support: false,
    veracode_severity: "3 - Medium",
  },
  329: {
    cwe_name: "Generation of Predictable IV with CBC Mode",
    static_support: true,
    dynamic_support: false,
    veracode_severity: "2 - Low",
  },
  330: {
    cwe_name: "Use of Insufficiently Random Values",
    static_support: true,
    dynamic_support: false,
    veracode_severity: "3 - Medium",
  },
  331: {
    cwe_name: "Insufficient Entropy",
    static_support: true,
    dynamic_support: false,
    veracode_severity: "3 - Medium",
  },
  338: {
    cwe_name:
      "Use of Cryptographically Weak Pseudo-Random Number Generator (PRNG)",
    static_support: true,
    dynamic_support: false,
    veracode_severity: "3 - Medium",
  },
  345: {
    cwe_name: "Insufficient Verification of Data Authenticity",
    static_support: true,
    dynamic_support: false,
    veracode_severity: "4 - High",
  },
  346: {
    cwe_name: "Origin Validation Error",
    static_support: true,
    dynamic_support: false,
    veracode_severity: "3 - Medium",
  },
  347: {
    cwe_name: "Improper Verification of Cryptographic Signature",
    static_support: true,
    dynamic_support: false,
    veracode_severity: "2 - Low",
  },
  350: {
    cwe_name:
      "Reliance on Reverse DNS Resolution for a Security-Critical Action",
    static_support: true,
    dynamic_support: false,
    veracode_severity: "3 - Medium",
  },
  352: {
    cwe_name: "Cross-Site Request Forgery (CSRF)",
    static_support: true,
    dynamic_support: true,
    veracode_severity: "3 - Medium",
  },
  354: {
    cwe_name: "Improper Validation of Integrity Check Value",
    static_support: true,
    dynamic_support: false,
    veracode_severity: "3 - Medium",
  },
  359: {
    cwe_name:
      "Exposure of Private Personal Information to an Unauthorized Actor",
    static_support: true,
    dynamic_support: false,
    veracode_severity: "2 - Low",
  },
  377: {
    cwe_name: "Insecure Temporary File",
    static_support: true,
    dynamic_support: false,
    veracode_severity: "3 - Medium",
  },
  384: {
    cwe_name: "Session Fixation",
    static_support: true,
    dynamic_support: true,
    veracode_severity: "3 - Medium",
  },
  402: {
    cwe_name:
      "Transmission of Private Resources into a New Sphere ('Resource Leak')",
    static_support: false,
    dynamic_support: true,
    veracode_severity: "3 - Medium",
  },
  421: {
    cwe_name: "Race Condition During Access to Alternate Channel",
    static_support: true,
    dynamic_support: false,
    veracode_severity: "3 - Medium",
  },
  426: {
    cwe_name: "Untrusted Search Path",
    static_support: true,
    dynamic_support: false,
    veracode_severity: "3 - Medium",
  },
  427: {
    cwe_name: "Uncontrolled Search Path Element",
    static_support: true,
    dynamic_support: false,
    veracode_severity: "3 - Medium",
  },
  434: {
    cwe_name: "Unrestricted Upload of File with Dangerous Type",
    static_support: false,
    dynamic_support: true,
    veracode_severity: "4 - High",
  },
  441: {
    cwe_name: "Unintended Proxy or Intermediary ('Confused Deputy')",
    static_support: true,
    dynamic_support: false,
    veracode_severity: "3 - Medium",
  },
  470: {
    cwe_name:
      "Use of Externally-Controlled Input to Select Classes or Code ('Unsafe Reflection')",
    static_support: true,
    dynamic_support: false,
    veracode_severity: "3 - Medium",
  },
  472: {
    cwe_name: "External Control of Assumed-Immutable Web Parameter",
    static_support: true,
    dynamic_support: false,
    veracode_severity: "3 - Medium",
  },
  494: {
    cwe_name: "Download of Code Without Integrity Check",
    static_support: true,
    dynamic_support: false,
    veracode_severity: "5 - Very High",
  },
  497: {
    cwe_name:
      "Exposure of Sensitive System Information to an Unauthorized Control Sphere",
    static_support: true,
    dynamic_support: false,
    veracode_severity: "2 - Low",
  },
  501: {
    cwe_name: "Trust Boundary Violation",
    static_support: true,
    dynamic_support: false,
    veracode_severity: "3 - Medium",
  },
  502: {
    cwe_name: "Deserialization of Untrusted Data",
    static_support: true,
    dynamic_support: false,
    veracode_severity: "3 - Medium",
  },
  522: {
    cwe_name: "Insufficiently Protected Credentials",
    static_support: true,
    dynamic_support: true,
    veracode_severity: "3 - Medium",
  },
  526: {
    cwe_name:
      "Exposure of Sensitive Information Through Environmental Variables",
    static_support: false,
    dynamic_support: true,
    veracode_severity: "2 - Low",
  },
  530: {
    cwe_name: "Exposure of Backup File to an Unauthorized Control Sphere",
    static_support: false,
    dynamic_support: true,
    veracode_severity: "2 - Low",
  },
  532: {
    cwe_name: "Insertion of Sensitive Information into Log File",
    static_support: true,
    dynamic_support: false,
    veracode_severity: "2 - Low",
  },
  538: {
    cwe_name:
      "Insertion of Sensitive Information into Externally-Accessible File or Directory",
    static_support: false,
    dynamic_support: true,
    veracode_severity: "2 - Low",
  },
  547: {
    cwe_name: '"Use of Hard-coded, Security-relevant Constants"',
    static_support: true,
    dynamic_support: false,
    veracode_severity: "3 - Medium",
  },
  548: {
    cwe_name: "Information Exposure Through Directory Listing",
    static_support: false,
    dynamic_support: true,
    veracode_severity: "2 - Low",
  },
  564: {
    cwe_name: "SQL Injection: Hibernate",
    static_support: true,
    dynamic_support: false,
    veracode_severity: "4 - High",
  },
  566: {
    cwe_name: "Authorization Bypass Through User-Controlled SQL Primary Key",
    static_support: true,
    dynamic_support: false,
    veracode_severity: "3 - Medium",
  },
  601: {
    cwe_name: "URL Redirection to Untrusted Site ('Open Redirect')",
    static_support: true,
    dynamic_support: true,
    veracode_severity: "3 - Medium",
  },
  611: {
    cwe_name: "Improper Restriction of XML External Entity Reference (XXE)",
    static_support: true,
    dynamic_support: true,
    veracode_severity: "3 - Medium",
  },
  614: {
    cwe_name: "Sensitive Cookie in HTTPS Session Without 'Secure' Attribute",
    static_support: true,
    dynamic_support: true,
    veracode_severity: "2 - Low",
  },
  615: {
    cwe_name: "Inclusion of Sensitive Information in Source Code Comments",
    static_support: true,
    dynamic_support: true,
    veracode_severity: "0 - Informational",
  },
  639: {
    cwe_name: "Authorization Bypass Through User-Controlled Key",
    static_support: true,
    dynamic_support: false,
    veracode_severity: "4 - High",
  },
  642: {
    cwe_name: "External Control of Critical State Data",
    static_support: false,
    dynamic_support: true,
    veracode_severity: "2 - Low",
  },
  656: {
    cwe_name: "Reliance on Security Through Obscurity",
    static_support: true,
    dynamic_support: false,
    veracode_severity: "0 - Informational",
  },
  668: {
    cwe_name: "Exposure of Resource to Wrong Sphere",
    static_support: true,
    dynamic_support: true,
    veracode_severity: "3 - Medium",
  },
  708: {
    cwe_name: "Incorrect Ownership Assignment",
    static_support: true,
    dynamic_support: false,
    veracode_severity: "4 - High",
  },
  732: {
    cwe_name: "Incorrect Permission Assignment for Critical Resource",
    static_support: true,
    dynamic_support: false,
    veracode_severity: "3 - Medium",
  },
  757: {
    cwe_name:
      "Selection of Less-Secure Algorithm During Negotiation ('Algorithm Downgrade')",
    static_support: true,
    dynamic_support: true,
    veracode_severity: "3 - Medium",
  },
  760: {
    cwe_name: "Use of a One-Way Hash with a Predictable Salt",
    static_support: true,
    dynamic_support: false,
    veracode_severity: "3 - Medium",
  },
  780: {
    cwe_name: "Use of RSA Algorithm without OAEP",
    static_support: true,
    dynamic_support: false,
    veracode_severity: "3 - Medium",
  },
  798: {
    cwe_name: "Use of Hard-coded Credentials",
    static_support: true,
    dynamic_support: false,
    veracode_severity: "3 - Medium",
  },
  829: {
    cwe_name: "Inclusion of Functionality from Untrusted Control Sphere",
    static_support: true,
    dynamic_support: true,
    veracode_severity: "3 - Medium",
  },
  830: {
    cwe_name: "Inclusion of Web Functionality from an Untrusted Source",
    static_support: false,
    dynamic_support: true,
    veracode_severity: "2 - Low",
  },
  915: {
    cwe_name:
      "Improperly Controlled Modification of Dynamically-Determined Object Attributes",
    static_support: true,
    dynamic_support: false,
    veracode_severity: "3 - Medium",
  },
  916: {
    cwe_name: "Use of Password Hash With Insufficient Computational Effort",
    static_support: true,
    dynamic_support: false,
    veracode_severity: "3 - Medium",
  },
  918: {
    cwe_name: "Server-Side Request Forgery (SSRF)",
    static_support: true,
    dynamic_support: true,
    veracode_severity: "3 - Medium",
  },
  926: {
    cwe_name: "Improper Export of Android Application Components",
    static_support: true,
    dynamic_support: false,
    veracode_severity: "3 - Medium",
  },
  942: {
    cwe_name: "Permissive Cross-domain Policy with Untrusted Domains",
    static_support: true,
    dynamic_support: true,
    veracode_severity: "3 - Medium",
  },
  943: {
    cwe_name: "Improper Neutralization of Special Elements in Data Query Logic",
    static_support: true,
    dynamic_support: false,
    veracode_severity: "4 - High",
  },
  1174: {
    cwe_name: "ASP.NET Misconfiguration: Improper Model Validation",
    static_support: true,
    dynamic_support: false,
    veracode_severity: "2 - Low",
  },
  1236: {
    cwe_name: "Improper Neutralization of Formula Elements in a CSV File",
    static_support: true,
    dynamic_support: false,
    veracode_severity: "3 - Medium",
  },
};

export const ISA_3_3_MAPPING = [
  {
    vulnerabilities: ["lineage"],
    req: [
      {
        items: ["FR 3", "SR 3.2 ", "SR 3.2"],
        desc: "Malicious code protection",
      },
      {
        items: ["FR 3", "SR 3.2 ", "SR 3.2 -RE (1)"],
        desc: "Malicious code protection on entry and exit points",
      },
      {
        items: ["FR 3", "SR 3.2 ", "SR 3.2 -RE (2)"],
        desc: "Central management and reporting for malicious code protection",
      },
    ],
  },
  {
    vulnerabilities: [
      276, 277, 278, 279, 280, 281, 283, 288, 441, 618, 639, 640, 708, 766, 767,
      770, 826, 837, 841, 1189, 1192, 1220, 1242, 1260, 1262, 1267, 1268, 1280,
      1294, 1299, 1302, 1303, 1314, 1318, 1334,
    ],
    req: [
      {
        items: ["FR 3", "SR 3.4 ", "SR 3.4"],
        desc: "Software and information integrity",
      },
      { items: ["FR 3", "SR 3.5 ", "SR 3.5"], desc: "Input validation" },
      {
        items: ["FR 3", "SR 3.9 ", "SR 3.9"],
        desc: "Protection of audit information",
      },
      {
        items: ["FR 4", "SR 4.1 ", "SR 4.1"],
        desc: "Information confidentiality",
      },
      {
        items: ["FR 4", "SR 4.1 ", "SR 4.1 -RE (1)"],
        desc: "Protection of confidentiality at rest or in transit via untrusted networks",
      },
      {
        items: ["FR 4", "SR 4.1 ", "SR 4.1 -RE (2)"],
        desc: "Protection of confidentiality across zone boundaries",
      },
    ],
  },
  {
    vulnerabilities: [1190, 1193, 1264, 1274, 1283, 1310, 1326, 1328],
    req: [
      {
        items: ["FR 3", "SR 3.1 ", "SR 3.1"],
        desc: "Communication integrity",
      },
      {
        items: ["FR 3", "SR 3.3 ", "SR 3.3"],
        desc: "Security functionality verification",
      },
      {
        items: ["FR 3", "SR 3.3 ", "SR 3.3 -RE (1)"],
        desc: "Automated mechanisms for security functionality verification",
      },
      {
        items: ["FR 3", "SR 3.3 ", "SR 3.3 -RE (2)"],
        desc: "Security functionality verification during normal operation",
      },
      {
        items: ["FR 3", "SR 3.4 ", "SR 3.4"],
        desc: "Software and information integrity",
      },
    ],
  },
  {
    vulnerabilities: [23, 36, 41, 59, 66, 378, 379, 426, 427, 428],
    req: [
      { items: ["FR 3", "SR 3.6 ", "SR 3.6"], desc: "Deterministic output" },
      {
        items: ["FR 4", "SR 4.2 ", "SR 4.2"],
        desc: "Information persistence",
      },
      {
        items: ["FR 4", "SR 4.2 ", "SR 4.2 -RE (1)"],
        desc: "Purging of shared memory resources",
      },
    ],
  },
  {
    vulnerabilities: [226, 454, 455, 459, 460, 1051, 1052, 1188],
    req: [
      { items: ["FR 3", "SR 3.8 ", "SR 3.8"], desc: "Session integrity" },
      {
        items: ["FR 4", "SR 4.2 ", "SR 4.2 -RE (1)"],
        desc: "Purging of shared memory resources",
      },
    ],
  },
  {
    vulnerabilities: [
      117, 222, 223, 224, 532, 778, 779, 209, 248, 252, 253, 390, 391, 392, 393,
      394, 395, 396, 397, 544, 584, 600, 617, 756, 1069, 430, 431, 432, 433,
      434, 479,
    ],
    req: [
      {
        items: ["FR 3", "SR 3.4 ", "SR 3.4 -RE (1)"],
        desc: "Automated notification about integrity violations",
      },
      { items: ["FR 3", "SR 3.7 ", "SR 3.7"], desc: "Error handling" },
      {
        items: ["FR 3", "SR 3.9 ", "SR 3.9 -RE (1)"],
        desc: "Audit records on write-once media",
      },
    ],
  },
  {
    vulnerabilities: [
      321, 322, 323, 324, 261, 324, 325, 328, 331, 334, 335, 338, 347, 916,
      1240,
    ],
    req: [
      {
        items: ["FR 3", "SR 3.1 ", "SR 3.1 -RE (1)"],
        desc: "Cryptographic integrity protection",
      },
      { items: ["FR 4", "SR 4.3 ", "SR 4.3"], desc: "Use of cryptography" },
    ],
  },
  {
    vulnerabilities: [488, 613, 841],
    req: [
      { items: ["FR 3", "SR 3.8 ", "SR 3.8"], desc: "Session integrity" },
      {
        items: ["FR 3", "SR 3.8 ", "SR 3.8 -RE (1)"],
        desc: "Invalidation of session IDs after session termination",
      },
    ],
  },
  {
    vulnerabilities: [694, 914, 1099],
    req: [
      {
        items: ["FR 3", "SR 3.8 ", "SR 3.8 -RE (1)"],
        desc: "Invalidation of session IDs after session termination",
      },
      {
        items: ["FR 3", "SR 3.8 ", "SR 3.8 -RE (2)"],
        desc: "Unique session ID generation",
      },
      {
        items: ["FR 3", "SR 3.8 ", "SR 3.8 -RE (3)"],
        desc: "Randomness of session IDs",
      },
    ],
  },
  {
    vulnerabilities: [203, 325, 1240, 1241, 1279, 1351],
    req: [
      {
        items: ["FR 1", "SR 1.5", "SR 1.5-RE (1)"],
        desc: "Hardware security for software process identity credentials",
      },
      {
        items: ["FR 1", "SR 1.9", "SR 1.9-RE (1)"],
        desc: "Hardware security for public key authentication",
      },
    ],
  },
  // NEW (1/6/2023) -->
  {
    vulnerabilities: [347, 522, 321, 798, 327],
    req: [
      {
        items: ["FR 1", "SR 1.5", "SR 1.5"],
        desc: "Authentication management",
      },
      {
        items: ["FR 1", "SR 1.9", "SR 1.9"],
        desc: "Strength of public key authentication",
      },
      {
        items: ["FR 3", "SR 3.1", "SR 3.1"],
        desc: "Communication integrity",
      },
      {
        items: ["FR 3", "SR 3.3", "SR 3.3"],
        desc: "Security functionality verification",
      },
      {
        items: ["FR 4", "SR 4.3", "SR 4.3"],
        desc: "Use of cryptography",
      },
    ],
  },
  {
    vulnerabilities: [1119, 561, 563, 1109, 1164],
    req: [
      {
        items: ["FR 7", "SR 7.7", "SR 7.7"],
        desc: "Least functionality",
      },
    ],
  },
  {
    vulnerabilities: [787, 121, 195, 805, 119, 681, 126],
    req: [
      {
        items: ["FR 3", "SR 3.2", "SR 3.2"],
        desc: "Malicious code protection",
      },
    ],
  },
  {
    vulnerabilities: [20, 807, 134],
    req: [
      {
        items: ["FR 3", "SR 3.5", "SR 3.5"],
        desc: "Input validation",
      },
    ],
  },
  {
    vulnerabilities: [400, 772],
    req: [
      {
        items: ["FR 3", "SR 3.5", "SR 3.5"],
        desc: "System integrity",
      },
      {
        items: ["FR 7", "SR 7.1", "SR 7.1"],
        desc: "Denial of service protection",
      },
      {
        items: ["FR 7", "SR 7.2", "SR 7.2"],
        desc: "Resource management",
      },
    ],
  },
  {
    vulnerabilities: [758, 691, 330],
    req: [
      {
        items: ["FR 3", "SR 3.6", "SR 3.6"],
        desc: "Deterministic output",
      },
    ],
  },
  {
    vulnerabilities: [732],
    req: [
      {
        items: ["FR 2", "SR 2.1", "SR 2.1"],
        desc: "Authorization enforcement",
      },
      {
        items: ["FR 7", "SR 7.2", "SR 7.2"],
        desc: "Resource management",
      },
    ],
  },
  {
    vulnerabilities: [710, 453],
    req: [
      {
        items: ["FR 3", "SR 3.2", "SR 3.2"],
        desc: "Malicious code protection",
      },
      {
        items: ["FR 3", "SR 3.4", "SR 3.4"],
        desc: "Software and information integrity",
      },
    ],
  },
  {
    vulnerabilities: [120],
    req: [
      {
        items: ["FR 3", "SR 3.2", "SR 3.2"],
        desc: "Malicious code protection",
      },
      {
        items: ["FR 3", "SR 3.5", "SR 3.5"],
        desc: "Input validation",
      },
    ],
  },
  {
    vulnerabilities: [1233],
    req: [
      {
        items: ["FR 2", "SR 2.5", "SR 2.5"],
        desc: "Session lock",
      },
      {
        items: ["FR 2", "SR 2.7", "SR 2.7"],
        desc: "Concurrent session control",
      },
    ],
  },
  {
    vulnerabilities: [190],
    req: [
      {
        items: ["FR 3", "SR 3.2", "SR 3.2"],
        desc: "Malicious code protection",
      },
      {
        items: ["FR 7", "SR 7.2", "SR 7.2"],
        desc: "Resource management",
      },
    ],
  },
  {
    vulnerabilities: [362, 367],
    req: [
      {
        items: ["FR 2", "SR 2.5", "SR 2.5"],
        desc: "Session lock",
      },
      {
        items: ["FR 2", "SR 2.7", "SR 2.7"],
        desc: "Concurrent session control",
      },
      {
        items: ["FR 7", "SR 7.2", "SR 7.2"],
        desc: "Resource management",
      },
    ],
  },
  {
    vulnerabilities: [94],
    req: [
      {
        items: ["FR 3", "SR 3.1", "SR 3.1"],
        desc: "Communication integrity",
      },
      {
        items: ["FR 3", "SR 3.2", "SR 3.2"],
        desc: "Malicious code protection",
      },
      {
        items: ["FR 3", "SR 3.5", "SR 3.5"],
        desc: "Input validation",
      },
    ],
  },
  {
    vulnerabilities: [616],
    req: [
      {
        items: ["FR 2", "SR 2.1", "SR 2.1"],
        desc: "Authorization enforcement",
      },
      {
        items: ["FR 3", "SR 3.4", "SR 3.4"],
        desc: "Software and information integrity",
      },
      {
        items: ["FR 3", "SR 3.5", "SR 3.5"],
        desc: "Input validation",
      },
    ],
  },
  {
    vulnerabilities: [78],
    req: [
      {
        items: ["FR 2", "SR 2.1", "SR 2.1"],
        desc: "Authorization enforcement",
      },
      {
        items: ["FR 3", "SR 3.2", "SR 3.2"],
        desc: "Malicious code protection",
      },
      {
        items: ["FR 5", "SR 5.2", "SR 5.2"],
        desc: "Zone boundary protection",
      },
      {
        items: ["FR 5", "SR 5.4", "SR 5.4"],
        desc: "Application partitioning",
      },
    ],
  },
  {
    vulnerabilities: [250],
    req: [
      {
        items: ["FR 1", "SR 1.1", "SR 1.1"],
        desc: "Human user identification and authentication",
      },
      {
        items: ["FR 1", "SR 1.2", "SR 1.2"],
        desc: "Software process and device identification and authentication",
      },
      {
        items: ["FR 1", "SR 1.3", "SR 1.3"],
        desc: "Account management",
      },
      {
        items: ["FR 2", "SR 2.1", "SR 2.1"],
        desc: "Authorization enforcement",
      },
    ],
  },
  // <--
];

export const ISA_4_2_MAPPING = [
  {
    vulnerabilities: ["lineage"],
    req: [
      {
        items: ["CR 3.2", "", "CR 3.2"],
        desc: "Protection from malicious code",
      },
      {
        items: ["SAR 3.2 ", "", "SAR 3.2"],
        desc: "Malicious code protection",
      },
      {
        items: ["EDR 3.2 ", "", "EDR 3.2"],
        desc: "Malicious code protection",
      },
      {
        items: ["HDR 3.2 ", "", "HDR 3.2"],
        desc: "Malicious code protection",
      },
      {
        items: ["NDR 3.2 ", "", "NDR 3.2"],
        desc: "Malicious code protection",
      },
    ],
  },
  {
    vulnerabilities: [
      276, 277, 278, 279, 280, 281, 283, 288, 441, 618, 639, 640, 708, 766, 767,
      770, 826, 837, 841, 1189, 1192, 1220, 1242, 1260, 1262, 1267, 1268, 1280,
      1294, 1299, 1302, 1303, 1314, 1318, 1334,
    ],
    req: [
      {
        items: ["CR 3.4 ", "", "CR 3.4"],
        desc: "Software and information integrity",
      },
      { items: ["CR 3.5 ", "", "CR 3.5"], desc: "Input validation" },
      { items: ["CR 3.8 ", "", "CR 3.8"], desc: "Session integrity" },
      {
        items: ["CR 3.9 ", "", "CR 3.9"],
        desc: "Protection of audit information",
      },
      {
        items: ["EDR 3.13", "", "EDR 3.13"],
        desc: "Provisioning asset owner roots of trust",
      },
      {
        items: ["HDR 3.13", "", "HDR 3.13"],
        desc: "Provisioning asset owner roots of trust",
      },
      {
        items: ["NDR 3.13", "", "NDR 3.13"],
        desc: "Provisioning asset owner roots of trust",
      },
      {
        items: ["CR 4.1 ", "", "CR 4.1"],
        desc: "Information confidentiality",
      },
    ],
  },
  {
    vulnerabilities: [1190, 1193, 1264, 1274, 1283, 1310, 1326, 1328],
    req: [
      {
        items: ["CR 3.1 ", "", "CR 3.1"],
        desc: "Communication integrity",
      },
      {
        items: ["CR 3.3 ", "", "CR 3.3"],
        desc: "Security functionality verification",
      },
      {
        items: ["CR 3.3 ", "RE (1)", "CR 3.3 -RE (1)"],
        desc: "Security functionality verification during normal operation",
      },
      {
        items: ["CR 3.4 ", "", "CR 3.4"],
        desc: "Software and information integrity",
      },
      { items: ["CR 3.5 ", "", "CR 3.5"], desc: "Input validation" },
      {
        items: ["EDR 3.10", "RE (1)", "EDR 3.10-RE (1)"],
        desc: "Update authenticity and integrity",
      },
      {
        items: ["HDR 3.10", "RE (1)", "HDR 3.10-RE (1)"],
        desc: "Update authenticity and integrity",
      },
      {
        items: ["NDR 3.10", "RE (1)", "NDR 3.10-RE (1)"],
        desc: "Update authenticity and integrity",
      },
      {
        items: ["CR 4.1 ", "", "CR 4.1"],
        desc: "Information confidentiality",
      },
    ],
  },
  {
    vulnerabilities: [23, 36, 41, 59, 66, 378, 379, 426, 427, 428],
    req: [
      { items: ["CR 3.6 ", "", "CR 3.6"], desc: "Deterministic output" },
      { items: ["CR 4.2 ", "", "CR 4.2"], desc: "Information persistence" },
      {
        items: ["CR 4.2 ", "RE (1)", "CR 4.2 -RE (1)"],
        desc: "Erase of shared memory resources",
      },
    ],
  },
  {
    vulnerabilities: [226, 454, 455, 459, 460, 1051, 1052, 1188],
    req: [
      { items: ["CR 3.1 ", "", "CR 3.1"], desc: "Communication integrity" },
      {
        items: ["CR 3.4 ", "RE (1)", "CR 3.4 -RE (1)"],
        desc: "Authenticity of software and information",
      },
      { items: ["CR 3.8 ", "", "CR 3.8"], desc: "Session integrity" },
      {
        items: ["CR 3.9 ", "", "CR 3.9"],
        desc: "Protection of audit information",
      },
      {
        items: ["EDR 3.11", "", "EDR 3.11"],
        desc: "Physical tamper resistance and protection",
      },
      {
        items: ["HDR 3.11", "", "HDR 3.11"],
        desc: "Physical tamper resistance and protection",
      },
      {
        items: ["NDR 3.11", "", "NDR 3.11"],
        desc: "Physical tamper resistance and protection",
      },
      {
        items: ["EDR 3.14", "RE (1)", "EDR 3.14-RE (1)"],
        desc: "Authenticity of the boot process",
      },
      {
        items: ["HDR 3.14", "RE (1)", "HDR 3.14-RE (1)"],
        desc: "Authenticity of the boot process",
      },
      {
        items: ["NDR 3.14", "RE (1)", "NDR 3.14-RE (1)"],
        desc: "Authenticity of the boot process",
      },
      {
        items: ["CR 4.2 ", "RE (1)", "CR 4.2 -RE (1)"],
        desc: "Erase of shared memory resources",
      },
    ],
  },
  {
    vulnerabilities: [
      117, 222, 223, 224, 532, 778, 779, 209, 248, 252, 253, 390, 391, 392, 393,
      394, 395, 396, 397, 544, 584, 600, 617, 756, 1069, 430, 431, 432, 433,
      434, 479,
    ],
    req: [
      { items: ["CR 2.8 ", "", "CR 2.8"], desc: "Auditable events" },
      {
        items: ["CR 2.9 ", "RE (1)", "CR 2.9 -RE (1)"],
        desc: "Warn when audit record storage capacity threshold reached",
      },
      {
        items: ["HDR 3.2 ", "RE (1)", "HDR 3.2 -RE (1)"],
        desc: "Report Version of code protection ",
      },
      {
        items: ["CR 3.4 ", "RE (2)", "CR 3.4 -RE (2)"],
        desc: "Automated notification of integrity violations",
      },
      { items: ["CR 3.7 ", "", "CR 3.7"], desc: "Error handling" },
      {
        items: ["CR 3.9 ", "RE (1)", "CR 3.9 -RE (1)"],
        desc: "Audit records on write-once media",
      },
      {
        items: ["EDR 3.11", "RE (1)", "EDR 3.11-RE (1)"],
        desc: "Notification of a tampering attempt",
      },
      {
        items: ["HDR 3.11", "RE (1)", "HDR 3.11-RE (1)"],
        desc: "Notification of a tampering attempt",
      },
      {
        items: ["NDR 3.11", "RE (1)", "NDR 3.11-RE (1)"],
        desc: "Notification of a tampering attempt",
      },
    ],
  },
  {
    vulnerabilities: [1190, 1193, 1264, 1274, 1283, 1310, 1326, 1328],
    req: [
      {
        items: ["EDR 3.14", "", "EDR 3.14"],
        desc: "Integrity of boot process",
      },
      {
        items: ["HDR 3.14", "", "HDR 3.14"],
        desc: "Integrity of boot process",
      },
      {
        items: ["NDR 3.14", "", "NDR 3.14"],
        desc: "Integrity of boot process",
      },
      {
        items: ["EDR 3.14", "RE (1)", "EDR 3.14-RE (1)"],
        desc: "Authenticity of the boot process",
      },
      {
        items: ["HDR 3.14", "RE (1)", "HDR 3.14-RE (1)"],
        desc: "Authenticity of the boot process",
      },
      {
        items: ["NDR 3.14", "RE (1)", "NDR 3.14-RE (1)"],
        desc: "Authenticity of the boot process",
      },
    ],
  },
  {
    vulnerabilities: [
      321, 322, 323, 324, 261, 324, 325, 328, 331, 334, 335, 338, 347, 916,
      1240,
    ],
    req: [
      {
        items: ["CR 3.1 ", "RE (1)", "CR 3.1 -RE (1)"],
        desc: "Cryptographic integrity protection",
      },
      {
        items: ["CR 4.1 ", "", "CR 4.1"],
        desc: "Information confidentiality",
      },
      {
        items: ["CR 4.2 ", "RE (2)", "CR 4.2 -RE (2)"],
        desc: "Erase verification ",
      },
      { items: ["CR 4.3 ", "", "CR 4.3"], desc: "Use of cryptography" },
    ],
  },
  {
    vulnerabilities: [488, 613, 841],
    req: [
      { items: ["CR 2.1", "", "CR 2.1"], desc: "Authorization enforcement" },
      {
        items: ["CR 2.1", "RE (1)", "CR 2.1 -RE (1)"],
        desc: "Authorization enforcement for all users",
      },
      {
        items: ["CR 2.6", "", "CR 2.6"],
        desc: "Remote session termination",
      },
      {
        items: ["CR 2.7", "", "CR 2.7"],
        desc: "Concurrent session control",
      },
      { items: ["CR 3.8", "", "CR 3.8"], desc: "Session integrity" },
    ],
  },
  {
    vulnerabilities: [694, 914, 1099],
    req: [
      {
        items: ["CR 1.1", "RE (1)", "CR 1.1-RE (1)"],
        desc: "Unique identification and authentication",
      },
      {
        items: ["CR 1.2", "RE (1)", "CR 1.2-RE (1)"],
        desc: "Unique identification and authentication",
      },
      {
        items: ["NDR 1.6", "RE (1)", "NDR 1.6-RE (1)"],
        desc: "Unique identification and authentication",
      },
      { items: ["CR 3.1 ", "", "CR 3.1"], desc: "Communication integrity" },
      {
        items: ["CR 3.4 ", "RE (1)", "CR 3.4 -RE (1)"],
        desc: "Authenticity of software and information",
      },
    ],
  },
  {
    vulnerabilities: [203, 325, 1240, 1241, 1279, 1351],
    req: [
      {
        items: ["CR 1.5", "RE (1)", "CR 1.5-RE (1)"],
        desc: "Hardware security for software process identity credentials",
      },
      {
        items: ["CR 1.9", "RE (1)", "CR 1.9-RE (1)"],
        desc: "Hardware security for public key authentication",
      },
      {
        items: ["CR 1.14", "RE (1)", "CR 1.14-RE (1)"],
        desc: "Hardware security for symmetric key based authentication",
      },
    ],
  },
  // NEW (1/6/2023) -->
  {
    vulnerabilities: [347, 522, 321, 798, 327],
    req: [
      {
        items: ["CR 1.8", "", "CR 1.8"],
        desc: "Public key infrastructure certificates",
      },
      {
        items: ["CR 1.9", "", "CR 1.9"],
        desc: "Strength of public key-based authentication",
      },
      {
        items: ["CR 3.1", "", "CR 3.1"],
        desc: "Communication integrity",
      },
      {
        items: ["CR 3.3", "", "CR 3.3"],
        desc: "Security functionality verification",
      },
      {
        items: ["CR 4.3", "", "CR 4.3"],
        desc: "Use of cryptography",
      },
    ],
  },
  {
    vulnerabilities: [1119, 561, 563, 1109, 1164],
    req: [
      {
        items: ["CR 7.7", "", "CR 7.7"],
        desc: "Least functionality",
      },
    ],
  },
  {
    vulnerabilities: [787, 121, 195, 805, 119, 681, 126],
    req: [
      {
        items: ["CR 3.2", "", "CR 3.2"],
        desc: "Protection from malicious code",
      },
      {
        items: ["SAR 3.2", "", "SAR 3.2"],
        desc: "Protection from malicious code",
      },
      {
        items: ["EDR 3.2", "", "EDR 3.2"],
        desc: "Protection from malicious code",
      },
      {
        items: ["HDR 3.2", "", "HDR 3.2"],
        desc: "Protection from malicious code",
      },
      {
        items: ["NDR 3.2", "", "NDR 3.2"],
        desc: "Protection from malicious code",
      },
    ],
  },
  {
    vulnerabilities: [20, 807, 134],
    req: [
      {
        items: ["CR 3.5", "", "CR 3.5"],
        desc: "Input validation",
      },
    ],
  },
  {
    vulnerabilities: [400, 772],
    req: [
      {
        items: ["CR 7.1", "", "CR 7.1"],
        desc: "Denial of service protction",
      },
      {
        items: ["CR 7.2", "", "CR 7.2"],
        desc: "Resource management",
      },
    ],
  },
  {
    vulnerabilities: [758, 691, 330],
    req: [
      {
        items: ["CR 3.6", "", "CR 3.6"],
        desc: "Deterministic output",
      },
    ],
  },
  {
    vulnerabilities: [732],
    req: [
      {
        items: ["CR 2.1", "", "CR 2.1"],
        desc: "Authorization enforcement",
      },
      {
        items: ["CR 7.2", "", "CR 7.2"],
        desc: "Resource management",
      },
    ],
  },
  {
    vulnerabilities: [710, 453],
    req: [
      {
        items: ["CR 3.2", "", "CR 3.2"],
        desc: "Protection from malicious code",
      },
      {
        items: ["SAR 3.2", "", "SAR 3.2"],
        desc: "Protection from malicious code",
      },
      {
        items: ["EDR 3.2", "", "EDR 3.2"],
        desc: "Protection from malicious code",
      },
      {
        items: ["HDR 3.2", "", "HDR 3.2"],
        desc: "Protection from malicious code",
      },
      {
        items: ["NDR 3.2", "", "NDR 3.2"],
        desc: "Protection from malicious code",
      },
      {
        items: ["CR 3.4", "", "CR 3.4"],
        desc: "Software and information integrity",
      },
    ],
  },
  {
    vulnerabilities: [120],
    req: [
      {
        items: ["CR 3.2", "", "CR 3.2"],
        desc: "Protection from malicious code",
      },
      {
        items: ["SAR 3.2", "", "SAR 3.2"],
        desc: "Protection from malicious code",
      },
      {
        items: ["EDR 3.2", "", "EDR 3.2"],
        desc: "Protection from malicious code",
      },
      {
        items: ["HDR 3.2", "", "HDR 3.2"],
        desc: "Protection from malicious code",
      },
      {
        items: ["NDR 3.2", "", "NDR 3.2"],
        desc: "Protection from malicious code",
      },
      {
        items: ["CR 3.5", "", "CR 3.5"],
        desc: "Input validation",
      },
    ],
  },
  {
    vulnerabilities: [1233],
    req: [
      {
        items: ["CR 2.5", "", "CR 2.5"],
        desc: "Session lock",
      },
      {
        items: ["CR 2.7", "", "CR 2.7"],
        desc: "Concurrent session control",
      },
    ],
  },
  {
    vulnerabilities: [190],
    req: [
      {
        items: ["CR 3.2", "", "CR 3.2"],
        desc: "Protection from malicious code",
      },
      {
        items: ["SAR 3.2", "", "SAR 3.2"],
        desc: "Protection from malicious code",
      },
      {
        items: ["EDR 3.2", "", "EDR 3.2"],
        desc: "Protection from malicious code",
      },
      {
        items: ["HDR 3.2", "", "HDR 3.2"],
        desc: "Protection from malicious code",
      },
      {
        items: ["NDR 3.2", "", "NDR 3.2"],
        desc: "Protection from malicious code",
      },
      {
        items: ["CR 7.2", "", "CR 7.2"],
        desc: "Resource management",
      },
    ],
  },
  {
    vulnerabilities: [362, 367],
    req: [
      {
        items: ["CR 2.5", "", "CR 2.5"],
        desc: "Session lock",
      },
      {
        items: ["CR 2.7", "", "CR 2.7"],
        desc: "Concurrent session control",
      },
      {
        items: ["CR 7.2", "", "CR 7.2"],
        desc: "Resource management",
      },
    ],
  },
  {
    vulnerabilities: [94],
    req: [
      {
        items: ["CR 3.1", "", "CR 3.1"],
        desc: "Communication integrity",
      },
      {
        items: ["CR 3.2", "", "CR 3.2"],
        desc: "Protection from malicious code",
      },
      {
        items: ["SAR 3.2", "", "SAR 3.2"],
        desc: "Protection from malicious code",
      },
      {
        items: ["EDR 3.2", "", "EDR 3.2"],
        desc: "Protection from malicious code",
      },
      {
        items: ["HDR 3.2", "", "HDR 3.2"],
        desc: "Protection from malicious code",
      },
      {
        items: ["NDR 3.2", "", "NDR 3.2"],
        desc: "Protection from malicious code",
      },
      {
        items: ["CR 3.5", "", "CR 3.5"],
        desc: "Input validation",
      },
    ],
  },
  {
    vulnerabilities: [616],
    req: [
      {
        items: ["CR 2.1", "", "CR 2.1"],
        desc: "Authorization enforcement",
      },
      {
        items: ["CR 3.4", "", "CR 3.4"],
        desc: "Software and information integrity",
      },
      {
        items: ["CR 3.5", "", "CR 3.5"],
        desc: "Input validation",
      },
    ],
  },
  {
    vulnerabilities: [78],
    req: [
      {
        items: ["CR 2.1", "", "CR 2.1"],
        desc: "Authorization enforcement",
      },
      {
        items: ["CR 3.2", "", "CR 3.2"],
        desc: "Protection from malicious code",
      },
      {
        items: ["SAR 3.2", "", "SAR 3.2"],
        desc: "Protection from malicious code",
      },
      {
        items: ["EDR 3.2", "", "EDR 3.2"],
        desc: "Protection from malicious code",
      },
      {
        items: ["HDR 3.2", "", "HDR 3.2"],
        desc: "Protection from malicious code",
      },
      {
        items: ["NDR 3.2", "", "NDR 3.2"],
        desc: "Protection from malicious code",
      },
      {
        items: ["CR 5.2", "", "CR 5.2"],
        desc: "Zone boundary protection",
      },
      {
        items: ["NDR 5.2", "", "NDR 5.2"],
        desc: "Zone boundary protection",
      },
      {
        items: ["CR 5.4", "", "CR 5.4"],
        desc: "Application partitioning",
      },
    ],
  },
  {
    vulnerabilities: [250],
    req: [
      {
        items: ["CR 1.1", "", "CR 1.1"],
        desc: "Human user identification and authentication",
      },
      {
        items: ["CR 1.2", "", "CR 1.2"],
        desc: "Software process and device identification and authentication",
      },
      {
        items: ["CR 1.3", "", "CR 1.3"],
        desc: "Account management",
      },
      {
        items: ["CR 2.1", "", "CR 2.1"],
        desc: "Authorization enforcement",
      },
    ],
  },
  {
    vulnerabilities: [319],
    req: [
      {
        items: ["CR 4.1", "", "CR 4.1"],
        desc: "Information confidentiality",
      },
    ],
  },
  {
    vulnerabilities: [287, 306],
    req: [
      {
        items: ["CR 1.1", "", "SR 1.1"],
        desc: "Human user identification and authentication",
      },
      {
        items: ["SR 1.2", "", "SR 1.2"],
        desc: "Software process and device identification and authentication",
      },
    ],
  },
  // <--
];

/**
 * Map a set of CWEs to their corresponding OWASP mappings.
 */
export function map_to_owasp(cwes) {
  let sorted_cwes = [...cwes];
  sorted_cwes = sorted_cwes.filter((cwe) => {
    let cwe_id = parseInt(cwe.cwe.split("-")[1]);
    if (!OWASP_2021_MAPPING.hasOwnProperty(cwe_id)) return false;
    return true;
  });
  sorted_cwes.sort((a, b) => {
    let a_id = parseInt(a.cwe.split("-")[1]);
    let b_id = parseInt(b.cwe.split("-")[1]);
    let a_val = parseInt(
      OWASP_2021_MAPPING[a_id].veracode_severity.split(" ")[0]
    );
    let b_val = parseInt(
      OWASP_2021_MAPPING[b_id].veracode_severity.split(" ")[0]
    );
    return b_val - a_val;
  });
  for (let cwe of sorted_cwes) {
    let cwe_id = parseInt(cwe.cwe.split("-")[1]);
    cwe.owasp_desc = OWASP_2021_MAPPING[cwe_id].cwe_name;
    cwe.static_support = OWASP_2021_MAPPING[cwe_id].static_support;
    cwe.dynamic_support = OWASP_2021_MAPPING[cwe_id].dynamic_support;
    cwe.veracode_severity = OWASP_2021_MAPPING[cwe_id].veracode_severity;
  }
  return sorted_cwes;
}

/**
 * Map to an ISA mapping.
 */
function map_to_isa(cwes, lineage, const_mapping) {
  let sorted_cwes = [];

  if (!lineage) {
    sorted_cwes.push({
      cwe: "Suspicious Code and/or Evasive Behavior Detected - Trojan Detection Failure",
      arr: [],
      requirements: const_mapping[0].req,
    });
  }

  for (let cwe of cwes) {
    let cwe_id = parseInt(cwe.cwe.split("-")[1]);
    for (let mapping of const_mapping) {
      if (mapping.vulnerabilities.includes(cwe_id)) {
        sorted_cwes.push({ ...cwe, requirements: mapping.req });
      }
    }
  }

  // check for special case:
  let cwe_1 = null;
  let cwe_2 = null;
  let cwe_3 = null;
  for (let cwe of cwes) {
    let cwe_id = parseInt(cwe.cwe.split("-")[1]);
    if (
      [
        322, 346, 347, 348, 349, 351, 353, 354, 494, 565, 649, 829, 924,
      ].includes(cwe_id)
    ) {
      cwe_1 = cwe;
    } else if ([23, 36, 41, 59, 66, 378, 379, 426, 427, 428].includes(cwe_id)) {
      cwe_2 = cwe;
    } else if ([112, 129, 179, 183, 184, 606, 641, 1173].includes(cwe_id)) {
      cwe_3 = cwe;
    }
  }
  if (cwe_1 && cwe_2 && cwe_3)
    sorted_cwes.push({
      cwe: cwe_1.cwe + ", " + cwe_2.cwe + ", and " + cwe_3.cwe,
      arr: cwe_1.arr.concat(cwe_2.arr, cwe_3.arr),
      requirements: const_mapping[2].req,
    });

  return sorted_cwes;
}

/**
 * Map a set of CWEs to their corresponding ISA 3-3 mappings;
 */
export function map_to_isa_3_3(cwes, lineage) {
  return map_to_isa(cwes, lineage, ISA_3_3_MAPPING);
}

/**
 * Map a set of CWEs to their corresponding ISA 4-2 mappings;
 */
export function map_to_isa_4_2(cwes, lineage) {
  return map_to_isa(cwes, lineage, ISA_4_2_MAPPING);
}

/**
 * These functions add mapping fields to a pre-exisiting cwe object.
 */
export function add_owasp_field(cwe) {
  if (!cwe.hasOwnProperty("mappings")) cwe.mappings = [];
  let owasp_mappings = map_to_owasp([cwe]);
  if (owasp_mappings.length === 0) return cwe;
  let mapping_data = owasp_mappings[0];
  cwe.mappings.push({
    type: "OWASP 10:2021",
    owasp_desc: mapping_data.owasp_desc,
    static_support: mapping_data.static_support,
    dynamic_support: mapping_data.dynamic_support,
    veracode_severity: mapping_data.veracode_severity,
  });
  return cwe;
}
export function add_isa_3_3_field(cwe) {
  if (!cwe.hasOwnProperty("mappings")) cwe.mappings = [];
  let isa_3_3_mappings = map_to_isa_3_3([cwe], true);
  if (isa_3_3_mappings.length === 0) return cwe;
  let mapping_data = isa_3_3_mappings[0];
  cwe.mappings.push({
    type: "ISA/IEC 62443-3-3",
    requirements: mapping_data.requirements,
  });
  return cwe;
}
export function add_isa_4_2_field(cwe) {
  if (!cwe.hasOwnProperty("mappings")) cwe.mappings = [];
  let isa_4_2_mappings = map_to_isa_4_2([cwe], true);
  if (isa_4_2_mappings.length === 0) return cwe;
  let mapping_data = isa_4_2_mappings[0];
  cwe.mappings.push({
    type: "ISA/IEC 62443-4-2",
    requirements: mapping_data.requirements,
  });
  return cwe;
}
export function add_nist_800_53_field(cwe) {
  if (!cwe.hasOwnProperty("mappings")) cwe.mappings = [];
  let nist_800_53_mappings = map_to_nist_800_53([cwe]);
  if (nist_800_53_mappings.length === 0) return cwe;

  let line_items = nist_800_53_mappings.map((mapping) => mapping.line_item);
  cwe.mappings.push({
    type: "NIST 800-53",
    line_items,
  });

  return cwe;
}
export function add_nist_800_82_field(cwe) {
  if (!cwe.hasOwnProperty("mappings")) cwe.mappings = [];
  let nist_800_82_mappings = map_to_nist_800_82([cwe]);
  if (nist_800_82_mappings.length === 0) return cwe;

  let line_items = nist_800_82_mappings.map((mapping) => mapping.line_item);
  cwe.mappings.push({
    type: "NIST 800-82",
    line_items,
  });

  return cwe;
}

/*
  NIST 800-53 Mappings

  Relevant CWE definitions:
  
  ---
  758	Reliance on Undefined, Unspecified, or Implementation-Defined Behavior
  691	Insufficient Control Flow Management
  1119	Excessive Use of Unconditional Branching
  330	Use of Insufficiently Random Values

  ---
  787	Out-of-bounds Write
  121	Stack-based Buffer Overflow
  805	Buffer Access with Incorrect Length Value
  119	Improper Restriction of Operations within the Bounds of a Memory Buffer
  126	Buffer Over-read
  120	Buffer Copy without Checking Size of Input ('Classic Buffer Overflow')

  ---
  710	Improper Adherence to Coding Standards
  453	Insecure Default Variable Initialization
  190	Integer Overflow or Wraparound
  681	Incorrect Conversion between Numeric Types
  195	Signed to Unsigned Conversion Error

  ---
  561	Dead Code
  563	Assignment to Variable without Use
  1109	Use of Same Variable for Multiple Purposes
  1164	Irrelevant Code

  ---
  20	Improper Input Validation
  807	Reliance on Untrusted Inputs in a Security Decision
  134	Use of Externally-Controlled Format String

  ---
  78	Improper Neutralization of Special Elements used in an OS Command ('OS Command Injection')
  94	Improper Control of Generation of Code ('Code Injection')
  616	Incomplete Identification of Uploaded File Variables (PHP)

  ---
  250	Execution with Unnecessary Privileges
  287	Improper Authentication
  306	Missing Authentication for Critical Function
  732	Incorrect Permission Assignment for Critical Resource
  1233	Security-Sensitive Hardware Controls with Missing Lock Bit Protection

  ---
  400	Uncontrolled Resource Consumption
  772	Missing Release of Resource after Effective Lifetime
  362	Concurrent Execution using Shared Resource with Improper Synchronization ('Race Condition')
  367	Time-of-check Time-of-use (TOCTOU) Race Condition

  ---
  522	Insufficiently Protected Credentials
  321	Use of Hard-coded Cryptographic Key
  798	Use of Hard-coded Credentials

  ---
  347	Improper Verification of Cryptographic Signature
  327	Use of a Broken or Risky Cryptographic Algorithm
  319	Cleartext Transmission of Sensitive Information

*/
const NIST_800_53_MAPPINGS = {
  "RA-3 Risk Assessment": {
    cwes: [
      787, 121, 805, 119, 126, 120, 453, 190, 681, 195, 20, 807, 134, 94, 616,
      78, 1233, 758, 691, 1119, 330,
    ],
    actionables: [
      "RA-3(1) Assess supply chain risks associated with organization-defined systems, system components, and system services and update the supply chain risk assessment organization-defined frequency], when there are significant changes to the relevant supply chain, or when changes to the system, environments of operation, or other conditions may necessitate a change in the supply chain.",
      "RA-3(2) Use all-source intelligence to assist in the analysis of risk.",
      "RA-3(3) Determine the current cyber threat environment on an ongoing basis.",
      "RA-3(4) Employ advanced automation and analytics capabilities to predict and identify risks.",
    ],
    notes:
      "By using OT.AI Platform, you are already partially accounting for Risk Assessment.",
  },
  "RA-5 Vulnerability Monitoring and Scanning": {
    cwes: [
      787, 121, 805, 119, 126, 120, 453, 190, 681, 195, 20, 807, 134, 94, 616,
      78, 1233, 758, 691, 1119, 330,
    ],
    actionables: [
      "RA-5(2) Update the system vulnerabilities to be scanned in an organization-defined frequency prior to a new scan and/or when new vulnerabilities are identified and reported",
      "RA-5(3) Define the breadth and depth of vulnerability scanning coverage.",
      "RA-5(4) Determine information about the system that is discoverable and take organization-defined corrective actions.",
      "RA-5(5) Implement privileged access authorization to organization-defined system components for organization-defined vulnerability scanning activities.",
      "RA-5(6) Compare the results of multiple vulnerability scans using organization-defined automated mechanisms.",
      "RA-5(8) Review historic audit logs to determine if a vulnerability identified in an organization-defined system has been previously exploited within an organization-defined time period.",
      "RA-5(10) Correlate the output from vulnerability scanning tools to determine the presence of multi-vulnerability and multi-hop attack vectors.",
      "RA-5(11) Establish a public reporting channel for receiving reports of vulnerabilities in organizational systems and system components.",
    ],
    notes:
      "By using OT.AI Platform, you are already partially accounting for Vulnerability Monitoring and Scanning.",
  },
  "RA-7 Risk Response": {
    cwes: [
      787, 121, 805, 119, 126, 120, 453, 190, 681, 195, 20, 807, 134, 94, 616,
      78, 1233, 758, 691, 1119, 330,
    ],
    actionables: [],
    notes:
      "The OT.AI Platform enables risk response via the Alerts features found in the top-right corner of the platform.",
  },
  "RA-9 Criticality Analysis": {
    cwes: [
      787, 121, 805, 119, 126, 120, 453, 190, 681, 195, 20, 807, 134, 94, 616,
      78, 1233, 758, 691, 1119, 330,
    ],
    actionables: [],
    notes:
      "The OT.AI Platform assesses binaries with a Red, Yellow, or Green score: indicating criticality. The context of the assessed binary also matters: does the binary interact with critical network resources, does the binary handle private user-information, etc.",
  },
  "RA-10 Threat Hunting": {
    cwes: [
      787, 121, 805, 119, 126, 120, 453, 190, 681, 195, 20, 807, 134, 94, 616,
      78, 1233, 758, 691, 1119, 330,
    ],
    actionables: [],
    notes:
      "Any of these discovered CWEs may indicate a threat if exploited, so it is critical that users of the OT.AI Platform investigate each instance of weakness with an open mind.",
  },
  // ---
  "SC-5 Denial-of-service Protection": {
    cwes: [362, 367, 400, 772],
    actionables: [
      "SC-5(1) Restrict the ability of individuals to launch the following denial-of-service attacks against other systems organization-defined denial-of-service attacks.",
      "SC-5(2) Manage capacity, bandwidth, or other redundancy to limit the effects of information flooding denial-of-service attacks.",
      "SC-5(3) Employ monitoring tools to detect indicators of denial-of-service attacks against, or launched from, the system and monitor system resources to determine if sufficient resources exist to prevent effective denial-of-service attacks.",
    ],
    notes:
      "Poorly-handled resource management may empower malicious actors with an easier time when initiating denial-of-service attacks.",
  },
  "SC-6 Resource Availability": {
    cwes: [362, 367, 400, 772],
    actionables: [],
    notes:
      "Binaries that fail to intelligently manage access to system resources may cause resource downtime, un-availability, or other issues.",
  },
  "SC-12 Cryptographic Key Establishment and Management": {
    cwes: [321, 522, 798],
    actionables: [
      "SC-12(1) Maintain availability of information in the event of the loss of cryptographic keys by users.",
      "SC-12(2) Produce, control, and distribute symmetric cryptographic keys using NSA-approved key management technology and processes.",
      "SC-12(3) Produce, control, and distribute asymmetric cryptographic keys using NSA-approved key management technology and processes; prepositioned keying material DoD-approved or DoD-issued Medium Assurance PKI certificates; DoD-approved or DoD-issued Medium Hardware Assurance PKI certificates and hardware security tokens that protect the user’s private key; certificates issued in accordance with organization-defined requirements.",
      "SC-12(6) Maintain physical control of cryptographic keys when stored information is encrypted by external service providers.",
    ],
    notes: "This binary handles cryptographic information in an insecure way.",
  },
  "SC-13 Cryptographic Protection": {
    cwes: [319, 327, 347],
    actionables: [],
    notes: "This binary handles cryptographic information in an insecure way.",
  },
  "SC-23 Session Authenticity": {
    cwes: [250, 287, 306, 732, 1233],
    actionables: [
      "SC-23(1) Invalidate session identifiers upon user logout or other session termination",
      "SC-23(3) Generate a unique session identifier for each session with organization-defined randomness requirements and recognize only session identifiers that are system-generated.",
      "SC-23(5) Only allow the use of organization-defined certificate authorities for verification of the establishment of protected sessions.",
    ],
  },
  "SC-24 Non-Modifiable Executable Programs": {
    cwes: [78, 94, 616],
    actionables: [
      "SC-24(1) Employ organization-defined system components with no writeable storage at is persistent across component restart or power on/off.",
      "SC-24(2) Protect the integrity of information prior to storage on read-only media and control the media after such information has been recorded onto the media.",
    ],
    notes:
      "Code injection may result in undesired manipulation of executable processes.",
  },
  "SC-35 External Malicious Code Identification": {
    cwes: [78, 94, 616],
    actionables: [],
  },
  //---
  "SI-2 Flaw Remediation": {
    cwes: [
      787, 121, 805, 119, 126, 120, 453, 190, 681, 195, 20, 807, 134, 94, 616,
      78, 1233, 758, 691, 1119, 330,
    ],
    actionables: [
      "SI-2(2) Determine if system components have applicable security-relevant software and firmware updates installed using organization-defined automated mechanisms.",
      "SI-2(3) Measure the time between flaw identification and flaw remediation and establish the following benchmarks for taking corrective actions.",
      "SI-2(4) Employ automated patch management tools to facilitate flaw remediation to system components.",
      "SI-2(5) Install organization-defined security-relevant software and firmware updates automatically to organization-defined system components.",
      "SI-2(6) Remove previous versions of organization-defined software and firmware components after updated versions have been installed.",
    ],
  },
  "SI-3 Malicious Code Protection": {
    cwes: [78, 94, 616],
    actionables: [
      "SI-3(4) Update malicious code protection mechanisms only when directed by a privileged user.",
      "SI-3(6) Test malicious code protection mechanisms organization-defined frequency by introducing known benign code into the system and verify that the detection of the code and the associated incident reporting occur.",
      "SI-3(8) Detect the following unauthorized operating system commands through the kernel application programming interface on organization-defined system hardware components, organization-defined unauthorized operating system commands, and issue a warning audit the command execution and/or prevent the execution of the command.",
      "SI-3(10) Employ tools and techniques to analyze the characteristics and behavior of malicious code and incorporate the results from malicious code analysis into organizational incident response and flaw remediation processes.",
    ],
  },
  "SI-10 Information Input Validation": {
    cwes: [20, 134, 807],
    actionables: [
      "SI-10(1) Provide a manual override capability for input validation of the information inputs. Restrict the use of the manual override capability and audit the use of the manual override capability.",
      "SI-10(2) Review and resolve input validation errors within an organization-defined time period.",
      "SI-10(3) Verify that the system behaves in a predictable and documented manner when invalid inputs are received.",
      "SI-10(4) Account for timing interactions among system components in determining appropriate responses for invalid inputs.",
      "SI-10(5) Restrict the use of information inputs to organization-defined trusted sources and/or organization-defined formats.",
      "SI-10(6) Prevent untrusted data injections.",
    ],
  },
  "SI-11 Error Handling": {
    cwes: [787, 121, 805, 119, 126, 120, 710, 453, 190, 681, 195],
    actionables: [],
    notes:
      "These CWEs potentially produce either application or system-level errors that must be handled appropriately.",
  },
  "SI-16 Memory Protection": {
    cwes: [787, 121, 805, 119, 126, 120],
    actionables: [],
    notes:
      "This binary contains code which may be manipulated to access memory locations the developer may not have initially intended.",
  },
  // ---
  "CM-4 Impact Analyses": {
    cwes: [],
    actionables: [
      "CM-4(1) Analyze changes to the system in a separate test environment before implementation in an operational environment, looking for security and privacy impacts due to flaws, weaknesses, incompatibility, or intentional malice.",
      "CM-4(2) After system changes, verify that the impacted controls are implemented correctly, operating as intended, and producing the desired outcome with regard to meeting the security and privacy requirements for the system.",
    ],
  },
  "CM-7 Least Functionality": {
    cwes: [561, 563, 1109, 1164],
    actionables: [
      "CM-7(1) Review the system organization-defined frequency to identify unnecessary and/or nonsecure functions, ports, protocols, software, and services; and disable or remove [Assignment: organization-defined functions, ports, protocols, software, and services within the system deemed to be unnecessary and/or nonsecure.",
      "CM-7(2) Prevent program execution in accordance with organization-defined policies, rules of behavior, and/or access agreements regarding software program usage and restrictions; rules authorizing the terms and conditions of software program usage.",
      "CM-7(3) Ensure compliance with organization-defined registration requirements for functions, ports, protocols, and services.",
      "CM-7(4) Identify organization-defined software programs not authorized to execute on the system, employ an allow-all, deny-by-exception policy to prohibit the execution of unauthorized software programs on the system, and review and update the list of unauthorized software programs.",
      "CM-7(5) Identify organization-defined software programs authorized to execute on the system, employ a deny-all, permit-by-exception policy to allow the execution of authorized software programs on the system, and review and update the list of authorized software program.",
      "CM-7(6) Require that the user-installed software execute in a confined physical or virtual machine environment with limited privileges.",
      "CM-7(7) Allow execution of binary or machine-executable code only in confined physical or virtual machine environments and with the explicit approval of organization-defined personnel or roles when such code is: Obtained from sources with limited or no warranty; and/or without the provision of source code.",
      "CM-7(8) Prohibit the use of binary or machine-executable code from sources with limited or no warranty or without the provision of source code; and allow exceptions only for compelling mission or operational requirements and with the approval of the authorizing official.",
      "CM-7(9) Identify organization-defined hardware components authorized for system use; prohibit the use or connection of unauthorized hardware components; review and update the list of authorized hardware components.",
    ],
  },
  // ---
  "SA-8 Security and Privacy Engineering Principles": {
    cwes: [250, 287, 306, 732, 1233],
    actionables: [
      "SA-8(1) Implement the security design principle of clear abstractions.",
      "SA-8(2) Implement the security design principle of least common mechanism.",
      "SA-8(3) Implement the security design principles of modularity and layering in organization-defined systems or system components.",
      "SA-8(4) Implement the security design principle of partially ordered dependencies in organization-defined systems or system components.",
      "SA-8(5) Implement the security design principle of efficiently mediated access in organization-defined systems or system components.",
      "SA-8(6) Implement the security design principle of minimized sharing in organization-defined systems or system components.",
      "SA-8(7) Implement the security design principle of reduced complexity in organization-defined systems or system components.",
      "SA-8(8) Implement the security design principle of secure evolvability in organization-defined systems or system components.",
      "SA-8(9) Implement the security design principle of trusted components in organization-defined systems or system components.",
      "SA-8(10) Implement the security design principle of hierarchical trust in organization-defined systems or system components.",
    ],
    notes:
      "There are other actionable items (25 total). Please view the NIST 800-53r5 document if you wish to review the additional actionable items.",
  },
  "SA-11 Developer Testing and Evaluation": {
    cwes: [
      758, 691, 1119, 330, 787, 121, 805, 119, 126, 120, 710, 453, 190, 681,
      195, 561, 563, 1109, 1164,
    ],
    actionables: [
      "SA-11(1) Require the developer of the system, system component, or system service to employ static code analysis tools to identify common flaws and document the results of the analysis.",
      "SA-11(2) Require the developer of the system, system component, or system service to perform threat modeling and vulnerability analyses during development and the subsequent testing and evaluation of the system, component, or service.",
      "SA-11(3) Require an independent agent satisfying organization-defined independence criteria to verify the correct implementation of the developer security and privacy assessment plans and the evidence produced during testing and evaluation; and verify that the independent agent is provided with sufficient information to complete the verification process or granted the authority to obtain such information.",
      "SA-11(4) Require an independent agent satisfying organization-defined independence criteria to verify the correct implementation of the developer security and privacy assessment plans and the evidence produced during testing and evaluation; and verify that the independent agent is provided with sufficient information to complete the verification process or granted the authority to obtain such information.",
      "SA-11(5) Require the developer of the system, system component, or system service to perform penetration testing.",
      "SA-11(6) Require the developer of the system, system component, or system service to perform attack surface reviews.",
      "SA-11(7) Require the developer of the system, system component, or system service to verify that the scope of testing and evaluation provides complete coverage of the required controls at the following level of rigor: organization-defined breadth and depth of testing and evaluation.",
      "SA-11(8) Require the developer of the system, system component, or system service to employ dynamic code analysis tools to identify common flaws and document the results of the analysis.",
      "SA-11(9) Require the developer of the system, system component, or system service to employ interactive application security testing tools to identify flaws and document the results.",
    ],
    notes:
      "The OT.AI Platform can be used in conjuction with existing CI/CD and vulnerability analysis tools to better adhere to SA-11.",
  },
  "SA-15 Development Process, Standards, and Tools": {
    cwes: [
      758, 691, 1119, 330, 787, 121, 805, 119, 126, 120, 710, 453, 190, 681,
      195, 561, 563, 1109, 1164,
    ],
    actionables: [],
    notes:
      "The OT.AI Platform can be used in conjuction with existing CI/CD and code-quality insurance tools to better adhere to SA-15.",
  },
};

const NIST_800_82_MAPPINGS = {
  "6.2.1 Access Control": {
    cwes: [250, 287, 306, 732, 1233, 522, 321, 798, 347, 327, 319, 807],
    notes: `Organizations should implement policies and procedures restricting use of 
      system resources to only users, programs, and processes that have been authorized. 

      The OT.AI Platform detects potential threats associated with an inproperly configured 
      access control system. This includes improper authentication schemes, incorrect permissions, 
      and hard-coded credentials.`,
  },
  "6.2.7 Identification and Authentication": {
    cwes: [250, 287, 306, 732, 522, 798],
    notes: `Organizations should identify potential users, hosts, applications, 
      services, and resources using a combination of identifying factors or credentials. 
      Without authentication, further actions within the system should be denied. 

      The OT.AI Platform discovers vulnerabilities in authentication schemes. 
      Cases where authentication is unrequired for critical resource access, or where 
      authentication credentials are stored in an unprotected way are detected.`,
  },
  "6.2.14 Risk Assessment": {
    cwes: [
      787, 121, 805, 119, 126, 120, 453, 190, 681, 195, 20, 807, 134, 94, 616,
      78, 1233, 758, 691, 1119, 330,
    ],
    notes: `Organizations should assess the impact from potential security risks to 
      operations, assets, individuals, and other organizations. 
      Organizations should focus on mitigating risks with the greatest potential impact.
      
      The OT.AI Platform detects a wide-variety of embedded software vulnerabilities. These
      vulnerabilities may require mitigation, depending on the organization's risk tolerance.
      Such vulnerabilities may include commonly known software weaknesses, poor input handling,
      and improper adherence to safe coding practices.`,
  },
  "6.2.16 System and Communications Protection": {
    cwes: [319, 327, 347, 321, 367, 400, 772, 362],
    notes: `Organizations should protect data communications between both internal and external system 
      components. Policies and procedures should be put in place to ensure all data communications 
      are handled in a way that best mitigates malicious interference.

      The OT.AI Platform detects a variety of potential threats to safe system communication.
      This includes cleartext transmission of sensitive data and various resource-realted issues
      that may increase the system's vulnerability to denial of service attacks.`,
  },
  "6.2.16.1 Encryption": {
    cwes: [319, 327, 347, 321],
    notes: `Organizations should enforce encryption of data transmissions whenever the transmission is 
      sensitive. This involves taking un-encrypted data (called plaintext) and encrypting it
      into an obfuscated form (called ciphertext) to conceal the data's orginal meaning from
      malicious actors.

      The OT.AI Platform detects vulnerable transmissions of plaintext, as well as finding 
      malicious examples of encryption: such as when malware attempts to obfuscate itself.`,
  },
  "6.2.17 System and Information Integrity": {
    cwes: [250, 732, 78, 94, 616, 134, 787, 121, 805, 119, 126, 120, 20],
    notes: `Organizations should maintain system and information integrity by ensuring that
      sensitive data has not been modified or deleted in an unauthorized or undetected manner.

      The OT.AI Platform discovers potential examples where critical resources are improperly
      privileged, allowing a malicious actor to modify normally in-accessible data. The Platform
      also discovers examples wherein software may be manipulated to enable
      an attacker to access or modify computer memory.`,
  },
  "6.2.17.1 Virus and Malicious Code Detection": {
    cwes: [78, 94, 616, 787, 121, 805, 119, 126, 120, 190],
    notes: `Organizations should utilize antivirus and malware code detection methods to
      evaluate files against known malware signature files.
      
      The OT.AI Platform detects examples wherein a malicious attacker may be able to manipulate
      memory in an attempt to execute arbitrary code. Such an attack may result in malware or 
      virus injection.
      Additionally, the OT.AI Platform also automatically scans for known malware signatures.`,
  },
  "6.2.17.3 Patch Management": {
    cwes: [
      561, 563, 1109, 1164
    ],
    notes: `Organizations should systematically appraoch patching vulnerable software:
      resolving specific problems or flaws in said software.
      
      The OT.AI Platform detects locations in software where code goes unused. Such code segments
      are an easy target for patch application.`
  },
};

export function map_to_nist(cwes, MAPPING_DS) {
  const CWE_INDICES = cwes.map((cwe) => parseInt(cwe.cwe.split("-")[1]));

  let mappings = [];

  for (let line_item in MAPPING_DS) {
    let mapping = MAPPING_DS[line_item];

    // are there CWEs in this binary that map to this NIST line item
    let intersection = CWE_INDICES.filter((value) =>
      mapping.cwes.includes(value)
    );
    if (intersection.length === 0) continue;
    mappings.push({
      ...mapping,
      line_item,
      cwes: intersection,
    });
  }

  return mappings;
}

/**
 * Map a set of CWEs to their corresponding NIST 800-53 line items.
 */
export function map_to_nist_800_53(cwes) {
  return map_to_nist(cwes, NIST_800_53_MAPPINGS);
}

/**
 * Map a set of CWEs to their corresponding NIST 800-82 line items.
 */
export function map_to_nist_800_82(cwes, _lineage) {
  return map_to_nist(cwes, NIST_800_82_MAPPINGS);
}
