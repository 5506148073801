/*
    This file wraps the components that appear on the asset report.

    This component is used both when creating a asset report, and when
    creating a concatenated report.
    
    When this component is being displayed on a concatenated report,
    it automatically displays all of its results.
*/
import { useRef, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

// components
import ReportBuilderComponent from "./report_builder_component";
import AssetSummaryTitle from "../../../binary_analysis_page/asset_summary_components/asset_summary_title";
import AssetDescription from "../../../binary_analysis_page/asset_summary_components/asset_description";
import AuditHistory from "../../../binary_analysis_page/asset_summary_components/audit_history";
import BinaryOverview from "../../../binary_analysis_page/asset_summary_components/binary_overview";

const getDefaultDoneMap = (items) => {
  let doneMap = {};
  for (let i = 0; i < items.length; i++) doneMap[`item_${i}`] = false;
  return doneMap;
};

export default function AssetReport({
  items,
  setItems,
  asset_id,
  asset,
  binaries,
  audit,
  done,
}) {
  // used to track what components have finished rendering
  let [doneMap, setDoneMap] = useState(getDefaultDoneMap(items));

  // once all components have finished rendering, report completion by
  // calling `done()`
  useEffect(() => {
    // if the `done` prop is not defined, simply return
    if (!done) return;

    // if any item is not done, return
    for (let done_item of Object.values(doneMap)) {
      if (!done_item) return;
    }

    // all items are done, the report is fully painted!
    done();
  }, [doneMap]);

  const useDraggableInPortal = () => {
    const self = useRef({}).current;

    useEffect(() => {
      const div = document.createElement("div");
      div.style.position = "absolute";
      div.style.pointerEvents = "none";
      div.style.top = "0";
      div.style.width = "100%";
      div.style.height = "100%";
      self.elt = div;
      document.body.appendChild(div);
      return () => {
        document.body.removeChild(div);
      };
    }, [self]);

    return (render) =>
      (provided, ...args) => {
        const element = render(provided, ...args);
        if (provided.draggableProps.style.position === "fixed") {
          return createPortal(element, self.elt);
        }
        return element;
      };
  };
  const renderDraggable = useDraggableInPortal();

  // count the number of each kind of scored binary
  let nRed = binaries.reduce((accumulator, binary) => {
    if (binary.cwss.aggregate === "RED") return accumulator + 1;
    return accumulator;
  }, 0);
  let nYellow = binaries.reduce((accumulator, binary) => {
    if (binary.cwss.aggregate === "YELLOW") return accumulator + 1;
    return accumulator;
  }, 0);
  let nGreen = binaries.reduce((accumulator, binary) => {
    if (binary.cwss.aggregate === "GREEN") return accumulator + 1;
    return accumulator;
  }, 0);

  return (
    <div>
      {/* Required components. */}
      <AssetSummaryTitle asset={asset} />
      {binaries.length > 0 && (
        <BinaryOverview nRed={nRed} nYellow={nYellow} nGreen={nGreen} />
      )}

      <AssetDescription asset={asset} />
      <div style={{ height: 30 }}></div>
      <AuditHistory audit={audit} />
      <div style={{ height: 30 }}></div>

      {/* Dynamic list of items. */}
      <DragDropContext
        onDragEnd={(result) => {
          if (!result.destination) {
            return;
          }
          setItems((items) => {
            const [removed] = items.splice(result.source.index, 1);
            items.splice(result.destination.index, 0, removed);
            return items;
          });
        }}
      >
        <Droppable droppableId="droppable" style={{ overflow: "scroll" }}>
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={{}}
            >
              {items.map((item, index) => {
                return (
                  <Draggable
                    className="report-builder-component"
                    draggableId={item.id}
                    index={index}
                    key={item.id}
                  >
                    {renderDraggable((provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={{
                          ...provided.draggableProps.style,
                        }}
                      >
                        <ReportBuilderComponent
                          asset_id={asset_id}
                          setItems={setItems}
                          item={item}
                          done={() => {
                            setDoneMap((doneMap) => {
                              let newDoneMap = { ...doneMap };
                              newDoneMap[`item_${index}`] = true;
                              return newDoneMap;
                            });
                          }}
                        />
                        {provided.placeholder}
                      </div>
                    ))}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <div className="row">
        <img
          className="bottom-report-logo"
          src="/otai_logo_500x500.png"
          alt="logo"
        />
        {nRed} Red Binaries &bull; {nYellow} Yellow Binaries &bull; {nGreen}{" "}
        Green Binaries
      </div>
    </div>
  );
}
